import UserAccessService from "services/user-access-service";
import { AdditionalFieldsHelper } from "helpers/additional-fields-helper";
import { AdditionalFieldsSourceType } from "enums/additional-fields-source-type";

define([
    "knockout",
    "underscore",
    "jquery",
    "core/resx",
    "services/workOrderService",
    "services/clientService",
    "helpers/routerHelper",
    "helpers/notificationHelper",
    "helpers/enumHelper",
    "helpers/labelHelper",
    "helpers/viewbag"
], function (ko, _, jQuery, resx, workOrderService, clientService, routerHelper, notificationHelper, enumHelper, labelHelper, viewbag) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        ctor.inject = [UserAccessService, AdditionalFieldsHelper];

        function ctor(userAccessService, additionalFieldsHelper) {
            self = this;

            //#region Properties
            self.resx = resx;
            self.routerHelper = routerHelper;

            self.btnLinks = [];
            self.client = {};
            self.detail = {};
            self.completeAvailable = false;
            self.signatureAvailable = false;
            self.readonly = false;
            self.editModes = enumHelper.fieldServiceEditMode();

            self.lblSubheaderTitle = '';
            self.lblAddress = '';
            self.workOrderId = '';

            self.userAccessService = userAccessService;
            self.additionalFieldsHelper = additionalFieldsHelper;

            //#endregion
        }

        //#region Private Functions
        function setLabels() {
            self.lblSubheaderTitle = resx.localize('WorkOrder') + ' - ' + self.detail.Id;
            self.lblAddress = self.detail.LocationAddress + ', ' + self.detail.LocationCity + (self.detail.LocationProvince ? ' (' + self.detail.LocationProvince + ') ' : ' ') + self.detail.LocationPostalCode;
        }

        function setButtonLinks() {
            self.btnLinks = [
                {
                    disabled: false,
                    disabledAdd: false,
                    display: true,
                    displayAdd: false,
                    displayChevron: true,
                    displayCount: true,
                    count: self.detail.ContactCount,
                    linkAdd: '',
                    linkMain: self.genUrl('contact'),
                    text: resx.localize('Contacts')
                },
                {
                    disabled: false,
                    disabledAdd: false,
                    display: true,
                    displayAdd: !self.readonly,
                    displayChevron: self.readonly,
                    displayCount: true,
                    count: self.detail.MaterialCount,
                    linkAdd: self.genUrl('material/edit/false/-1'),
                    linkMain: self.genUrl('material'),
                    text: resx.localize('MaterialAndBillingItem')
                },
                {
                    disabled: false,
                    disabledAdd: false,
                    display: true,
                    displayAdd: !self.readonly,
                    displayChevron: self.readonly,
                    displayCount: true,
                    count: self.detail.MaterialRequisitionCount,
                    linkAdd: self.genUrl('materialRequisition/edit'),
                    linkMain: self.genUrl('materialRequisition'),
                    text: resx.localize('MaterialRequisition')
                },
                {
                    id: "workTasks",
                    disabled: false,
                    disabledAdd: true,
                    display: true, //TODO DONT SHOW IF MAINTENANCE
                    displayAdd: false,
                    displayChevron: self.isReadonly,
                    displayCount: true,
                    count: self.detail.WorkTasksCount,
                    linkAdd: self.genUrl("workTask"),
                    linkMain: self.genUrl("workTask"),
                    text: resx.localize('WorkTask')
                },
                {
                    id: "orders",
                    disabled: false,
                    disabledAdd: false,
                    display: self.userAccessService.isOptionEnabledSync(enumHelper.userOptions.FIELDSERVICESTOCKORDERFROMCATALOG),
                    displayAdd: !self.isReadonly,
                    displayChevron: self.isReadonly,
                    displayCount: true,
                    count: self.detail.OrdersCount,
                    linkAdd: self.genUrl("materialOrder/edit"),
                    linkMain: self.genUrl("materialOrder"),
                    text: resx.localize('Orders')
                },
                {
                    disabled: false,
                    disabledAdd: false,
                    display: true,
                    displayAdd: !self.readonly,
                    displayChevron: self.readonly,
                    displayCount: true,
                    count: self.detail.RecommendationCount,
                    linkAdd: self.genUrl('recommendation/edit'),
                    linkMain: self.genUrl('recommendation'),
                    text: resx.localize('Recommendations')
                },
                {
                    disabled: false,
                    disabledAdd: false,
                    display: true,
                    displayAdd: true,
                    displayChevron: false,
                    displayCount: false,
                    count: 0,
                    linkAdd: self.genUrl('document/add'),
                    linkMain: self.genUrl('document'),
                    text: resx.localize('Pictures/Documents')
                },
                {
                    disabled: false,
                    disabledAdd: false,
                    display: true,
                    displayAdd: false,
                    displayChevron: true,
                    displayCount: false,
                    count: 0,
                    linkAdd: '',
                    linkMain: self.genUrl('address'),
                    text: resx.localize('WorkAddress')
                },
                {
                    disabled: false,
                    disabledAdd: false,
                    display: true,
                    displayAdd: false,
                    displayChevron: true,
                    displayCount: false,
                    count: 0,
                    linkAdd: self.genUrl('addidditionalFields'),
                    linkMain: self.genUrl('additionalFields'),
                    text: resx.localize('AdditionalFields')
                },
                {
                    disabled: false,
                    disabledAdd: false,
                    display: self.detail.WorkOrderTypeId !== "",
                    displayAdd: false,
                    displayChevron: true,
                    displayCount: false,
                    count: 0,
                    linkAdd: self.genUrl('addidditionalFieldsType'),
                    linkMain: self.genUrl('additionalFieldsType'),
                    text: `${resx.localize('AdditionalFields')} (${self.detail.WorkOrderTypeId})`
                },
                {
                    disabled: false,
                    disabledAdd: false,
                    display: true,
                    displayAdd: false,
                    displayChevron: true,
                    displayCount: false,
                    count: 0,
                    linkAdd: '',
                    linkMain: self.genUrl('summary'),
                    text: resx.localize('WorkOrderSummary')
                }
            ];
        }

        function setViewModelProperties() {
            self.signatureAvailable = self.client === null || self.client.MobilitySignatureRequiredness !== 2;
            self.completeAvailable = self.client && self.client.MobilitySignatureRequiredness !== 1;

            self.readonly = self.detail.IsReadOnly;

            setLabels();
            setButtonLinks();
        }

        function loadData(workOrderId) {
            self.workOrderId = workOrderId;
            var dfdWorkOrder = workOrderService.get(workOrderId);
            var dfdClient = clientService.getClientInfoForWorkOrder(workOrderId);

            return jQuery.when(dfdWorkOrder, dfdClient)
                .done(function (detail, client) {
                    self.detail = detail;
                    if (client) {
                        self.client = client;
                    }
                    setViewModelProperties();
                });
        }

        function validateReadyToComplete() {
            if (!self.detail.ReadyToComplete.IsReadyToComplete) {
                if(!self.detail.ReadyToComplete.AllDispatchesTimeEntryIsEntered) {
                    var message = resx.localize("msg_FieldService_EnterTimeForXEmployees").replace("{0}", self.detail.PresentDispatchesCount);
                    notificationHelper.showWarning(message, null, { timeOut: 0 });
                }

                if(!self.detail.ReadyToComplete.AllRequiredAdditionalFieldsIsEntered) {
                    message = self.additionalFieldsHelper.getRequiredRessourceBySourceType(AdditionalFieldsSourceType.FieldService);
                    notificationHelper.showWarning(message, null, { timeOut: 0 });
                }
            }

            return self.detail.ReadyToComplete.IsReadyToComplete;
        }

        //#endregion

        //#region Public Functions
        ctor.prototype.activate = function (params) {
            return loadData(params.workOrderId);
        };

        ctor.prototype.complete = function () {
            if (self.client === null) {
                return;
            }

            if (validateReadyToComplete()) {
                notificationHelper.showConfirmation(resx.localize('msg_CompleteWorkOrderConfirmationText'))
                    .done(function (success) {
                        if (success) {
                            routerHelper.showLoading();

                            workOrderService.complete(self.detail.Id)
                                .done(function () {
                                    //Never call printForm in fieldService (233426)
                                    //workOrderService.printForm(self.detail.Id, '');
                                    routerHelper.navigate(routerHelper.navigateTo('FieldService'));
                                })
                                .always(routerHelper.hideLoading);
                        }
                    });
            }
        };

        ctor.prototype.genUrl = function (routeName) {
            let url = routerHelper.getRelativeUrl(routeName);
            let queryString = {
                readonly: self.readonly,
                isCompleted: self.detail.IsCompleted,
                workOrderId: self.workOrderId,
                workOrderType: this.detail.WorkOrderTypeId,
                projectCode: this.detail.ProjectCode,
                dispatchProjectCode: this.detail.ProjectCode
            };

            url += routerHelper.addQuerystring(queryString);

            return  url;
        };

        ctor.prototype.genUrlAddHours = function (routeName) {
            return routerHelper.getRelativeUrl(routeName) + routerHelper.addQuerystring({
                readonly: self.readonly,
                selectedEmployees: JSON.stringify([]),
                multi: true,
                isEmployee: true
            });
        };

        ctor.prototype.genUrlEditMode = function (routeName, editMode) {
            return routerHelper.getRelativeUrl(routeName) + routerHelper.addQuerystring({ readonly: editMode === enumHelper.fieldServiceEditMode().SUB ? false : self.readonly, editMode: editMode });
        };

        ctor.prototype.navigateToSummarySignature = function () {
            if (validateReadyToComplete()) {
                viewbag({ isFromFieldServiceSignature: true });
                routerHelper.navigate(self.genUrl('summary'));
            }
        };
        //#endregion

        return ctor;
    })();

    return viewModel;
});
