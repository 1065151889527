define([
    "services/requests",
    "helpers/apiHelper"
], function (requests, apiHelper) {
    "use strict";

    function getRecommendationsFromUrl(url, status, page) {
        return requests.getJson(
            url + "/recommendations?"
            + "recommendationstatus=" + status
            + (page ? "&page=" + page : "")
        );
    }

    var exports = {
        getRecommendationTypes: function () {
            return requests.getJson(apiHelper.getBaseUrl() + "/recommendationtypes");
        },

        addRecommendation: function (data) {
            return requests.post(apiHelper.getBaseUrl() + "/recommendations", data);
        },

        addRecommendationForDispatch: function (dispatchId, data) {
            return requests.post(apiHelper.getDispatchBaseUrl(dispatchId) + "/recommendations", data);
        },

        addRecommendationForProject: function (dispatchProjectCode, data) {
            return requests.post(apiHelper.getDispatchProjectBaseUrl(dispatchProjectCode) + "/recommendations", data);
        },

        addRecommendationForWorkOrder: function (workOrderId, data) {
            return requests.post(apiHelper.getWorkOrderBaseUrl(workOrderId) + "/recommendations", data);
        },

        getRecommendations: function (status, page) {
            return getRecommendationsFromUrl(apiHelper.getBaseUrl(), status, page);
        },

        getRecommendationsForDispatch: function (dispatchId, status, page) {
            return getRecommendationsFromUrl(apiHelper.getDispatchBaseUrl(dispatchId), status, page);
        },

        getRecommendationsForProject: function (dispatchProjectCode, status, page) {
            return getRecommendationsFromUrl(apiHelper.getDispatchProjectBaseUrl(dispatchProjectCode), status, page);
        },

        getRecommendationsForWorkOrder: function (workOrderId, status, page) {
            return getRecommendationsFromUrl(apiHelper.getWorkOrderBaseUrl(workOrderId), status, page);
        },

        getRecommendation: function (id) {
            return requests.getJson(apiHelper.getBaseUrl() + "/recommendations/" + id);
        }
    };

    return exports;
});
