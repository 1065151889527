define([
    "knockout",
    "helpers/routerHelper",
    "services/documentService",
    "helpers/documentHelper"
], function (ko, routerHelper, documentService, documentHelper) {
    "use strict";

    var viewModel = (function() {
        var self = null;

        function ctor() {
            self = this;

            //#region Properties
            self.readonly = false;
            self.dispatchProjectCode = ko.observable();

            self.actions = {};
            //#endregion
        }

        //#region Public Methods
        ctor.prototype.activate = function(params) {
            var paramsQueryString = routerHelper.getQuerystring(params.q);
            self.readonly = !(paramsQueryString && paramsQueryString.readonly === "false");
            self.dispatchProjectCode(params.dispatchProjectCode);

            self.actions = {
                getSections: documentService.getSectionsByDispatchProjectCode.bind(self, params.dispatchProjectCode),
                getSectionDocuments: documentService.getDocumentsByDispatchProjectCode.bind(self, params.dispatchProjectCode),
                getDocumentUrl: documentHelper.getDocumentUrlById.bind(self),
                deleteDocument: documentService.deleteDocument.bind(documentService)
            };
        };
        //#endregion

        return ctor;
    })();

    return viewModel;
});
