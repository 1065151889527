/****************************************************************************************
 * IMPORTANT - KNOWN ISSUE
 * In one run (no refresh), if a dispatch is accessed from the mobile site,
 * then deleted in maestro* and a new dispatch is added to the same person,
 * the header won't be refreshed if you access the new dispatch since they have the 
 * same dispatchId.
 * 
 * This is cause by maestro* which attributes the first available number as dispatchId
 * instead of using an auto-increment identity field.
 ****************************************************************************************/
import { bindable, transient } from "aurelia-framework";

import { default as dispatchService } from "services/dispatchService";
import { default as projectService } from "services/projectService";
import { default as dateHelper } from "helpers/dateHelper";

function updateValues(self, dispatchProjectCode, dispatchDate, description) {
    if (dispatchDate) {
        self.title = dateHelper.getFullTextDate(dispatchDate);
    } else {
        self.title = '';
    }

    self.subtitle = dispatchProjectCode + " - " + description;
    self.url = "#project/" + encodeURIComponent(dispatchProjectCode) + "/information";

    self.previousDispatchProjectCode = dispatchProjectCode;
    self.previousDescription = description;
}

function loadData(self) {
    if (self.dispatchProjectCode !== self.previousDispatchProjectCode) {
        projectService.getHeader(self.dispatchProjectCode).done( (data) => {
            updateValues(self, self.dispatchProjectCode, self.dispatchDate, data.Description);
        });
    } else {
        updateValues(self, self.dispatchProjectCode, self.dispatchDate, self.previousDescription);
    }
}

@transient()
export class ProjectHeader {

    //decorators
    @bindable dispatchProjectCode;
    @bindable dispatchDate;
    @bindable hideInfo = false;

    previousDispatchProjectCode = '';
    previousDescription = '';
    title = '';
    subtitle = '';
    url = '';

    attached() {
        loadData(this);
    }
}