import {default as logger } from "core/logger";

define([
    "underscore",
    "knockout",
    "moment",
    "helpers/dateHelper",
    "helpers/enumHelper",
    "helpers/routerHelper",
    "services/timesheetService"
], function (_, ko, moment, dateHelper, enumHelper, routerHelper, timesheetService) {
    "use strict";

    var exports = {
        LIST_SHOW_CURRENT: "ONGOING",
        LIST_SHOW_PAST: "PREVIOUS",
        LIST_GROUP_TYPE_DATE: "DATE",
        LIST_GROUP_TYPE_EMPLOYEE: "EMPLOYEE",

        PAGE_TYPE_INDEX: "PAGE_TYPE_INDEX",
        PAGE_TYPE_SUMMARY: "PAGE_TYPE_SUMMARY",

        loadList: function (viewmodel, groupType, period) {
            routerHelper.showLoading();

            var dfd = fetchList(viewmodel, groupType, period);
            dfd.always(routerHelper.hideLoading);

            dfd.done(function (data) {
                fetchListCallBack(viewmodel, data, groupType, period);
            });

            return dfd;
        },

        anyTimeEntry: function (list, predicate) {
            // Return true if any of the time entries for any date/employee/project match the predicate
            return _.some(list,
                function (firstGroup) {
                    // First grouping level
                    return _.some(firstGroup.Items,
                        function (secondGroup) {
                            //second grouping level
                            return _.some(secondGroup.Items,
                                function (projectGroup) {
                                    //Proj grouping level
                                    return _.some(projectGroup.Items, predicate);
                                });
                        });
                });
        },

        getEquipmentsCounterCountforTimesheet: function (period) {
            var previousPayPeriod = isPreviousPayPeriod(period);
            return timesheetService.getEquipmentsCounterCountforTimesheet(previousPayPeriod);
        },

        isPreviousPayPeriod: function (period) {
            return isPreviousPayPeriod(period);
        }
    };

    function isPreviousPayPeriod(period) {
        return period !== undefined && period === exports.LIST_SHOW_PAST;
    }

    function fetchList(viewmodel, groupType, period) {
        var previousPayPeriod = isPreviousPayPeriod(period);
        if (viewmodel.loadCurrentEmployeeOnly) {
            return timesheetService.getTimesheetsGroupedByType(previousPayPeriod);
        }
        
        if (groupType === exports.LIST_GROUP_TYPE_DATE) {
            return timesheetService.getTimesheetsGroupedByDate(previousPayPeriod);
        }

        if (groupType === exports.LIST_GROUP_TYPE_EMPLOYEE) {
            return timesheetService.getTimesheetsGroupedByEmployee(previousPayPeriod);
        }

        logger.error("err_TimesheetUnkownListGroupType");
    }

    function fetchListCallBack(viewmodel, data, groupType, period) {
        viewmodel.isTeamLeader(data.CurrentEmployeeIsTeamLeader);
        viewmodel.teamLeaderIsResponsibleForTimeEntry(data.TeamLeaderIsResponsibleForTimeEntry);
        viewmodel.employee(data.CurrentEmployee);
        viewmodel.linkedEquipments(data.LinkedEquipments);


        if (viewmodel.loadCurrentEmployeeOnly) {
            data.list = data.Groups;
        }
        else {
            data.list  = _.map(data.Groups, function (x) { return mapFirstLevelGroup(viewmodel, x, groupType); });
        }

        viewmodel._cache[period][groupType] = data;
        viewmodel._currentData(data);
    }
    
    function mapFirstLevelGroup(viewModel, group1, groupType) {
        var firstGroup = buildBaseGroupItem(viewModel, group1);

        if (groupType === exports.LIST_GROUP_TYPE_DATE) {
            firstGroup.title = getDateTitle(group1.Key);
        } else {
            var firstDayOfWeek = _.first(group1.Items);
            buildEmployeeGroupItem(viewModel, firstGroup, group1.Key, firstDayOfWeek);
        }

        firstGroup.Items = _.map(group1.Items, function (x) { return mapSecondLevelGroup(viewModel, x, groupType); });

        return firstGroup;
    }

    function mapSecondLevelGroup(viewModel, group2, groupType) {
        var secondGroup = buildBaseGroupItem(viewModel, group2);

        if (groupType === exports.LIST_GROUP_TYPE_DATE) {
            buildEmployeeGroupItem(viewModel, secondGroup, group2.Key, group2);
        } else {
            secondGroup.title = getDateTitle(group2.Key);
        }

        return secondGroup;
    }

    function getDateTitle(key) {
        return dateHelper.getDayOfWeekTextDate(key);
    }

    function getEmployeeTitle(key) {
        return key.FirstName + " " + key.LastName;
    }

    function buildBaseGroupItem(viewmodel, data) {
        var group = {};

        group.icon = '';
        group.showCheckmark = false;
        group.showLinkedEquipment = false;
        group.selected = ko.observable(false);
        group.showIcon = false;
        group.isOpened = ko.observable(false);
        group.linkedEquipment = ko.observableArray([]);
        group.hours = dateHelper.hoursTohmm(data.TotalHours);

        group.Items = _.map(data.Items, function (x, index) {
            x.isLast = (index === data.Items.length - 1);
            x.readonly = viewmodel.type === exports.PAGE_TYPE_SUMMARY;
            return x;
        });

        return group;
    }

    function buildEmployeeGroupItem(viewmodel, group, employee, secondLevelGroup) {
        group.title = getEmployeeTitle(employee);

        var firstProj = _.first(secondLevelGroup.Items);
        var firstTimeEntry = _.first(firstProj.Items);
        var status = firstTimeEntry.Status;
        var tsStatus = _.find(enumHelper.timesheetStatus, function (x) { return x.id === status; });

        group.showCheckmark = canSelect(viewmodel, firstTimeEntry, tsStatus);
        group.showIcon = showIcon(viewmodel, firstTimeEntry, tsStatus);
        group.showLinkedEquipment = !_.isEmpty(employee.LinkedEquipment);
        group.linkedEquipment(employee.LinkedEquipment);

        if (group.showIcon) {
            group.icon = tsStatus.icon;
        }
    }

    function canSelect(viewmodel, timeEntry, tsStatus) {
        if (viewmodel.type !== exports.PAGE_TYPE_SUMMARY) {
            return false;
        }

        if (viewmodel.isTeamLeader()) {
            if (viewmodel.teamLeaderIsResponsibleForTimeEntry() || viewmodel.employee().Id === timeEntry.EmployeeId) {
                //If is responsible for timeentry
                return tsStatus.id === enumHelper.timesheetStatus.INPROGRESS.id ||
                    tsStatus.id === enumHelper.timesheetStatus.COMPLETED.id;
            }

            // Teamleader not responsible
            return tsStatus.id === enumHelper.timesheetStatus.COMPLETED.id;
        }

        // Not teamleader
        return tsStatus.id === enumHelper.timesheetStatus.INPROGRESS.id;
    }

    function showIcon(viewmodel, timeEntry, tsStatus) {
        // No icons for this status
        if (!(tsStatus && tsStatus.icon)) {
            return false;
        }

        if (tsStatus.id === enumHelper.timesheetStatus.APPROVED.id) {
            //Always display approved icons
            return true;
        }

        var userIsResponsibleForThisTimeEntry = viewmodel.teamLeaderIsResponsibleForTimeEntry() ||
            viewmodel.employee().Id === timeEntry.EmployeeId;

        // Hide icons if current user is responsible for the timesheet
        return !userIsResponsibleForThisTimeEntry;
    }

    return exports;
});
