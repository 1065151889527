import { transient } from "aurelia-framework";

import * as ko from "knockout";
import * as dispatchService from "services/dispatchService";
import * as projectService from "services/projectService";
import * as dateHelper from "helpers/dateHelper";


@transient()
export class DailyEntryButton {
    constructor() {
        this.dateHelper = dateHelper;
        this.isProjectResponsible = false;
        this.date = "";
        this.status = "";
        this.isCanceled = "";
        this.urlToGo = "";
        this.noIcons = "";
        this.cancelReason = "";
    }

    activate(widgetSettings) {
        this.date = widgetSettings.date;
        this.isProjectResponsible = widgetSettings.isProjectResponsible;
        this.status = widgetSettings.status;
        this.isCanceled = widgetSettings.isCanceled;
        this.urlToGo = widgetSettings.urlToGo;
        this.noIcons = widgetSettings.noIcons;
        this.cancelReason = widgetSettings.cancelReason;
    }

    isComplete(status) {
        //!! -> if status == "" we get a bool value instead of ""
        return !!(status && status !== "2" && status !== "X");
    }

    isReturn(status) {
        return status && status === "X";
    }

    isInProgress(status) {
        return status && status === "2";
    }
}
