// GENERATED FILE - DO NOT MODIFY
import { DocumentModel } from "api/models/company/document/document-model";
import { DocumentSectionModel } from "api/models/company/document/document-section-model";
import { ListDocumentModel } from "api/models/company/document/list-document-model";
import { PurchaseOrderItemModel } from "api/models/company/purchase-order-item-model";
import { PurchaseOrderModel } from "api/models/company/purchase-order-model";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class PurchaseOrderProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async GetPurchaseOrderByDispatchId(dispatchId: number, getOnlyFullyReceived: boolean, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<PurchaseOrderModel[] | null> {
        const uri = this.buildUri(
            "/dispatch/{dispatchId}/purchaseorders",
            { dispatchId: (dispatchId === null || dispatchId === undefined) ? undefined : dispatchId.toString() },
            { getOnlyFullyReceived: (getOnlyFullyReceived === null || getOnlyFullyReceived === undefined) ? undefined : getOnlyFullyReceived.toString(), filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: PurchaseOrderModel[] | null = await this.get<PurchaseOrderModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.PurchaseOrderModel");
        return data;
    }

    public async GetProjectPurchaseOrders(projectCode: string | null, getClosedOrders: boolean, getOnlyFullyReceived: boolean, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<PurchaseOrderModel[] | null> {
        const uri = this.buildUri(
            "/project/purchaseorders/{projectCode}",
            { projectCode: (projectCode === null || projectCode === undefined) ? undefined : projectCode },
            { getClosedOrders: (getClosedOrders === null || getClosedOrders === undefined) ? undefined : getClosedOrders.toString(), getOnlyFullyReceived: (getOnlyFullyReceived === null || getOnlyFullyReceived === undefined) ? undefined : getOnlyFullyReceived.toString(), filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: PurchaseOrderModel[] | null = await this.get<PurchaseOrderModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.PurchaseOrderModel");
        return data;
    }

    public async GetWorkOrderPurchaseOrders(workOrderId: string | null, getOnlyFullyReceived: boolean, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<PurchaseOrderModel[] | null> {
        const uri = this.buildUri(
            "/workOrder/purchaseorders/{workOrderId}",
            { workOrderId: (workOrderId === null || workOrderId === undefined) ? undefined : workOrderId },
            { getOnlyFullyReceived: (getOnlyFullyReceived === null || getOnlyFullyReceived === undefined) ? undefined : getOnlyFullyReceived.toString(), filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: PurchaseOrderModel[] | null = await this.get<PurchaseOrderModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.PurchaseOrderModel");
        return data;
    }

    public async GetPurchaseOrder(purchaseOrderId: string | null, extension: string | null | undefined, requestConfig?: HttpClientRequestConfig): Promise<PurchaseOrderModel | null> {
        const uri = this.buildUri(
            "/purchaseorders/{purchaseOrderId}",
            { purchaseOrderId: (purchaseOrderId === null || purchaseOrderId === undefined) ? undefined : purchaseOrderId },
            { extension: (extension === null || extension === undefined) ? undefined : extension }
        );

        const data: PurchaseOrderModel | null = await this.get<PurchaseOrderModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.PurchaseOrderModel");
        return data;
    }

    public async SavePurchaseOrderHeaderForDispatch(dispatchId: number, purchaseOrderHeader: PurchaseOrderModel | null, requestConfig?: HttpClientRequestConfig): Promise<PurchaseOrderModel | null> {
        const uri = this.buildUri(
            "/dispatch/{dispatchId}/purchaseorders",
            { dispatchId: (dispatchId === null || dispatchId === undefined) ? undefined : dispatchId.toString() },
            null
        );

        const data: PurchaseOrderModel | null = await this.post<PurchaseOrderModel | null, PurchaseOrderModel | null>(uri, purchaseOrderHeader, requestConfig);
        this.transformModels(data, "Company.PurchaseOrderModel");
        return data;
    }

    public async SaveProjectPurchaseOrderHeader(projectCode: string | null, purchaseOrderHeader: PurchaseOrderModel | null, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<PurchaseOrderModel[] | null> {
        const uri = this.buildUri(
            "/project/purchaseorders/{projectCode}",
            { projectCode: (projectCode === null || projectCode === undefined) ? undefined : projectCode },
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: PurchaseOrderModel[] | null = await this.post<PurchaseOrderModel | null, PurchaseOrderModel[] | null>(uri, purchaseOrderHeader, requestConfig);
        this.transformModels(data, "Company.PurchaseOrderModel");
        return data;
    }

    public async DeletePurchaseOrder(purchaseOrderId: string | null, extension: string | null | undefined, requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        const uri = this.buildUri(
            "/purchaseorders/{purchaseOrderId}",
            { purchaseOrderId: (purchaseOrderId === null || purchaseOrderId === undefined) ? undefined : purchaseOrderId },
            { extension: (extension === null || extension === undefined) ? undefined : extension }
        );

        const data: boolean = await this.delete<boolean>(uri, requestConfig);
        return data;
    }

    public async DeletePurchaseOrderLine(purchaseOrderId: string | null, lineId: number, extension: string | null | undefined, requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        const uri = this.buildUri(
            "/purchaseorders/{purchaseOrderId}/{lineId}",
            { purchaseOrderId: (purchaseOrderId === null || purchaseOrderId === undefined) ? undefined : purchaseOrderId, lineId: (lineId === null || lineId === undefined) ? undefined : lineId.toString() },
            { extension: (extension === null || extension === undefined) ? undefined : extension }
        );

        const data: boolean = await this.delete<boolean>(uri, requestConfig);
        return data;
    }

    public async SavePurchaseOrderDetailForDispatch(dispatchId: number, purchaseOrderId: string | null, lineId: number, items: Array<PurchaseOrderItemModel | null> | null, requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        const uri = this.buildUri(
            "/dispatch/{dispatchId}/purchaseorders/{purchaseOrderId}/{lineId}",
            { dispatchId: (dispatchId === null || dispatchId === undefined) ? undefined : dispatchId.toString(), purchaseOrderId: (purchaseOrderId === null || purchaseOrderId === undefined) ? undefined : purchaseOrderId, lineId: (lineId === null || lineId === undefined) ? undefined : lineId.toString() },
            null
        );

        const data: boolean = await this.post<Array<PurchaseOrderItemModel | null> | null, boolean>(uri, items, requestConfig);
        return data;
    }

    public async SavePurchaseOrderDetailForProject(purchaseOrderId: string | null, lineId: number, items: Array<PurchaseOrderItemModel | null> | null, requestConfig?: HttpClientRequestConfig): Promise<void> {
        const uri = this.buildUri(
            "/project/purchaseorders/{purchaseOrderId}/{lineId}",
            { purchaseOrderId: (purchaseOrderId === null || purchaseOrderId === undefined) ? undefined : purchaseOrderId, lineId: (lineId === null || lineId === undefined) ? undefined : lineId.toString() },
            null
        );

        await this.post<Array<PurchaseOrderItemModel | null> | null, void>(uri, items, requestConfig);
    }

    public async SavePurchaseOrderHeaderForWorkOrder(workOrderId: string | null, purchaseOrderHeader: PurchaseOrderModel | null, requestConfig?: HttpClientRequestConfig): Promise<PurchaseOrderModel | null> {
        const uri = this.buildUri(
            "/workOrder/{workOrderId}/purchaseorders",
            { workOrderId: (workOrderId === null || workOrderId === undefined) ? undefined : workOrderId },
            null
        );

        const data: PurchaseOrderModel | null = await this.post<PurchaseOrderModel | null, PurchaseOrderModel | null>(uri, purchaseOrderHeader, requestConfig);
        this.transformModels(data, "Company.PurchaseOrderModel");
        return data;
    }

    public async SavePurchaseOrderDetailForWorkOrder(workOrderId: string | null, purchaseOrderId: string | null, lineId: number, items: Array<PurchaseOrderItemModel | null> | null, requestConfig?: HttpClientRequestConfig): Promise<void> {
        const uri = this.buildUri(
            "/workOrder/{workOrderId}/purchaseorders/{purchaseOrderId}/{lineId}",
            { workOrderId: (workOrderId === null || workOrderId === undefined) ? undefined : workOrderId, purchaseOrderId: (purchaseOrderId === null || purchaseOrderId === undefined) ? undefined : purchaseOrderId, lineId: (lineId === null || lineId === undefined) ? undefined : lineId.toString() },
            null
        );

        await this.post<Array<PurchaseOrderItemModel | null> | null, void>(uri, items, requestConfig);
    }

    public async GetSections(purchaseOrderId: string | null, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<DocumentSectionModel[] | null> {
        const uri = this.buildUri(
            "/purchaseorders/{purchaseOrderId}/documents/sections",
            { purchaseOrderId: (purchaseOrderId === null || purchaseOrderId === undefined) ? undefined : purchaseOrderId },
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: DocumentSectionModel[] | null = await this.get<DocumentSectionModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Document.DocumentSectionModel");
        return data;
    }

    public async GetDocuments(purchaseOrderId: string | null, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ListDocumentModel[] | null> {
        const uri = this.buildUri(
            "/purchaseorders/{purchaseOrderId}/documents",
            { purchaseOrderId: (purchaseOrderId === null || purchaseOrderId === undefined) ? undefined : purchaseOrderId },
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: ListDocumentModel[] | null = await this.get<ListDocumentModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Document.ListDocumentModel");
        return data;
    }

    public async AddDocuments(purchaseOrderId: string | null, model: Array<DocumentModel | null> | null, dispatchTemplateId: string | null, requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        const uri = this.buildUri(
            "/purchaseorders/{purchaseOrderId}/documents",
            { purchaseOrderId: (purchaseOrderId === null || purchaseOrderId === undefined) ? undefined : purchaseOrderId },
            { dispatchTemplateId: (dispatchTemplateId === null || dispatchTemplateId === undefined) ? undefined : dispatchTemplateId }
        );

        const data: boolean = await this.post<Array<DocumentModel | null> | null, boolean>(uri, model, requestConfig);
        return data;
    }

    public async AllowedSendTransfer(requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        const uri = this.buildUri(
            "/purchaseorders/allowed-send-transfer",
            null,
            null
        );

        const data: boolean = await this.get<boolean>(uri, requestConfig);
        return data;
    }

    public async Send(purchaseOrderId: string | null, email: string | null, extension: string | null | undefined, requestConfig?: HttpClientRequestConfig): Promise<void> {
        const uri = this.buildUri(
            "/purchaseorders/{purchaseOrderId}/send",
            { purchaseOrderId: (purchaseOrderId === null || purchaseOrderId === undefined) ? undefined : purchaseOrderId },
            { email: (email === null || email === undefined) ? undefined : email, extension: (extension === null || extension === undefined) ? undefined : extension }
        );

        await this.post<void, void>(uri, undefined, requestConfig);
    }

    public async Transfer(purchaseOrderId: string | null, extension: string | null | undefined, requestConfig?: HttpClientRequestConfig): Promise<void> {
        const uri = this.buildUri(
            "/purchaseorders/{purchaseOrderId}/transfer",
            { purchaseOrderId: (purchaseOrderId === null || purchaseOrderId === undefined) ? undefined : purchaseOrderId },
            { extension: (extension === null || extension === undefined) ? undefined : extension }
        );

        await this.post<void, void>(uri, undefined, requestConfig);
    }

    public async ChangeSupplier(purchaseOrderId: string | null, SupplierCode: string | null, extension: string | null | undefined, requestConfig?: HttpClientRequestConfig): Promise<PurchaseOrderModel | null> {
        const uri = this.buildUri(
            "/purchaseorders/{purchaseOrderId}/change-supplier",
            { purchaseOrderId: (purchaseOrderId === null || purchaseOrderId === undefined) ? undefined : purchaseOrderId },
            { SupplierCode: (SupplierCode === null || SupplierCode === undefined) ? undefined : SupplierCode, extension: (extension === null || extension === undefined) ? undefined : extension }
        );

        const data: PurchaseOrderModel | null = await this.post<void, PurchaseOrderModel | null>(uri, undefined, requestConfig);
        this.transformModels(data, "Company.PurchaseOrderModel");
        return data;
    }
}
