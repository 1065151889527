import UserAccessService from "services/user-access-service";
import { DispatchProjectService } from "services/dispatch-project-service";

define([
    "knockout",
    "jquery",
    "helpers/dateHelper",
    "helpers/routerHelper",
    "services/projectService",
    "services/dispatchService",
    "services/defaultService",
    "core/resx",
    "helpers/enumHelper",
    "widgets/dailyEntryButton"
], function (ko, jQuery, dateHelper, routerHelper, projectService, dispatchService, defaultService, resx, enumHelper) {
    "use strict";

    var _ongoingHasOnlyOnePage = false;

    var viewModel = (function () {
        var self = null;

        ctor.inject = [UserAccessService, DispatchProjectService];

        function ctor(userAccessService, dispatchProjectService) {
            self = this;

            //#region PropertiesshowScrollMessage
            self.dateHelper = dateHelper;
            self.routerHelper = routerHelper;
            self.sectionShown = ko.observable("");
            self.dispatchProjectCode = ko.observable("");
            self.list = ko.observableArray([]);
            self.ongoingList = ko.observableArray([]);
            self.closeList = ko.observableArray([]);
            self.counts = ko.observable([]);
            self.resx = resx;
            self.isProjectResponsible = false;
            self.noDataText = ko.observable("");
            self.lblShowMoreEntries = ko.observable(resx.localize('ShowMoreEntries'));
            self.moreEntriesLoading = ko.observable(false);
            self.enableMoreEntries = ko.observable(true);
            self.displayMoreEntries = ko.pureComputed(function () {
                return !_ongoingHasOnlyOnePage && self.sectionShown() === "ongoing";
            });
            self.page = ko.observable({ 'close': 1, 'ongoing': 1 });
            self.maxDate = ko.observable('');
            self.maxDateSubscribe = null;
            self.loadingStatus = ko.observable(false);
            self.killScroll = true;
            self.showScrollMessage = ko.observable(false);
            self.scrollMessageText = ko.observable(resx.localize('ScrollForMore'));
            self.userAccessService = userAccessService;
            self.dispatchProjectService = dispatchProjectService;
            self.enumHelper = enumHelper;
            self.projectCode = "";

            self.statusesToIncludeInCompletedTab = [enumHelper.DailyEntryStatus.COMPLETE, enumHelper.DailyEntryStatus.TRANSFERRED, enumHelper.DailyEntryStatus.APPROVED,
                enumHelper.DailyEntryStatus.ACCEPTED, enumHelper.DailyEntryStatus.INPROGRESSGP];

            self.statusesToExcludeInCurrentTab = [enumHelper.DailyEntryStatus.COMPLETE, enumHelper.DailyEntryStatus.TRANSFERRED, enumHelper.DailyEntryStatus.APPROVED,
                enumHelper.DailyEntryStatus.ACCEPTED, enumHelper.DailyEntryStatus.RETURNED, enumHelper.DailyEntryStatus.INPROGRESSGP];
            //#endregion
        }

        //#region Private Functions
        function initializeMaxDate() {
            self.maxDate = ko.observable(dateHelper.formatDate('YYYY-MM-DD', dateHelper.getNow()));
        }

        function bindViewModel(dispatchProjectCode, querystring) {
            var params = routerHelper.getQuerystring(querystring);
            self.isProjectResponsible = (params && params.isProjectResponsible === "true") || false;

            self.ongoingList([]);
            self.closeList([]);
            self.sectionShown("");

            self.dispatchProjectCode(dispatchProjectCode);
            initializeMaxDate();
        }

        function loadDispatches(dispatchProjectCode, isCompletedTab, maxDate) {
            routerHelper.showLoading();

            var statusesToInclude = isCompletedTab ? self.statusesToIncludeInCompletedTab : null;
            var statusesToExclude = !isCompletedTab ? self.statusesToExcludeInCurrentTab : null;

            // On tri par desc lorsqu'on veut les complétés
            var sortByAsc = isCompletedTab ? false : true;

            // Si on est dans l'onglet d'entrées complétées, on ne veut pas les retours d'entrées
            var includeReturnedEntries = !isCompletedTab;

            return dispatchService.getListByDispatchProjectCode(dispatchProjectCode, statusesToInclude, statusesToExclude, sortByAsc, includeReturnedEntries, 1, maxDate)
                .done(function (data) {
                    var list = data;
                    if (isCompletedTab) {
                        self.closeList(list);
                        if (!list.length) {
                            self.noDataText(resx.localize("NoHistoricData"));
                            disposeScroll();
                        } else {
                            if (list.length === defaultService.getPageSize()) {
                                initScroll();
                                self.killScroll = false;
                            } else {
                                self.killScroll = true;
                                disposeScroll();
                            }
                        }
                        self.loadingStatus(false);
                    } else {
                        self.ongoingList(list);
                        if (!list.length) {
                            self.noDataText(resx.localize("NoDispatchesPlanned"));
                            _ongoingHasOnlyOnePage = true;
                        } else {
                            _ongoingHasOnlyOnePage = !(list.length >= defaultService.getPageSize());
                            self.enableMoreEntries(list.length >= defaultService.getPageSize());
                        }
                    }

                    self.list(list);
                }).always(function () {
                    routerHelper.hideLoading();
                });
        }

        function loadData(dispatchProjectCode) {
            return loadDispatches(dispatchProjectCode, false);
        }

        function loadDispatchesList(list, isCompletedTab) {
            if (list.length) {
                routerHelper.showLoading();
                self.list(list);
                routerHelper.hideLoading();
            } else {
                loadDispatches(self.dispatchProjectCode(), isCompletedTab, self.maxDate());
                //self.showMoreEntries(isCompletedTab);
            }
        }

        function initScroll() {
            jQuery(document).scroll(scrollHandler);
            showScrollMessage("scroll");
        }

        function disposeScroll() {
            jQuery(document).off("scroll", scrollHandler);
            hideScrollMessage();
        }

        function scrollHandler() {
            if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
                disposeScroll();
                showScrollMessage("loading");
                self.showMoreEntries(true);
            }
        }

        function showScrollMessage(msgId) {
            if (msgId === "loading") {
                self.scrollMessageText(resx.localize("LoadingMoreResults"));
            } else {
                self.scrollMessageText(resx.localize("ScrollForMore"));
            }

            self.showScrollMessage(true);
        }

        function hideScrollMessage() {
            self.showScrollMessage(false);
        }
        //#endregion

        //#region Public Functions
        ctor.prototype.activate = async function (params) {
            bindViewModel(params.id, params.q);

            projectService.getItemsCount(params.id).done(function (data) {
                self.counts(data);
            });

            self.projectCode = (await self.dispatchProjectService.GetProjectFromDispatchProjectCode(self.dispatchProjectCode())).Id;

            if (!self.sectionShown()) {
                self.sectionShown("ongoing");
                return loadData(params.id);
            } else {
                self.show(self.sectionShown());
                return new jQuery.Deferred().resolve();
            }
        };

        ctor.prototype.attached = function () {

        };

        ctor.prototype.deactivate = function () {
            if (self.maxDateSubscribe) {
                self.maxDateSubscribe.dispose();
            }
            initializeMaxDate();
            disposeScroll();
            self.killScroll = true;
            self.page().close = 1;
            self.page().ongoing = 1;
        };

        ctor.prototype.show = function (section) {
            self.list([]);
            self.sectionShown(section);
            if (section === "close") {
                if (!self.maxDateSubscribe) {
                    // TODO JL: move this code to the attached handler once we convert the viewmodel to aurelia.
                    // For now we cannot since this handler will be called before the widget compositionComplete,
                    // and the subscribtion is gonna fire when we init the datePicker.
                    self.maxDateSubscribe = self.maxDate.subscribe(function (newValue) {
                        self.killScroll = false;
                        self.page()[self.sectionShown()] = 1;
                        initScroll();
                        loadDispatches(self.dispatchProjectCode(), true, newValue);
                    });
                }

                loadDispatchesList(self.closeList(), true, self.maxDate());

                if (!self.killScroll) {
                    initScroll();
                }
            } else {
                loadDispatchesList(self.ongoingList(), false);
                disposeScroll();
            }
        };

        ctor.prototype.showMoreEntries = function (isCompletedTab) {
            self.lblShowMoreEntries(resx.localize('LoadingMoreEntries'));
            self.moreEntriesLoading(true);
            self.loadingStatus(true);
            self.page()[self.sectionShown()] += 1;

            var statusesToInclude = isCompletedTab ? self.statusesToIncludeInCompletedTab : null;
            var statusesToExclude = !isCompletedTab ? self.statusesToExcludeInCurrentTab : null;

            // On tri par desc lorsqu'on veut les complétés
            var sortByAsc = isCompletedTab ? false : true;

            // Si on est dans l'onglet d'entrées complétées, on ne veut pas les retours d'entrées
            var includeReturnedEntries = !isCompletedTab;

            dispatchService.getListByDispatchProjectCode(self.dispatchProjectCode(), statusesToInclude, statusesToExclude, sortByAsc,
                includeReturnedEntries, self.page()[self.sectionShown()], isCompletedTab ? self.maxDate() : null)
                .done(function (ls) {
                    var actualLs = isCompletedTab ? self.closeList() : self.ongoingList();
                    actualLs = actualLs.concat(ls);

                    self.lblShowMoreEntries(resx.localize('ShowMoreEntries'));
                    self.moreEntriesLoading(false);
                    self.loadingStatus(false);

                    if (isCompletedTab) {
                        self.closeList(actualLs);

                        if (ls.length >= defaultService.getPageSize()) {
                            initScroll();
                            self.killScroll = false;
                        } else {
                            self.killScroll = true;
                            disposeScroll();
                        }

                    } else {
                        self.ongoingList(actualLs);
                        self.enableMoreEntries(ls.length >= defaultService.getPageSize());
                    }

                    self.list(actualLs);
                })
                .fail(function () {
                    self.list([]);
                });
        };

        ctor.prototype.getDailyEntryUrl = function (startTime) {
            return routerHelper.getRelativeUrl("dailyEntry", dateHelper.dateFromUTC(startTime, "YYYY-MM-DD")) + routerHelper.addQuerystring({ isProjectResponsible: self.isProjectResponsible });
        };

        ctor.prototype.genUrl = function (rel) {

            return routerHelper.getRelativeUrl(rel) + routerHelper.addQuerystring({
                isProjectResponsible: self.isProjectResponsible,
                dispatchProjectCode: self.dispatchProjectCode(),
                projectCode: self.projectCode,
                isClosingDayMode: false
            });
        };

        //#endregion

        return ctor;
    })();

    return viewModel;
});
