import { ServiceCallContractQuotationFormType } from "api/enums/service-call-contract-quotation-form-type";
import { ServiceCallQuotationStatus } from "api/enums/service-call-quotation-status";
import { ValidationError } from "api/enums/validation-error";
import { ContactLookupModel } from "api/models/company/contact/contact-lookup-model";
import { ServiceQuotationSecuritySettingsModel } from "api/models/company/security/service-quotation-security-settings-model";
import { ServiceCallQuotationDetailsModel } from "api/models/company/service-call/service-call-quotation-details-model";
import { ContractDetailModel } from "api/models/company/service/contract-detail-model";
import { PagingInfo } from "api/paging-info";
import { autoinject, computedFrom } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { ValidationController, ValidationRules, validateTrigger } from "aurelia-validation";
import { ValidationAttributeValueConverter } from "converters/misc/validation-attribute-value-converter";
import { ErrorManager } from "error-management/error-manager";
import { NotificationHelper } from "helpers/notification-helper";
import { RouterHelper } from "helpers/router-helper";
import { ValidationHelper } from "helpers/validation-helper";
import { ContactService } from "services/contact-service";
import { CustomerContactService } from "services/customer-contact-service";
import { ServiceCallContractService } from "services/service-call-contract-service";
import { ServiceCallQuotationSecurityService } from "services/service-call-quotation-security-service";
import { ServiceCallQuotationService } from "services/service-call-quotation-service";

export interface QuotationSummaryParameters {
    quotationId: number;
    showSignature?: string;
}

@autoinject()
export class QuotationSummary {
    public quotation!: ServiceCallQuotationDetailsModel;
    public securitySettings: ServiceQuotationSecuritySettingsModel | null = null;
    public contract: ContractDetailModel | null = null;
    public isSignaturePadOpened: boolean = false;
    public contactId: string | null = null;
    public contactDescription: string | null = null;
    public contactName: string = "";
    public contactEmail: string = "";
    public showSignature: boolean = true;

    // TODO Déplacer cette logique à qqpart. Elle ne devrait pas être copié
    @computedFrom("quotation", "quotation.Status", "securitySettings")
    public get canCompleteAndAccept(): boolean {
        return (
            this.quotation !== null && !this.quotation.IsServiceContractNotFound &&
            this.quotation !== undefined &&
            (this.quotation.Status === ServiceCallQuotationStatus.InProgressByTheTechnician || this.quotation.Status === ServiceCallQuotationStatus.Entered) &&
            !!this.securitySettings &&
            (this.securitySettings.Statuses as ServiceCallQuotationStatus[]).includes(ServiceCallQuotationStatus.AcceptedByTheCustomer) &&
            this.showSignature
        );
    }

    @computedFrom("quotation", "quotation.Status", "securitySettings")
    public get readonly(): boolean {
        return (
            !this.quotation ||
            !this.securitySettings ||
            !(this.securitySettings.Statuses as ServiceCallQuotationStatus[]).includes(this.quotation.Status as ServiceCallQuotationStatus)
        );
    }

    @computedFrom("quotation")
    public get specifiedLanguage(): string | null {
        if (this.quotation === null) { return null; }
        return this.quotation.CustomerLanguage;
    }

    @computedFrom("contract")
    public get contractFormTypeIsDetailed(): boolean {
        return !!this.contract && this.contract.ServiceQuotationFormType === ServiceCallContractQuotationFormType.CustomerDetailed;
    }

    constructor(
        private readonly serviceCallQuotationService: ServiceCallQuotationService,
        private readonly serviceCallQuotationSecurityService: ServiceCallQuotationSecurityService,
        private readonly routerHelper: RouterHelper,
        private readonly contactService: ContactService,
        private readonly customerContactService: CustomerContactService,
        private readonly serviceCallContractService: ServiceCallContractService,
        private readonly validationHelper: ValidationHelper,
        private readonly errorManager: ErrorManager,
        private readonly i18n: I18N,
        private readonly validationAttributeValueConverter: ValidationAttributeValueConverter,
        private readonly validationController: ValidationController,
        private readonly notificationHelper: NotificationHelper
    ) {
    }

    // #region Lifecycle
    public async canActivate(params: QuotationSummaryParameters): Promise<void> {
        if (params.showSignature != null) {
            this.showSignature = params.showSignature === "true";
        }

        await Promise.all([this.initQuotation(Number(params.quotationId)), this.initSecuritySettings()]);
        if (this.serviceCallQuotationSecurityService.containNotInCatalogItem(this.quotation)) {

            if (this.canCompleteAndAccept) {
                this.showSignature = false;
                this.notificationHelper.showWarningKey("msg_QuotationSignatureNotAllowedContainsNonCatalogItem", "", { timeOut: 0 });
            }
        }
    }

    public async activate(params: QuotationSummaryParameters): Promise<void> {
        if (params.showSignature != null) {
            this.showSignature = params.showSignature === "true";
        }
        this.initValidation();
        await Promise.all([this.initQuotation(Number(params.quotationId)), this.initSecuritySettings()]);

        if (!!this.quotation && !this.quotation.IsWorkOrder) {
            await Promise.all([this.initDefaultContact(), this.initContract()]);
        }
    }
    // #endregion

    // #region Events Handlers
    public onContactChanged(event: CustomEvent): void {
        const selectedContact = event.detail as ContactLookupModel | null;

        if (selectedContact) {
            this.contactName = this.truncateContactName(selectedContact);
            this.contactEmail = selectedContact.Email || "";
        } else {
            this.contactName = "";
            this.contactEmail = "";
        }
    }

    public async onBtnSignClicked(): Promise<void> {
        const isValid = await this.validationHelper.validateControllerAndNotifyUserIfNecessary(this.validationController);

        if (isValid !== true) {
            return;
        }

        this.isSignaturePadOpened = true;
    }

    public async onSignatureCompleted(event: CustomEvent): Promise<void> {
        const signatureData = event.detail;

        try {
            await this.serviceCallQuotationService.completeAndAcceptQuotation(this.quotation.Id, signatureData, this.contactName, this.contactEmail);
        } catch (error) {
            // Si une erreur se produit avec la génération du formulaire, la soumission est quand même changée d'état. On affiche un message pour avertir de contacter le bureau pour compléter la génération.
            const validationException = this.errorManager.getApiValidationExceptionFromError(error);
            if (validationException && validationException.ErrorId === ValidationError.ServiceCallQuotationFormGenerationError) {
                this.notificationHelper.showError(this.i18n.tr(`ApiError.${ValidationError.ServiceCallQuotationFormGenerationError}`), undefined, { timeOut: 0, extendedTimeOut: 0 });
            } else {
                throw error;
            }
        }

        this.routerHelper.navigateBack();
    }
    // #endregion

    // #region Lookup
    public async lookupContact(filter: string, pagingInfo: PagingInfo): Promise<ContactLookupModel[]> {
        const customerId: string = this.quotation.CustomerId as string;
        return await this.customerContactService.getClientContactsLookup(customerId, filter, pagingInfo);
    }
    // #endregion

    // #region Privates
    private async initQuotation(quotationId: number): Promise<void> {
        this.quotation = await this.serviceCallQuotationService.getQuotation(quotationId, true, true, false, false, true);
    }

    private async initSecuritySettings(): Promise<void> {
        this.securitySettings = await this.serviceCallQuotationSecurityService.getSecuritySettings();
    }

    private async initDefaultContact(): Promise<void> {

        this.contactEmail = this.quotation.ContactEmail || "";
        this.contactName = this.quotation.ContactName || "";

        if (this.quotation.ContactId <= 0) {
            return;
        }

        const contact = await this.contactService.getContactLookup(this.quotation.ContactId);

        if (contact === null) {
            return;
        }

        this.contactId = contact.Id + "";
        this.contactDescription = contact.FullName || "";
        this.contactEmail = contact.Email || "";
        this.contactName = this.truncateContactName(contact);
    }

    private async initContract(): Promise<void> {
        this.contract = await this.serviceCallContractService.GetContract(this.quotation.ContractId);
    }

    private initValidation(): void {
        this.validationController.validateTrigger = validateTrigger.manual;

        ValidationRules
            .ensure((x: QuotationSummary) => x.contactName).required().withMessageKey("err_NameRequired")
            .ensure((x: QuotationSummary) => x.contactEmail).required().withMessageKey("err_EmailRequired")
            .ensure((x: QuotationSummary) => x.contactEmail).email().withMessageKey("err_InvalidEmail")
            .on(this);
    }

    private truncateContactName(contact: ContactLookupModel | null): string {
        if (!contact) {
            return "";
        }

        const maxlengthContactName = this.validationAttributeValueConverter.toView("maxLength", "service.serviceCall.quotation.contact.name");
        let contactName = contact.FullName || "";
        if (contactName && contactName.length > maxlengthContactName) {
            contactName = contactName.substring(0, maxlengthContactName);
        }

        return contactName;
    }
    // #endregion
}
