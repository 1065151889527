import { ProjectModel } from "api/models/company/project/project-model";
import { DispatchProjectProxy } from "api/proxies/dispatch-project-proxy";
import { autoinject } from "aurelia-framework";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";
import { LocationModel } from "api/models/company/location-model";
import { PagingInfo } from "api/paging-info";

@autoinject()
export class DispatchProjectService {
    constructor(private readonly dispatchProjectProxy: DispatchProjectProxy) {}

    public async GetProjectFromDispatchProjectCode(dispatchId: string, requestConfig?: HttpClientRequestConfig): Promise<ProjectModel | any> {
        return await this.dispatchProjectProxy.GetProjectFromDispatchProjectCode(dispatchId, requestConfig);
    }

    public async getProjectDeliveryAddress(dispatchProjectCode: string | null, addressType: string | null, supplierId: string | null, prefix: string | null, dispatchId: number | null | undefined, workOrderId: string | null | undefined, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<LocationModel[] | null> {
        return await this.dispatchProjectProxy.GetProjectDeliveryAddress(dispatchProjectCode, addressType, supplierId, prefix, dispatchId, workOrderId, filter, pagingInfo, requestConfig);
    }

}
