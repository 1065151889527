import { ScrollHelper } from "helpers/scroll-helper";
import { autoinject } from "aurelia-framework";

@autoinject
export class CollapseAll {
  public wrapper: any = null;

  constructor(private readonly scrollHelper: ScrollHelper) { }

  public bind(): void {
    this.scrollHelper.fixTop(this.wrapper);
  }

  public collapseAll(): void {
    this.collapseLogic(true,  "drawer > div.open");
  }

  public expandAll(): void {
    this.collapseLogic(false, "drawer > div:not(.open)");
  }

  private collapseLogic(collapse: boolean, selector: string): void {
    const drawers = document.querySelectorAll(selector);

    for (let i = 0; !!drawers[i]; i++) {
        const drawer = drawers[i];

        if (collapse) {
          drawer.classList.remove("open");
        } else {
          drawer.classList.add("open");
        }
    }
  }
}
