import { LocationModel } from "api/models/company/location-model";
import _ from "underscore";
import { I18N } from "aurelia-i18n";
import { autoinject, observable } from "aurelia-framework";

import { servicePresence } from "helpers/enumHelper";

import dateHelper from "helpers/dateHelper";
import labelHelper from "helpers/labelHelper";

import { EmployeeHoursSummaryHeaderModel } from "api/models/company/employee-hours-summary-header-model";
import { EmployeeHoursSummaryItemModel } from "api/models/company/employee-hours-summary-item-model";
import { MaCardLineModel, MaCardOptionsModel, MaCardRightInfoModel, MaCardSplitDisplayModel } from "components/layout/ma-card/ma-card-options.model";

import employeeService from "services/employeeService";
@autoinject
export class HoursWorked {
    public dateHelper: typeof dateHelper;

    @observable public selectedPeriod: any = null;
    public dispatchList: EmployeeHoursSummaryHeaderModel[] = [];
    public total: string = "";
    public regularTotal: string = "";
    public overTotal: string = "";
    public doubleTotal: string = "";

    public showDetails: boolean = false;

    private counter: number = 0;
    private language: string = "";

    private readonly zeroTotalTime: string = "00:00";
    private readonly emptyTime: string = "00:00:00";
    private readonly splitDisplayClass: string = "pr-1";

    constructor(private readonly i18n: I18N) {
        this.dateHelper = dateHelper;
        this.language = localStorage.getItem("maestro_user_profile_LANGUAGE") ? localStorage.getItem("maestro_user_profile_LANGUAGE")! : "";
    }

    public activate(): any {
        this.loadData(null);
    }

    public selectedPeriodChanged(selectedPeriod: any): void {
        if (selectedPeriod) {
            this.loadData(selectedPeriod.item);
        }
    }

    public createCard(data: EmployeeHoursSummaryItemModel, showDetails: boolean = false): MaCardOptionsModel {

        const card = new MaCardOptionsModel({
            model: data,
            cardBodyClass: "py-2",
            id: "card_" + this.counter++,
            isSelectable: false,
            isSelected: false,
            displayLines: [
                new MaCardLineModel({  id: `${data.DispatchId}_title_line`, display: this.getDescriptionDisplay(data), class: "bold" })
            ],
            rightInfos: [ new MaCardRightInfoModel({ display: dateHelper.formatTimeSpan(data.Hours) })]
        });

        if (data.ProjectId) {
            card.displayLines.push(new MaCardLineModel({
                splitDisplays: [
                    new MaCardSplitDisplayModel({
                        class: "bold",
                        display: `${this.i18n.tr("Project:")}`
                    }),
                    new MaCardSplitDisplayModel({
                        display: `${data.ProjectId} - ${data.ProjectDescription}`,
                    })
                ]
            }));
        }

        if ((data.IsServiceCall || data.IsWorkOrder) && data.CustomerName) {

            card.displayLines.push(new MaCardLineModel({
                id: `${data.DispatchId}_customer_line`,
                splitDisplays: [
                    new MaCardSplitDisplayModel({
                        class: "bold",
                        display: `${this.i18n.tr("Customer:")}`
                    }),
                    new MaCardSplitDisplayModel({
                        display: data.CustomerName,
                    }),
                ]
            }));
        }

        if (!data.IsTimesheet && data.Location) {
            card.displayLines.push(new MaCardLineModel({
                id: `${data.DispatchId}_location_line`,
                splitDisplays: this.getLocationSplitDisplays(data.Location)
            }));
        }

        if (showDetails) {
            card.displayLines.push(new MaCardLineModel({
                id: `${data.DispatchId}_detail_line`,
                class: "worked-hours-line",
                splitDisplays: this.getDetailSplitDisplays(data)
            }));
        }

        return card;
    }

    private getLocationSplitDisplays(location: LocationModel): MaCardSplitDisplayModel[] {
        const splitDisplays: MaCardSplitDisplayModel[] = [];

        if (location.Name || location.Address || location.PostalCode) {
            splitDisplays.push(new MaCardSplitDisplayModel({
                class: "bold",
                display: `${this.i18n.tr("Location:")}`
            }));
        }

        if (location.Name) {
            splitDisplays.push(new MaCardSplitDisplayModel({
                display: location.Name,
            }));
        }
        if (location.Address) {
            splitDisplays.push(new MaCardSplitDisplayModel({
                display: `${location.Address}, ${location.City} ${location.Province ? "(" + location.Province + ")" : ""}`,
            }));
        }
        if (location.PostalCode) {
            splitDisplays.push(new MaCardSplitDisplayModel({
                display: location.PostalCode,
            }));
        }
        return splitDisplays;
    }

    private getDetailSplitDisplays(data: EmployeeHoursSummaryItemModel): MaCardSplitDisplayModel[] {
        const detailLineDisplays: MaCardSplitDisplayModel[] = [];
        if (data.ActivityDescription) {
            detailLineDisplays.push(new MaCardSplitDisplayModel({
                class: `bold ${this.splitDisplayClass}`,
                display: `${this.i18n.tr("Activity:")}`,
            }));
            detailLineDisplays.push(new MaCardSplitDisplayModel({
                class: `${this.splitDisplayClass}`,
                display: `${data.ActivityId} - ${data.ActivityDescription}`,
            }));
        }

        detailLineDisplays.push(new MaCardSplitDisplayModel({
            class: `bold ${this.splitDisplayClass}`,
            display: `${this.i18n.tr("Rate:")}`,
        }));
        detailLineDisplays.push(this.getRateSplitDisplays(data));

        if (data.ShiftDescription) {
            detailLineDisplays.push(new MaCardSplitDisplayModel({
                class: `bold ${this.splitDisplayClass}`,
                display: `${this.i18n.tr("Shift:")}`,
            }));
            detailLineDisplays.push(new MaCardSplitDisplayModel({
                class: this.splitDisplayClass,
                display: `${data.ShiftId}${data.ShiftDescription ? " - " + data.ShiftDescription : ""}`,
            }));
        }

        if (data.TradeId) {
            detailLineDisplays.push(new MaCardSplitDisplayModel({
                class: `bold ${this.splitDisplayClass}`,
                display: `${this.i18n.tr("Trade:")}`,
            }));
            detailLineDisplays.push(new MaCardSplitDisplayModel({
                display: `${data.TradeId}${data.TradeDescription ? " - " + data.TradeDescription : ""}`,
            }));
        }
        return detailLineDisplays;
    }

    private getRateSplitDisplays(data: EmployeeHoursSummaryItemModel): MaCardSplitDisplayModel {
        let rate = "";
        if (data.RegularHours !== this.emptyTime) {
            rate = this.i18n.tr("regular");
        } else if (data.OvertimeHours !== this.emptyTime) {
            rate = this.i18n.tr("Over");
        } else if (data.DoubleHours !== this.emptyTime) {
            rate = this.i18n.tr("Double");
        }

        return new MaCardSplitDisplayModel({
                icon: "fa fa-clock-o",
                class: this.splitDisplayClass,
                display: `${this.i18n.tr("Time")} ${rate.toLowerCase()}`,
            });
    }

    private getDescriptionDisplay(data: EmployeeHoursSummaryItemModel): string {
        if (data.IsProject) {
            return `${this.i18n.tr("DailyEntry")}`;
        }

        if (data.IsServiceCall || data.IsWorkOrder) {
            return (data.IsServiceCall ? `${labelHelper.getCallTypeLabel(data.CallType!, this.language)} ${data.ServiceCallId}` : `${this.i18n.tr("WorkOrder")} ${data.WorkOrderId}`);
        }

        if (data.IsTimesheet) {
            return `${this.i18n.tr("Timesheet")}`;
        }

        if (data.IsProjectHours) {
            return `${this.i18n.tr("Project_Detail_Hours")}`;

        }

        return "";
    }

    private calculateTotalTime(data: any): void {
        const tTime = _.reduce(data, (memo: any, item: any) => {
            return +memo + dateHelper.timeSpanToSeconds(item.TotalHours);
        }, 0);
        if (tTime > 0) {
            this.total = dateHelper.formatTimeSpan(tTime * 1000);
        } else {
            this.total = this.zeroTotalTime;
        }

        const tRegularTime = _.reduce(data, (memo: any, item: any) => {
            return +memo + dateHelper.timeSpanToSeconds(item.TotalRegularHours);
        }, 0);
        if (tRegularTime > 0) {
            this.regularTotal = dateHelper.formatTimeSpan(tRegularTime * 1000);
        } else {
            this.regularTotal = this.zeroTotalTime;
        }

        const tOverTime = _.reduce(data, (memo: any, item: any) => {
            return +memo + dateHelper.timeSpanToSeconds(item.TotalOvertimeHours);
        }, 0);
        if (tOverTime > 0) {
            this.overTotal = dateHelper.formatTimeSpan(tOverTime * 1000);
        } else {
            this.overTotal = this.zeroTotalTime;
        }

        const tDoubleTime = _.reduce(data, (memo: any, item: any) => {
            return +memo + dateHelper.timeSpanToSeconds(item.TotalDoubleHours);
        }, 0);
        if (tDoubleTime > 0) {
            this.doubleTotal = dateHelper.formatTimeSpan(tDoubleTime * 1000);
        } else {
            this.doubleTotal = this.zeroTotalTime;
        }
    }

    private async loadData(selectedPeriod: any): Promise<void> {
        if (selectedPeriod) {
            this.dispatchList = await employeeService.getHoursSummary(selectedPeriod.StartDate, selectedPeriod.EndDate);
            this.calculateTotalTime(this.dispatchList);
        }
    }
}
