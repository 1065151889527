define([
    "knockout",
    "core/resx",
    "helpers/dateHelper",
    "services/serviceService",
    //"plugins/router",
    "helpers/routerHelper",
    "helpers/labelHelper",
    "helpers/notificationHelper",
    "helpers/queryStringHelper"
], function (ko, resx, dateHelper, services, /*router, */routerHelper, labelHelper, notifier, queryStringHelper) {
    "use strict";

    var viewModel = (function() {
        var self = null;

        function ctor() {
            self = this;

            //#region Properties
            self.dateHelper = dateHelper;
            self.labelHelper = labelHelper;
            self.resx = resx;

            self.dispatchId = ko.observable();
            self.estimatedTime = ko.observable();
            self.list = ko.observable([]);
            self.readonly = false;
            self.serviceType = "";
            self.timesheetTotalTime = ko.observable();
            //#endregion
        }

        //#region Private Functions
        function bindViewModel(serviceType, dispatchId, querystring) {
            self.readonly = queryStringHelper.parseReadonly(querystring);

            self.serviceType = serviceType;
            self.dispatchId(dispatchId);
        }

        function loadData() {
            return services.getLabors(self.serviceType, self.dispatchId()).done(setDetail);
        }

        function setDetail(data) {
            self.list(data.Timesheets);
            self.timesheetTotalTime(dateHelper.hoursTohmm(data.TimesheetTotalTime));
            self.estimatedTime(dateHelper.hoursTohmm(data.EstimatedTime));
        }
        //#endregion

        //#region Public Functions
        ctor.prototype.activate = function (params) {
            bindViewModel(params.serviceType, params.dispatchId, params.q);
            return loadData();
        };

        ctor.prototype.delete = function(item) {
            notifier.showDeleteConfirmation().done(function(success) {
                if (success) {
                    routerHelper.showLoading();
                    services.deleteLabor(self.serviceType, self.dispatchId(), item.LineNo, item.Timestamp)
                        .done(setDetail)
                        .concurrence(loadData)
                        .always(routerHelper.hideLoading);
                }
            });
        };

        ctor.prototype.gotoPage = function (labor) {
            var url;
            var type = 'time';

            if (labor && labor.LineNo) {
                // Edit existing
                url = routerHelper.getRelativeUrl("edit", labor.LineNo);
                type = labor.IsSelf ? 'time' : 'labor';
            }
            else {
                // Create new 
                url = routerHelper.getRelativeUrl("edit");
            }
            routerHelper.navigate(url + routerHelper.addQuerystring({ readonly: self.readonly, type: type }));
        };

        ctor.prototype.addLabor = function () {
            var url = routerHelper.getRelativeUrl("edit");
            routerHelper.navigate(url + routerHelper.addQuerystring({ readonly: self.readonly, type: 'labor' }));
        };
        //#endregion

        return ctor;
    })();

    return viewModel;
});
