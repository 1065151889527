import { PLATFORM } from "aurelia-framework";
import { Redirect } from "aurelia-router";
import { ClientStatus } from "api/enums/client-status";
import { default as templateService } from "services/templateService";

define([
    "knockout",
    "underscore",
    "core/resx",
    "core/val",
    "moment",
    "services/projectService",
    "services/clientService",
    "services/commonService",
    "services/workOrderService",
    "services/defaultService",
    "services/templateService",
    "helpers/queryStringHelper",
    "helpers/labelHelper",
    "helpers/enumHelper",
    "helpers/settingHelper",
    "helpers/dateHelper",
    "helpers/notificationHelper",
    "helpers/routerHelper",
    "widgets/fieldServiceHeader"
], function (ko, _, resx, val, moment, projectService, clientService, commonService, workOrderService,
    defaultService, templateService, queryStringHelper, labelHelper, enumHelper, settingHelper, dateHelper, notificationHelper, routerHelper) {
    "use strict";

    var constEmptyItem = "[empty]";

    var viewModel = (function () {
        var self = null;

        function ctor() {
            self = this;
            //#region Properties
            self.resx = resx;
            self.val = val;

            self.dispatchModel = "";
            self.readonly = false;
            self.disableSave = ko.observable(false);
            self.customerReadonly = ko.observable(false);
            self.sourceWorkorder = ko.observable(null);
            self.routerHelper = routerHelper;
            self.toggleModal = ko.observable(false);
            self.templateResult = PLATFORM.moduleName("pages/templates/maSelectTemplates/fieldService_detail_edit_lastWorkOrders_result.html");
            self.dialogTemplate = PLATFORM.moduleName("pages/templates/dialogTemplates/startEndTimeTemplate");
            self.selectedChoice = ko.observable(enumHelper.copyDailyEntryOrWorkOrderChoices().RESSOURCESONLY.id);
            self.noHours = false;
            self.defaultWorkOrderType = null;

            self.validatedForm = ko.validatedObservable({
                comment: ko.observable(''),
                description: ko.observable(''),
                customer: ko.observable(),
                date: ko.observable().extend({ date: true }),
                id: ko.observable(0),
                originalWO: ko.observable(),
                po: ko.observable(''),
                progressState: ko.observable(),
                project: ko.observable(),
                reference: ko.observable(''),
                type: ko.observable(),
                startHour : ko.observable(),
                endHour : ko.observable(),

                clear: function() {
                    var selfForm = this;
                    selfForm.comment('');
                    selfForm.description('');
                    selfForm.customer(null);
                    selfForm.date(dateHelper.getNow());

                    if (self.editMode !== enumHelper.fieldServiceEditMode().SUB) {
                        selfForm.originalWO(null);
                    }

                    selfForm.id(0);
                    selfForm.po('');
                    selfForm.project(null);
                    selfForm.reference('');
                    selfForm.type(null);
                },

                init: function() {
                    var selfForm = this;

                    selfForm.date.extend({
                        required: {
                            params: true,
                            message: resx.localize('err_DateRequired')
                        }
                    });

                    selfForm.description.extend({
                        required: {
                            params: true,
                            message: resx.localize('err_DescriptionRequired')
                        }
                    });

                    selfForm.project.extend({
                        required: {
                            params: true,
                            message: resx.localize('err_ProjectRequired')
                        }
                    });
                },

                bind: function(data) {
                    var selfForm = this;

                    selfForm.comment(data.Comment);
                    selfForm.description(data.Description);

                    if (data.CustomerCode) {
                        selfForm.customer(
                            { id: data.CustomerCode, text: data.CustomerCode + ' - ' + data.CustomerName });
                    }

                    if (self.editMode === enumHelper.fieldServiceEditMode().EDIT) {
                        selfForm.date(moment(data.Date));
                    } else {
                        selfForm.date(moment());
                    }

                    if (self.editMode === enumHelper.fieldServiceEditMode().SUB) {
                        selfForm.originalWO({ id: data.Id, text: data.Id + ' - ' + data.Description });
                    } else if (data.OriginalWorkOrderId) {
                        selfForm.originalWO({
                            id: data.OriginalWorkOrderId,
                            text: data.OriginalWorkOrderId + ' - ' + data.OriginalWorkOrderDescription
                        });
                    }

                    selfForm.id(self.editMode === enumHelper.fieldServiceEditMode().EDIT ? data.Id : 0);
                    selfForm.po(data.PoNumber);

                    if (data.ProgressStatusId) {
                        selfForm.progressState({
                            id: data.ProgressStatusId,
                            text: data.ProgressStatusId + ' - ' + data.ProgressStatusDescription
                        });
                    }

                    if (data.ProjectCode) {
                        selfForm.project({
                            id: data.ProjectCode,
                            text: data.ProjectCode + ' - ' + data.ProjectDescription
                        });
                    }

                    selfForm.reference(data.Reference);

                    if (data.WorkOrderTypeId) {
                        selfForm.type({
                            id: data.WorkOrderTypeId,
                            text: data.WorkOrderTypeId + ' - ' + data.WorkOrderTypeDescription
                        });
                    }

                    if (data.StartTime && data.EndTime) {
                        var startDateTime = dateHelper.formatDate(null, data.StartTime);
                        var endDateTime = dateHelper.formatDate(null, data.EndTime);

                        selfForm.startHour(startDateTime);
                        selfForm.endHour(endDateTime);
                    } else {
                        self.noHours = true;
                        //initialiseStartEndHours();
                    }
                    
                }
            });

            //#region Ajax load for maSelect
            self.loadClients = {
                transport: function(params, success, failure) {
                    clientService.getClientsLookup(params.data.filter, params.data.page || 1)
                        .done(success)
                        .fail(failure);
                },
                mapResults: function(item) {
                    return { id: item.Id, text: item.Id + ' - ' + item.Description };
                }
            };

            self.loadSourceWorkorders = {
                transport: function(params, success, failure) {
                    workOrderService.listLast5Workorder()
                        .done(function(data) {
                            data.unshift({ Id: constEmptyItem, Description: resx.localize("Empty") });
                            success(data);
                        })
                        .fail(failure);
                },
                mapResults: function(item) {
                    if (item.Id === constEmptyItem) {
                        return { id: item.Id, text: item.Description, data: item };
                    }
                    return { id: item.Id, text: item.Id + ' - ' + item.Description, data: item };
                }
            };

            self.loadProjects = {
                transport: function(params, success, failure) {
                    projectService.listLookup(self.dispatchModel, params.data.filter, params.data.page || 1)
                        .done(success)
                        .fail(failure);
                },
                mapResults: function(item) {
                    return { id: item.Id, text: item.Id + ' - ' + item.Description, data: item };
                }
            };

            self.loadTypes = {
                transport: function(params, success, failure) {
                    workOrderService.listTypes(params.data.filter, params.data.page || 1)
                        .done(success)
                        .fail(failure);
                },
                mapResults: function(item) {
                    return { id: item.Id, text: item.Id + ' - ' + item.Description };
                }
            };

            self.loadWorkOrders = {
                transport: function(params, success, failure) {
                    workOrderService.listOriginalLookup(params.data.filter, params.data.page || 1)
                        .done(success)
                        .fail(failure);
                },
                mapResults: function(item) {
                    return { id: item.Id, text: item.Id + ' - ' + item.Description };
                }
            };

            self.loadProgressStates = {
                transport: function(params, success, failure) {
                    workOrderService.listProgressStateLookup()
                        .done(success)
                        .fail(failure);
                },
                mapResults: function(item) {
                    return { id: item.Id, text: item.Id + ' - ' + item.Description };
                }
            };
            //#endregion
            //#endregion
        }

        //#region Private Functions
        function bindQueryString(querystring) {
            self.readonly = queryStringHelper.parseReadonly(querystring);
            self.editMode = queryStringHelper.parse(querystring, 'editMode');
        }

        function setDisplayProperties() {
            self.customerReadonly(self.validatedForm().customer());
        }

        function clearViewModel() {
            self.validatedForm().clear();

            self.readonly = false;
            self.disableSave(false);
            self.customerReadonly(false);
            self.sourceWorkorder(null);
        }

        function bindViewModel(workOrderData) {
            self.validatedForm().bind(workOrderData);

            setDisplayProperties();
        }

        async function loadData(workOrderId) {
            if (self.editMode === enumHelper.fieldServiceEditMode().EDIT){
            return workOrderService.get(workOrderId)
                .done(function (workOrderData) {
                    bindViewModel(workOrderData);
                    self.validatedForm().init();
                });
            } else {
                self.validatedForm().init();
                await setDefaultWorkOrderType();
                return new jQuery.Deferred().resolve();
            }
        }

        function buildSetData() {
            return {
                Id: self.validatedForm().id(),
                Description: self.validatedForm().description(),
                Date: self.validatedForm().date(),
                Reference: self.validatedForm().reference(),
                OriginalWorkOrderId: self.validatedForm().originalWO() ? self.validatedForm().originalWO().id : '',
                ProjectCode: self.validatedForm().project() ? self.validatedForm().project().id : '',
                CustomerCode: self.validatedForm().customer() ? self.validatedForm().customer().id : '',
                PoNumber: self.validatedForm().po(),
                WorkOrderTypeId: self.validatedForm().type() ? self.validatedForm().type().id : self.defaultWorkOrderType,
                ProgressStatusId: self.validatedForm().progressState() ? self.validatedForm().progressState().id : '',
                Comment: self.validatedForm().comment(),
                StartTime: dateHelper.getTime(self.validatedForm().startHour()),
                EndTime: dateHelper.getTime(self.validatedForm().endHour())
            };
        }

        function goToId(id) {
            var url = routerHelper.navigateTo('FieldService_Detail') + "/" + id;
            routerHelper.navigate(url, { replace: true, trigger: true });
        }

        function createCopy(workorderId, copyAttendance, copyTimesheets, copyMaterials, startHour, endHour) {
            routerHelper.showLoading();

            workOrderService.createCopy(workorderId, copyAttendance, copyTimesheets, copyMaterials, startHour, endHour)
                .done(goToId)
                .always(routerHelper.hideLoading);
        }

        function askForAttendanceAndTimesheets(item) {
            
            notificationHelper.showOptionsDialog(
                resx.localize("msg_ChoicePrecisionToCopy"),
                "",
                {
                    selectedValue: self.selectedChoice,
                    optionsList: [
                        {
                            value: enumHelper.copyDailyEntryOrWorkOrderChoices().RESSOURCESONLY.id,
                            text: enumHelper.copyDailyEntryOrWorkOrderChoices().RESSOURCESONLY.label
                        },
                        {
                            value: enumHelper.copyDailyEntryOrWorkOrderChoices().RESSOURCESANDTIME.id,
                            text: enumHelper.copyDailyEntryOrWorkOrderChoices().RESSOURCESANDTIME.label
                        },
                        {
                            value: enumHelper.copyDailyEntryOrWorkOrderChoices().RESSOURCESANDTIMEANDMATERIALS.id,
                            text: enumHelper.copyDailyEntryOrWorkOrderChoices().RESSOURCESANDTIMEANDMATERIALS.label
                        }
                    ]
                }).done(function (choice) {
                    if (choice) {
                        self.selectedChoice(choice);
                        enterNewHours(item.id);
                    } else {
                        routerHelper.navigateBack();
                    }
                });

        }

        function showStartEndHoursPopup(workOrderId) {
            initialiseStartEndHours();
            notificationHelper.showTemplateDialog(
                "",
                "",
                self.dialogTemplate,
                {templateContext : {
                    startHour: self.validatedForm().startHour,
                    endHour: self.validatedForm().endHour
                }
                }
            ).done(function (success) {
                if (success) {
                    //create copy using selected Start/End hours from dialog
                    createCopy(workOrderId, true, self.selectedChoice() === enumHelper.copyDailyEntryOrWorkOrderChoices().RESSOURCESANDTIME.id || self.selectedChoice() === enumHelper.copyDailyEntryOrWorkOrderChoices().RESSOURCESANDTIMEANDMATERIALS.id, self.selectedChoice() === enumHelper.copyDailyEntryOrWorkOrderChoices().RESSOURCESANDTIMEANDMATERIALS.id, dateHelper.getTime(self.validatedForm().startHour()), dateHelper.getTime(self.validatedForm().endHour()));
                } else {
                    routerHelper.navigateBack();
                }
            });
        }

        function enterNewHours(workOrderId) {
            routerHelper.showLoading();
            workOrderService.isOldworkOrder(workOrderId).done(function (isOld) {
                if (isOld) {
                    showStartEndHoursPopup(workOrderId);
                } else {
                    //createCopyDirectly
                    createCopy(workOrderId, true, self.selectedChoice() === enumHelper.copyDailyEntryOrWorkOrderChoices().RESSOURCESANDTIME.id || self.selectedChoice() === enumHelper.copyDailyEntryOrWorkOrderChoices().RESSOURCESANDTIMEANDMATERIALS.id, self.selectedChoice() === enumHelper.copyDailyEntryOrWorkOrderChoices().RESSOURCESANDTIMEANDMATERIALS.id);
                }
            }).always(routerHelper.hideLoading);
        }

        function getStartEndHour(isStartHour) {
            var templateConfigs = templateService.getCurrentTemplateConfigs();
            var dateTime = new Date();
            if (isStartHour) {
                dateTime = dateHelper.addTimeSpan(dateTime, templateConfigs.WorkingStartHour);
            } else {
                dateTime = dateHelper.addTimeSpan(dateTime, templateConfigs.WorkingEndHour);
            }
            return dateTime;
        }

        function initialiseStartEndHours() {
            self.validatedForm().startHour(getStartEndHour(true));
            self.validatedForm().endHour(getStartEndHour(false));
        }

        function checkBlockedClient(client) {
            if (client.Status === ClientStatus.Blocked) {
                self.disableSave(true);
                var message = resx.localize("msg_BlockedCustomer").replace("{0}", client.Id).replace("{1}", client.Description).replace("{2}", client.StatusReason);
                notificationHelper.showWarning(message, "", { timeOut: 0 });
            } else if (client.Status === ClientStatus.Warning) {
                var warning = resx.localize("msg_BlockedCustomerWarning").replace("{0}", client.Id).replace("{1}", client.Description).replace("{2}", client.StatusReason);
                notificationHelper.showWarning(warning, "", { timeOut: 0 });
            }
        }

        async function setDefaultWorkOrderType() {
            var template = await templateService.getTemplateConfigs(self.dispatchModel);

            if (template.DefaultWorkOrderType) {
                var listWoTypes = await workOrderService.listTypes();

                if (listWoTypes.length > 0) {
                    var selectedWorkOrderType = listWoTypes.find((item) => item.Id === template.DefaultWorkOrderType);
                    if (selectedWorkOrderType) {
                        self.defaultWorkOrderType = selectedWorkOrderType.Id
                        self.validatedForm().type({
                            id: selectedWorkOrderType.Id,
                            text: selectedWorkOrderType.Id + ' - ' + selectedWorkOrderType.Description
                        });
                    }
                }
            }
        }

        //#endregion

        //#region Public Functions
        ctor.prototype.canActivate = function () {
            if (!settingHelper.hasDispatchModel()) {
                notificationHelper.showWarning(resx.localize("DispatchModelRequired"));
                return new Redirect("Settings");
            }

            return true;
        }

        ctor.prototype.activate = async function  (params) {
            clearViewModel();

            self.dispatchModel = settingHelper.getSelectedDispatchModel();

            bindQueryString(params.q);

            return await loadData(params.workOrderId);
        };

        ctor.prototype.attached = function () {
            self.toggleModal(!self.validatedForm().id());
        };

        ctor.prototype.clearDefaultValues = function () {
            self.validatedForm().clear();
        };

        ctor.prototype.updateCustomerForProject = async function (item) {
            self.disableSave(false);
            if (item) {
                var client = await clientService.getClientFromProject(item.data.Id);
                if (client) {

                    if (client) {
                        checkBlockedClient(client);
                    }

                    if (self.disableSave()) {
                        return;
                    }
                }
            }
            
            if (item && item.data.CustomerCode) {
                self.validatedForm().customer({ id: item.data.CustomerCode, text: item.data.CustomerCode + ' - ' + item.data.Customer });
                self.customerReadonly(true);
            } else {
                self.customerReadonly(false);
                self.validatedForm().customer(null);
            }
        };

       

        ctor.prototype.save = function () {
            if (self.validatedForm.isValid()) {
                routerHelper.showLoading();
                var data = buildSetData();

                workOrderService.set(self.dispatchModel, data)
                    .done(goToId)
                    .always(routerHelper.hideLoading);
            } else {
                notificationHelper.showValidationError(self.validatedForm.errors);
            }
        };

        ctor.prototype.createWorkorderFromCopy = function (item) {
            if (item && item.id) {
                if (item.id !== constEmptyItem) {
                    askForAttendanceAndTimesheets(item);
                } else {
                    //initialise start and end hours 
                    initialiseStartEndHours();
                }
            }
        };

        //#endregion

        return ctor;
    })();

    return viewModel;
});
