import { autoinject, observable } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { ProcoreGrid } from "./composition/grid/procore-grid";
import { SideBar } from "./composition/side-bar/side-bar";
import { ProcoreSynchronizer } from "./composition/synchronizer/procore-synchronizer";
import { ProcoreLoader } from "./composition/loader/procore-loader";
import { ProcoreFilters } from "./composition/top-bar/filters/procore-filters";
import { ProcoreGridItem } from "./composition/grid/models/procore-grid-item";
import { ProcoreGridGroup } from "./composition/grid/models/procore-grid-group";
import { ProcorePollingSystem } from "./composition/polling-system/procore-polling-system";
import { AutoReloadHelper } from "helpers/job-system/auto-reload-helper";

@autoinject
export class Synchronization {
  public isLoading: boolean = false;
  public attachedHook?: Promise<void>;

  @observable
  public initialLoadingFinished: boolean = false;

  public get isTools(): boolean {
    return false;
  }

  public get disableButtons(): boolean {
    return !this.sidebar.menuItems || this.sidebar.menuItems.length === 0;
  }

  public get disableIcons(): boolean {
    return !!this.sidebar.transactionsSelected;
  }

  public get detailButtonEnabled(): boolean {
    return !!this.sidebar.transactionsSelected || this.sidebar.budgetSelected || this.sidebar.budgetModificationSelected || this.sidebar.PrimeContractsSelected;
  }

  private showLoadingOverlay: boolean = false;

  constructor(public readonly filters: ProcoreFilters,
              public readonly grid: ProcoreGrid,
              public readonly sidebar: SideBar,

              private readonly i18n: I18N, // on en a besoin dans le front-end

              private readonly synchronizer: ProcoreSynchronizer,
              private readonly loader: ProcoreLoader,
              private readonly pollingSystem: ProcorePollingSystem,
              private readonly _: AutoReloadHelper) {

                this.sidebar.synchronization = this;
                this.sidebar.generateMenu();
                this.grid.synchronization = this;
  }

  public attached(): void {
    this.grid.setContentHeight();
  }

  public async refresh(): Promise<void> {
    if (this.sidebar.menuItems && this.sidebar.menuItems.length > 0) {
      this.grid.configurations.SetDefault();

      this.columnsSetup();

      await this.loader.load(this);

      this.pollingSystem.gridItems = this.grid.items;
    }
  }

  public async synchronize(): Promise<void> {
    await this.synchronizer.synchronize(this);
  }

  public toggleErrors(item: ProcoreGridItem, event: any): void {
    if (item.Errors.length > 1 || item.Warnings.length > 1) {
      item.ErrorsOpen = !item.ErrorsOpen;
    }
  }

  // <span if.bind="!item.DetailsOpen">${"Procore.ShowTransactions" | t}</span>
  // <span if.bind="item.DetailsOpen">${"Procore.HideTransactions" | t}</span>

  public detailsLabel(itemDetailsOpen: boolean): string {
    if (this.sidebar.budgetSelected || this.sidebar.budgetModificationSelected) {
      return !itemDetailsOpen ? this.i18n.tr("Procore.ShowBudget")
                               : this.i18n.tr("Procore.HideBudget");
    }

    if (this.sidebar.PrimeContractsSelected) {
                             return !itemDetailsOpen ? this.i18n.tr("Procore.ShowDetails")
                                                     : this.i18n.tr("Procore.HideDetails");
    }

    return !itemDetailsOpen ? this.i18n.tr("Procore.ShowTransactions")
                            : this.i18n.tr("Procore.HideTransactions");
  }

  public async toggleDetails(item: ProcoreGridGroup, event: any): Promise<void> {
    if (!!item.DetailsIsLoading) { return; }

    !item.DetailsOpen
      ? this.openDetails(item, event.target)
      : this.closeDetails(item, event.target);
  }

  private async openDetails(item: ProcoreGridGroup, element: HTMLElement): Promise<void> {
    item.DetailsOpen = true;

    await this.loader.loadDetails(this, item);
  }

  private async closeDetails(item: ProcoreGridGroup, element: HTMLElement): Promise<void> {
    item.DetailsOpen = false;
    item.Details = [];
  }

  private initialLoadingFinishedChanged(): void {
    this.refresh();
  }

  private columnsSetup(): void {
    this.grid.ApplyConfigurator(this.sidebar.selectedItemId);
  }
}
