import { autoinject } from "aurelia-framework";
import { PurchaseOrderModel } from "api/models/company/purchase-order-model";
import { PagingInfo } from "api/paging-info";
import { PurchaseOrderProxy } from "api/proxies/purchase-order-proxy";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";
import { PurchaseOrderItemModel } from "api/models/company/purchase-order-item-model";
import { DocumentSectionModel } from "api/models/company/document/document-section-model";
import { ListDocumentModel } from "api/models/company/document/list-document-model";
import { DocumentModel } from "api/models/company/document/document-model";

@autoinject()
export class PurchaseOrderService {
    constructor(private readonly purchaseOrderProxy: PurchaseOrderProxy) {}

    public async GetPurchaseOrderByDispatchId(dispatchId: number, getClosedOrders: boolean = false, getOnlyFullyReceived: boolean = false, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<PurchaseOrderModel[] | null> {
        return this.purchaseOrderProxy.GetPurchaseOrderByDispatchId(dispatchId, getOnlyFullyReceived, filter, pagingInfo, requestConfig);
    }

    public async GetProjectPurchaseOrders(projectCode: string | null, getClosedOrders: boolean = false, getOnlyFullyReceived: boolean = false, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<PurchaseOrderModel[] | null> {
        return this.purchaseOrderProxy.GetProjectPurchaseOrders(projectCode, getClosedOrders, getOnlyFullyReceived, filter, pagingInfo, requestConfig);
    }

    public async GetWorkOrderPurchaseOrders(workOrderId: string | null, getClosedOrders: boolean = false, getOnlyFullyReceived: boolean = false, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<PurchaseOrderModel[] | null> {
        return this.purchaseOrderProxy.GetWorkOrderPurchaseOrders(workOrderId, getOnlyFullyReceived, filter, pagingInfo, requestConfig);
    }

    public async GetPurchaseOrder(purchaseOrderId: string | null, extension: string | null | undefined, requestConfig?: HttpClientRequestConfig): Promise<PurchaseOrderModel | null> {
        return this.purchaseOrderProxy.GetPurchaseOrder(purchaseOrderId, extension, requestConfig);
    }

    public async SavePurchaseOrderHeaderForDispatch(dispatchId: number, purchaseOrderHeader: PurchaseOrderModel | null, requestConfig?: HttpClientRequestConfig): Promise<PurchaseOrderModel | null> {
        return this.purchaseOrderProxy.SavePurchaseOrderHeaderForDispatch(dispatchId, purchaseOrderHeader, requestConfig);
    }

    public async SaveProjectPurchaseOrderHeader(projectCode: string | null, purchaseOrderHeader: PurchaseOrderModel | null, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<PurchaseOrderModel[] | null> {
        return this.purchaseOrderProxy.SaveProjectPurchaseOrderHeader(projectCode, purchaseOrderHeader, filter, pagingInfo, requestConfig);
    }

    public async DeletePurchaseOrder(purchaseOrderId: string | null, extension: string | null | undefined, requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        return this.purchaseOrderProxy.DeletePurchaseOrder(purchaseOrderId, extension, requestConfig);
    }

    public async DeletePurchaseOrderLine(purchaseOrderId: string | null, lineId: number, extension: string | null | undefined, requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        return this.purchaseOrderProxy.DeletePurchaseOrderLine(purchaseOrderId, lineId, extension, requestConfig);
    }

    public async SavePurchaseOrderDetailForDispatch(dispatchId: number, purchaseOrderId: string | null, lineId: number, items: Array<PurchaseOrderItemModel | null> | null, requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        return this.purchaseOrderProxy.SavePurchaseOrderDetailForDispatch(dispatchId, purchaseOrderId, lineId, items, requestConfig);
    }

    public async SavePurchaseOrderDetailForProject(purchaseOrderId: string | null, lineId: number, items: Array<PurchaseOrderItemModel | null> | null, requestConfig?: HttpClientRequestConfig): Promise<void> {
        return this.purchaseOrderProxy.SavePurchaseOrderDetailForProject(purchaseOrderId, lineId, items, requestConfig);
    }

    public async SavePurchaseOrderHeaderForWorkOrder(workOrderId: string | null, purchaseOrderHeader: PurchaseOrderModel | null, requestConfig?: HttpClientRequestConfig): Promise<PurchaseOrderModel | null> {
        return this.purchaseOrderProxy.SavePurchaseOrderHeaderForWorkOrder(workOrderId, purchaseOrderHeader, requestConfig);
    }

    public async SavePurchaseOrderDetailForWorkOrder(workOrderId: string | null, purchaseOrderId: string | null, lineId: number, items: Array<PurchaseOrderItemModel | null> | null, requestConfig?: HttpClientRequestConfig): Promise<void> {
        return this.purchaseOrderProxy.SavePurchaseOrderDetailForWorkOrder(workOrderId, purchaseOrderId, lineId, items, requestConfig);
    }

    public async GetSections(purchaseOrderId: string | null, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<DocumentSectionModel[] | null> {
        return this.purchaseOrderProxy.GetSections(purchaseOrderId, filter, pagingInfo, requestConfig);
    }

    public async GetDocuments(purchaseOrderId: string | null, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ListDocumentModel[] | null> {
        return this.purchaseOrderProxy.GetDocuments(purchaseOrderId, filter, pagingInfo, requestConfig);
    }

    public async AddDocuments(purchaseOrderId: string | null, model: Array<DocumentModel | null> | null, dispatchTemplateId: string | null, requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        return this.purchaseOrderProxy.AddDocuments(purchaseOrderId, model, dispatchTemplateId, requestConfig);
    }

    public async AllowedSendTransfer(requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        return this.purchaseOrderProxy.AllowedSendTransfer(requestConfig);

    }

    public async Send(purchaseOrderId: string | null, email: string | null, extension: string | null | undefined, requestConfig?: HttpClientRequestConfig): Promise<void> {
        extension = extension ? extension : null;
        return this.purchaseOrderProxy.Send(purchaseOrderId, email, extension, requestConfig);

    }

    public async Transfer(purchaseOrderId: string | null, extension: string | null | undefined, requestConfig?: HttpClientRequestConfig): Promise<void> {
        extension = extension ? extension : null;
        return this.purchaseOrderProxy.Transfer(purchaseOrderId, extension, requestConfig);
    }

    public async ChangeSupplier(purchaseOrderId: string | null, supplierCode: string | null, extension: string | null | undefined, requestConfig?: HttpClientRequestConfig): Promise<PurchaseOrderModel | null> {
        extension = extension ? extension : null;
        return this.purchaseOrderProxy.ChangeSupplier(purchaseOrderId, supplierCode, extension, requestConfig);
    }
}
