import { JobModelStatus } from "api/enums/job-model-status";
import { ProcoreDirectCostTransactionType } from "api/enums/procore-direct-cost-transaction-type";
import { SynchronizationStatus } from "api/enums/synchronization-status";
import { ProcoreError } from "api/models/procore/errors_-logger/procore-error";
import { autoinject } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { bindable } from "aurelia-typed-observable-plugin";
import { CurrencyFormatValueConverter } from "converters/format/number-format";
import { JobStatusHelper } from "helpers/job-system/job-status-helper";
import { Synchronization } from "../../synchronization";
import { ProcoreMenuItemId } from "../side-bar/enums/menu-item-id";
import { BaseColumnsConfigurator } from "./configurations/columns configurators/base-columns-configurator";
import { DeleteDirectCostColumnsConfigurator } from "./configurations/columns configurators/delete-direct-cost-columns-configurator";
import { BudgetColumnsConfigurator } from "./configurations/columns configurators/budget-columns-configurator";
import { EmployeesColumnsConfigurator } from "./configurations/columns configurators/employees-columns-configurator";
import { TransactionsColumnsConfigurator } from "./configurations/columns configurators/transactions-columns-configurator";
import { PrimeContractsColumnsConfigurator } from "./configurations/columns configurators/primecontracts-columns-configurator";
// Generate import
import { ProcoreGridConfigurations } from "./configurations/procore-grid-configurations";
import { ProcoreGridGroup } from "./models/procore-grid-group";
import { ProcoreGridItem } from "./models/procore-grid-item";
import { ProcoreTransactionType } from "api/enums/procore-transaction-type";

@autoinject
export class ProcoreGrid {
  @bindable
  public items: ProcoreGridItem[] | null = [];

  public synchronization?: Synchronization;

  public orderinvoice: any = ProcoreDirectCostTransactionType.OrderInvoice;

  public directcost: any = ProcoreTransactionType.DirectCosts;

  constructor(public  readonly configurations: ProcoreGridConfigurations,
              public  readonly jobStatus: JobStatusHelper,
              private readonly i18n: I18N,
              private readonly currencyFormatValueConverter: CurrencyFormatValueConverter,
              private readonly deleteDirectCostColumnsConfigurator: DeleteDirectCostColumnsConfigurator,
              private readonly transactionsColumnsConfigurator: TransactionsColumnsConfigurator,
              private readonly employeesColumnsConfigurator: EmployeesColumnsConfigurator,
              private readonly primecontractsColumnsConfigurator: PrimeContractsColumnsConfigurator,
              // Generate injection
              private readonly budgetColumnsConfigurator: BudgetColumnsConfigurator) {
    window.addEventListener("resize", this.setContentHeight);
  }

  public setContentHeight(): void {
    const gridContent = document.querySelector("procore-grid-content") as HTMLElement;

    if (gridContent) {
      const padding = 150;

      gridContent.style.maxHeight = `${window.innerHeight - padding}px`;
    }
  }

  public toggleSelection(item: ProcoreGridItem): void {
    if (!item.Selected) {
      this.selectItem(item);
    } else {
      this.selectItem(item, false);
    }
  }

  public selectItem(item: ProcoreGridItem, selected: boolean = true): void {
    item.Selected = selected;

    if (item.Children) {
      item.Children.forEach((child: ProcoreGridItem) => {
        this.selectItem(child, selected); // recursive
      });
    }
  }

  public setMessage(item: ProcoreGridItem, message: string | null): void {
    if (!this.items || this.items.length === 0 || !message) { return; }

    document.querySelector(`#row-${this.findIndex(item)} procore-grid-content-message`)!.innerHTML = message;
  }

  public displayErrors(event: any, item: ProcoreGridItem): void {
    if (!item.Errors) { return; }

    const lessThanOneError = item.Errors.length <= 1;

    if (lessThanOneError ||
       !event ||
       !event.target) { return; }

    const row = event.target.closest("procore-grid-content-row");

    const errorsWrapper = row.querySelector("procore-grid-content-errors");

    if (errorsWrapper) {
      errorsWrapper.toggleAttribute("hidden");
    }
  }

  public displayWarnings(event: any, item: ProcoreGridItem): void {
    if (!item.Warnings) { return; }

    const oneOrlessWarning = item.Warnings.length <= 1;

    if (oneOrlessWarning ||
       !event ||
       !event.target) { return; }

    const row = event.target.closest("procore-grid-content-row");

    const warningWrapper = row.querySelector("procore-grid-content-warnings");

    if (warningWrapper) {
      warningWrapper.toggleAttribute("hidden");
    }
  }

  public getMessage(item: ProcoreGridItem): string {
    if (!item.Errors) { return ""; }

    const errors = item.Errors.filter((error: string) => error != null);

    if (this.HasError(errors)) {
      const message = this.i18n.tr(`Procore.nbErrors`);

      message.replace("{0}", errors.length.toString());

      return message;
    }

    return "";
  }

  public showMore(event: any, item: ProcoreGridGroup): void {
    if (!event || !event.target) { return; }

    const row = event.target.closest("procore-grid-content-row");

    const childrenWrapper = row.querySelector("procore-grid-content-children");

    item.Expanded = !item.Expanded;

    if (childrenWrapper) {
      childrenWrapper.toggleAttribute("hidden");
    }
  }

  public GetGroupNameWithCount(item: ProcoreGridGroup): string {
    const count = this.NestedChildrenCount(item);

    return `${count} ${item.GroupName}`;
  }

  public GetDirectCostGroupingName(item: ProcoreGridGroup): string {
    switch (item.DirectCostType) {
      case ProcoreDirectCostTransactionType.Purchases:
        return "purchases";
      case ProcoreDirectCostTransactionType.HoursAndLabor:
        return "hours-and-labor";
      case ProcoreDirectCostTransactionType.ProjectToProjectTransfers:
        return "transferts";
      case ProcoreDirectCostTransactionType.OrderInvoice:
        return "order-invoices";
      default:
        return "purchases";
    }

    return "";
  }

  public ApplyConfigurator(selectedItemId: ProcoreMenuItemId | null): void {
    let columnsConfigurator: BaseColumnsConfigurator | undefined;

    switch (selectedItemId) {
      case ProcoreMenuItemId.Transactions:
        columnsConfigurator = this.transactionsColumnsConfigurator;
        break;
      case ProcoreMenuItemId.Employees:
        columnsConfigurator = this.employeesColumnsConfigurator;
        break;
      case ProcoreMenuItemId.DeleteDirectCosts:
        columnsConfigurator = this.deleteDirectCostColumnsConfigurator;
        break;
      case ProcoreMenuItemId.Budget:
        columnsConfigurator = this.budgetColumnsConfigurator;
        break;
        case ProcoreMenuItemId.BudgetModifications:
          columnsConfigurator = this.budgetColumnsConfigurator;
          break;
        case ProcoreMenuItemId.PrimeContracts:
          columnsConfigurator = this.primecontractsColumnsConfigurator;
          break;
        // Generate case
    }

    if (!columnsConfigurator) {
      this.configurations.WarningFooter = undefined;
      this.configurations.ShowCheckboxOnSuccess = false;
      return;
    }

    columnsConfigurator.enableColumns();
    columnsConfigurator.disableColumns();
    columnsConfigurator.changeHeaders();
    columnsConfigurator.setCommonProperties();
  }

  public displayCheckbox(item: ProcoreGridItem): boolean {
    const showCheckboxOnSuccess = (this.configurations.ShowCheckboxOnSuccess ||
                                 !this.success(item));

    const transactionsSelected = (!this.synchronization ||
                                this.synchronization.sidebar.transactionsSelected &&
                                !item.SynchronizationTried);

    return showCheckboxOnSuccess &&
           !this.working(item) &&
           !this.running(item) &&
           !this.delay(item) ||
           transactionsSelected;
  }

  public error(item: ProcoreGridItem): boolean {
    return this.checkStatuses(item,
                              [JobModelStatus.Error,
                               JobModelStatus.CriticalError]) ||
           this.hasErrors(item);
  }

  public success(item: ProcoreGridItem): boolean {
    return !item.IsSynchronizing &&
           ((this.isSynchronized(item) ||
           this.checkStatuses(item, [JobModelStatus.Success]) &&
           !this.hasErrors(item)));
  }

  public working(item: ProcoreGridItem): boolean {
    return this.checkStatuses(item,
                               [JobModelStatus.Waiting]);
  }

  public running(item: ProcoreGridItem): boolean {
    return this.checkStatuses(item,
                               [JobModelStatus.Running]);
  }

  public delay(item: ProcoreGridItem): boolean {
    return item.IsSynchronizing &&
            this.checkStatuses(item,
                               [JobModelStatus.Delayed]);
  }

  private isSynchronized(item: ProcoreGridItem): boolean {
    return item.Status === SynchronizationStatus.Synchronized &&
           item.Errors.length === 0;
  }

  private checkStatuses(item: ProcoreGridItem, statuses: JobModelStatus[]): boolean {
    return item.JobStatus !== null && item.JobStatus !== undefined &&
           statuses.includes(item.JobStatus);
  }

  private hasErrors(item: ProcoreGridItem): boolean {
    return item.Errors.length > 0;
  }

  private FormatCurrency(amount: any): string {
      return this.currencyFormatValueConverter.toView(amount);
  }

  private NestedChildrenCount(item: ProcoreGridItem): number {
    const HasChildren = item.Children && item.Children.length > 0;

    if (HasChildren) {
      return item.Children.reduce((count: number, child: ProcoreGridItem) => count + this.NestedChildrenCount(child), 0);
    }

    return 1;
  }

  private HasError(errors: string[]): boolean {
    return errors && errors.length > 0;
  }

  private HasChildren(children: ProcoreError[]): boolean {
    return children && children.length > 0;
  }

  private findIndex(item: ProcoreGridItem): number | null {
    if (!this.items) { return null; }

    return this.items.findIndex((x: ProcoreGridItem): boolean => x.Code === item.Code &&
      x.Description === item.Description);
  }
}
