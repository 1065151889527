import { ProcoreDirectCostTransactionType } from "api/enums/procore-direct-cost-transaction-type";
import { ProcoreTransactionType } from "api/enums/procore-transaction-type";
import { SynchronizationTarget } from "api/enums/synchronization-target";
import { ProcoreError } from "api/models/procore/errors_-logger/procore-error";
import { ProcoreTransactionGroup } from "api/models/procore/loaders/procore-transaction-group";
import { ProcoreTransactionsForAProject } from "api/models/procore/loaders/procore-transactions-for-a-project";
import { autoinject } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { ProcoreExceptionHelper } from "pages/procore/synchronization/helpers/procore-exception-helper";
import { CommandContractTransactionTransformer } from "services/procore/helpers/command-contract-transaction-transformer";
import { DirectCostTransformer } from "services/procore/helpers/direct-cost-transformer";
import { ProcoreGridItemTransformer } from "services/procore/helpers/procore-grid-item-transformer";
import { ProcoreGridGroup } from "../../grid/models/procore-grid-group";
import { ProcoreGridItem } from "../../grid/models/procore-grid-item";
import { TransactionsLoader } from "../../loader/details loaders/transactions-loader";

@autoinject
export class ProcorePollingTransactions {
  constructor(private i18n: I18N,
              private transactionsLoader: TransactionsLoader,
              private procoreGridItemTransformer: ProcoreGridItemTransformer,
              private commandContractTransactionTransformer: CommandContractTransactionTransformer,
              private directCostTransformer: DirectCostTransformer,
              private exceptionHelper: ProcoreExceptionHelper) {
              }

    public transformTransactions(item: ProcoreGridItem, result: any): ProcoreGridItem[] {
        try {
          item.Errors = [];
          item.Warnings = [];
    
          item.Message = "";
          let transactions = this.transformToGroups(result);
    
          const transactionCount = transactions.reduce((prev: number, curr: ProcoreGridGroup) => prev + (curr.Children ? curr.Children.length : 0), 0);
    
          if (transactionCount <= 0) {
            transactions = [];
          }
    
          const warning = ((!result || !result.Errors || result.Errors!.length <= 0) && (transactionCount <= 0))
            ? [{
              MessageFr: this.i18n.tr("Procore.NoTransactions"),
              MessageEn: this.i18n.tr("Procore.NoTransactions")
            } as ProcoreError]
          : [];
    
          const language = localStorage.getItem("maestro_user_profile_LANGUAGE");
    
          const errors = this.procoreGridItemTransformer.transformErrors(!result || !result.Errors ? [] : result.Errors, language);
          const warnings = this.procoreGridItemTransformer.transformErrors(warning, language);
    
          item.Message = this.procoreGridItemTransformer.getMessage(errors, []);
          item.Message += this.procoreGridItemTransformer.getMessage([], warnings);
    
          item.Errors = errors;
          item.Warnings = warnings;
    
          return transactions;
        } catch (e) {
          let message = "";
          if (e && e.response && e.response.data && e.response.data.Exception && e.response.data.Exception.ErrorId) {
            message = this.exceptionHelper.getMessageFromProcore(e.response.data.Exception, item.EntityToSynchronize);
          } else {
            message = e.response.data.Exception.Message;
          }
        
          const error = {
            MessageFr: message,
            MessageEn: message
          } as ProcoreError;

          const language = localStorage.getItem("maestro_user_profile_LANGUAGE");
    
          const errors = this.procoreGridItemTransformer.transformErrors([error], language);
    
          item.Message = this.procoreGridItemTransformer.getMessage(errors, []);
          item.Errors = errors;
    
          return [];
        }
      }

        private transformToGroups(transactions: ProcoreTransactionsForAProject | null): ProcoreGridGroup[] {
            if (!transactions || transactions.AllEntities!.length <= 0) {
              return [];
            }
            const transformed = this.CreateTransactionsGroups();
        
            let purchases: ProcoreTransactionGroup | null | undefined;
        
            if (transactions.DirectCosts && transactions.OrderInvoices) {
              purchases = transactions.DirectCosts.find((x: any) => x && x.Type === ProcoreDirectCostTransactionType.Purchases);
        
              if (!purchases) {
                purchases = {
                  LoaderEntities: [],
                  Type: ProcoreDirectCostTransactionType.Purchases
                };
        
                transactions.DirectCosts.push(purchases);
              }
            }
        
            transformed[ProcoreTransactionType.PurchaseOrder].Children = this.commandContractTransactionTransformer.transform(transactions.PurchaseOrders);
            transformed[ProcoreTransactionType.Contracts].Children = this.commandContractTransactionTransformer.transform(transactions.Contracts);
            transformed[ProcoreTransactionType.ChangeOrders].Children = this.commandContractTransactionTransformer.transform(transactions.ChangeOrders);
            transformed[ProcoreTransactionType.DirectCosts].Children = this.directCostTransformer.transform(transactions.DirectCosts);
            transformed[ProcoreTransactionType.OrderInvoices].Children = this.directCostTransformer.transformTransactions(transactions.OrderInvoices, ProcoreDirectCostTransactionType.OrderInvoice);
        
            return transformed;
          }

          private CreateTransactionsGroups(): ProcoreGridGroup[] {
            const list = Object.keys(ProcoreTransactionType).map((key: string): ProcoreGridGroup | null => {
              try {
                return this.createGroup(Number(key), this.transactionsLoader.stockOrdersEnabled);
              } catch {
                return null;
              }
            });
        
            return list.filter((item: ProcoreGridGroup | null): boolean  => item != null)
                       .map((item: ProcoreGridGroup): ProcoreGridGroup => item);
          }

          private createGroup(transactionType: ProcoreTransactionType, stockOrdersEnabled: boolean): ProcoreGridGroup {
            let description = "";
            let target: SynchronizationTarget | null = null;
        
            const purchaseOrderString = stockOrdersEnabled
                                          ? "Procore.Procore_Entity_StockOrders"
                                          : "Procore.Procore_Entity_PurchaseOrders";
        
            switch (transactionType) {
              case ProcoreTransactionType.PurchaseOrder:
                description = this.i18n.tr(purchaseOrderString);
                target = SynchronizationTarget.Maestro;
                break;
        
              case ProcoreTransactionType.Contracts:
                description = this.i18n.tr("Procore.Procore_Entity_Contracts");
                target = SynchronizationTarget.Maestro;
                break;
        
              case ProcoreTransactionType.ChangeOrders:
                description = this.i18n.tr("Procore.Procore_Entity_ChangeOrders");
                target = SynchronizationTarget.Maestro;
                break;
        
              case ProcoreTransactionType.DirectCosts:
                description = this.i18n.tr("Procore.Procore_Entity_DirectCostItems");
                target = SynchronizationTarget.Procore;
                break;
              
                case ProcoreTransactionType.OrderInvoices:
                description = this.i18n.tr("Procore.Procore_Entity_OrderInvoices");
                target = SynchronizationTarget.Procore;
                break;
            }
        
            return {
              GroupName: description,
              IsGroup: true,
              Message: "",
              Target: target
            } as ProcoreGridGroup;
          }
}
