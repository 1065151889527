import { Router } from "aurelia-router";
import UserSettingService from "services/userSettingsService";

define([
    "knockout",
    "underscore",
    "services/projectService",
    "services/commonService",
    "helpers/routerHelper",
    "core/resx",
    "helpers/viewbag",
    "widgets/consultHoursWorkedButton"
], function (ko, _, projects, commonService, routerHelper, resx, viewbag) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        function ctor(router) {
            self = this;
            //#region Properties
            self.list = ko.observableArray([]);
            self.resx = resx;
            self.router = router;
            self.hasAtLeastOneResponsibleProject = ko.observable(false);
            self.onlyActiveDispatch = ko.observable(false);
            //#endregion
        }

        //#region Private Functions
        async function loadData() {
            //no need this info for the execution... only for subsequent views...
            commonService.refreshProjectConfigs();

            const usersetting = await UserSettingService.getOnlyActiveDispatchSetting();
            if (usersetting) {
                self.onlyActiveDispatch(usersetting.Value === "true");
            }

            const data = await projects.getProjectList()
            const isProjectResponsible = await projects.isCurrentUserProjectResponsible();

            self.list(data);
            self.hasAtLeastOneResponsibleProject(isProjectResponsible);
        }
        ctor.inject = [Router];
        //#endregion

        //#region Public Functions
        ctor.prototype.activate = function() {
            return loadData();
        };

        ctor.prototype.genUrl = function (isProjectResponsible, id) {
            var url = routerHelper.getRelativeUrl("", id);
            return url + routerHelper.addQuerystring({ isProjectResponsible: isProjectResponsible});
        };

        ctor.prototype.goToHoursSummary = function () {
            viewbag({isHourSummaryToday:true});
            var url = routerHelper.getRelativeUrl('hoursSummary');
            url += routerHelper.addQuerystring({ isProjectResponsible: self.hasAtLeastOneResponsibleProject() });

            self.router.navigate(url);
        };

        ctor.prototype.setOnlyActiveDispatchesSetting = async function () {
            self.onlyActiveDispatch(!self.onlyActiveDispatch())
            await UserSettingService.setOnlyActiveDispatchSetting(self.onlyActiveDispatch());
            await loadData();
        }

        //#endregion
        return ctor;
    })();

    return viewModel;
});
