define([
    "knockout",
    "services/projectService",
    "helpers/dateHelper",
    "helpers/routerHelper",
    "helpers/phoneHelper",
    "core/resx"
], function (ko, serviceProject, dateHelper, routerHelper, phoneHelper, resx) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        function ctor() {
            self = this;
            //#region Properties
            self.projectAddress = ko.observable('');
            self.stagingAddress = ko.observable('');
            self.dispatchProjectCode = ko.observable('');
            self.information = ko.observable({});
            self.dateHelper = dateHelper;
            self.routerHelper = routerHelper;
            self.phoneHelper = phoneHelper;
            self.resx = resx;
            //#endregion
        }

        //#region Private Functions
        function loadData() {
            return serviceProject.getDetail(self.dispatchProjectCode()).done(function (data) {
                self.information(data);

                self.projectAddress(self.information().ProjectLocation.Address +
                (self.information().ProjectLocation.City ? ', ' + self.information().ProjectLocation.City : '') +
                (self.information().ProjectLocation.Province ? ', ' + self.information().ProjectLocation.Province : '') +
                (self.information().ProjectLocation.PostalCode ? ', ' + self.information().ProjectLocation.PostalCode : ''));
                
                self.stagingAddress(self.information().Address +
                (self.information().City ? ', ' + self.information().City : '') +
                (self.information().Province ? ', ' + self.information().Province : '') +
                (self.information().PostalCode ? ', ' + self.information().PostalCode : ''));

               
            });
        }
        //#endregion

        //#region Public Functions
        ctor.prototype.activate = function (params) {
            self.dispatchProjectCode(params.dispatchProjectCode);

            return loadData();
        };
        //#endregion
        return ctor;
    })();

    return viewModel;
});
