define([
    "knockout",
    "core/resx",
    "helpers/queryStringHelper"
], function (ko, resx, queryStringHelper) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        function ctor() {
            self = this;
            //#region Properties
            self.resx = resx;

            self.contactId = ko.observable();
            self.dispatchProjectCode = ko.observable();
            self.isProjectResponsible = false;
            //#endregion
        }

        //#region Private Function
        function bindViewModel(dispatchProjectCode, id, queryString) {
            self.isProjectResponsible = queryStringHelper.parseIsProjectResponsible(queryString);

            self.dispatchProjectCode(dispatchProjectCode);
            self.contactId(id);
        }
        //#endregion

        //#region Public Functions
        ctor.prototype.activate = function (params) {
            bindViewModel(params.dispatchProjectCode, params.id, params.q);
        };
        //#endregion

        return ctor;
    })();

    return viewModel;
});
