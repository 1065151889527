define([
    "knockout",
    "jquery",
    "underscore",
    "helpers/dateHelper",
    "helpers/labelHelper",
    "helpers/routerHelper",
    "helpers/notificationHelper",
    "services/dispatchService",
    "services/dailyEntryService",
    "core/resx",
    "helpers/viewbag",
    "helpers/queryStringHelper",
    "services/templateService",
    "widgets/dateTimePicker",
    "widgets/pages/hoursSummary",
    "widgets/lstNoData"
], function (ko, jQuery, _, dateHelper, labelHelper, routerHelper, notificationHelper, dispatchService, dailyEntryService, resx, viewbag, queryStringHelper, templateService) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        function ctor() {
            self = this;

            //#region Properties
            self.dateHelper = dateHelper;
            self.labelHelper = labelHelper;
            self.routerHelper = routerHelper;
            self.resx = resx;
            self.selectedDate = ko.observable(dateHelper.getDate());
            self.selectedMaxDate = ko.observable(dateHelper.getDate());
            self.formatedSelectedDate = ko.computed(function() {
                return dateHelper.formatDate("YYYY-MM-DD", self.selectedDate());
            });
            self.formatedSelectedMaxDate = ko.computed(function () {
                return dateHelper.formatDate("YYYY-MM-DD", self.selectedMaxDate());
            });
            self.list = ko.observableArray([]);
            self.noDataText = ko.observable("");
            self.isProjectResponsible = false;
            self.subscribeSelected = undefined;
            //#endregion
        }

        //#region Private Functions
        function getUncompleteProjectsCodeList() {
            var lsprjs = [];
            _.each(self.list(), function (employee) {
                _.each(employee.Projects, function (project) {
                    if (!project.Readonly && _.indexOf(lsprjs, project.DispatchProjectCode) === -1) {
                        lsprjs.push(project.DispatchProjectCode);
                    }
                });
            });

            return lsprjs;
        }

        function loadData(selectedDate, selectedMaxDate) {
            return dispatchService.getHoursSummary(selectedDate, selectedMaxDate).done(function (data) {
                self.list(data);
            });
        }

        function reloadList()
        {
            routerHelper.showLoading();

            loadData(self.formatedSelectedDate(), self.formatedSelectedMaxDate()).always(function() {
                routerHelper.hideLoading();
            });
        }

        function showSomeErrorsWarning(data) {
            var msg = "";

            for (var i = 0; i < data.ProjectInError.length; i++) {
                msg += data.ProjectInError[i] + "<br/>";
            }
            notificationHelper.showWarning(resx.localize("err_CompleteAllDailyEntry_SomeError").replace('{prjs}', msg), "", { "timeOut": 5000 });
        }

        function showAllInErrorWarning() {
            notificationHelper.showWarning(resx.localize("err_CompleteAllDailyEntry_AllError"));
        }

        function showAllReadonlyWarning() {
            notificationHelper.showWarning(resx.localize("err_CompleteAllDailyEntry_AllReadonly"));
        }

        function allInError(value) {
            return value === 0;
        }

        function allOk(value) {
            return value === 1;
        }

        function someOk(value) {
            return value === 2;
        }

        function getTimeEntryMode() {
            return templateService.getCurrentTemplateConfigs().ProjectTimeEntryMode;
        }

        function getInChargeRequireIndicateWhenEmployeeLeftWorksite() {
            return templateService.getCurrentTemplateConfigs().InChargeRequireIndicateWhenEmployeeLeftWorksite;
        }
        //#endregion

        //#region Public Functions
        ctor.prototype.activate = function (queryString) {
            self.isProjectResponsible = queryStringHelper.parseIsProjectResponsible(queryString);

            if (viewbag() && viewbag().isHourSummaryToday) {
                viewbag(null);
                self.selectedDate(dateHelper.getDate());
                self.selectedMaxDate(dateHelper.getDate());
            }
            return loadData(self.formatedSelectedDate(), self.formatedSelectedMaxDate());
        };

        ctor.prototype.attached = function() {
            self.subscribeSelected = self.selectedDate.subscribe(function () {
                reloadList();
            });
            self.subscribeSelectedMax = self.selectedMaxDate.subscribe(function () {
                reloadList();
            });
        };

        ctor.prototype.detached = function() {
            if (self.subscribeSelected) {
                self.subscribeSelected.dispose();
            }
            if (self.subscribeSelectedMax) {
                self.subscribeSelectedMax.dispose();
            }
        };

        ctor.prototype.save = function () {
            var lsprjs = getUncompleteProjectsCodeList();

            if (lsprjs.length > 0) {
                routerHelper.showLoading();

                dailyEntryService.completeList(self.formatedSelectedDate(), lsprjs, getTimeEntryMode(), getInChargeRequireIndicateWhenEmployeeLeftWorksite())
                    .done(function (data) {
                        //Status 1 = all ok, 2 = some ok, 0 = all in error
                        if (allOk(data.Status)) {
                            routerHelper.navigateBack();
                            return;
                        }

                        if (someOk(data.Status)) {
                            loadData(self.formatedSelectedDate(), self.formatedSelectedMaxDate());
                            showSomeErrorsWarning(data);
                        } else if (allInError(data.Status)) {
                            showAllInErrorWarning();
                        }
                    })
                    .always(function() {
                        routerHelper.hideLoading();
                    });
            } else {
                showAllReadonlyWarning();
            }
        };

        //#endregion

        return ctor;
    })();

    return viewModel;
});
