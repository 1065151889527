import val from "core/val";
import { autoinject } from "aurelia-framework";
import routerHelper from "helpers/routerHelper";

import { ServiceCallQuotationService } from "services/service-call-quotation-service";
import { ServiceCallQuotationDetailsModel } from "api/models/company/service-call/service-call-quotation-details-model";

@autoinject()
export class QuotationInternalNotes {
    public val: any = val;

    public quotation: ServiceCallQuotationDetailsModel | null = null;
    public readonly: boolean = false;
  
    constructor(private readonly serviceCallQuotationService: ServiceCallQuotationService) {}

    public async activate(params: any): Promise<void> {
        this.readonly = params.readonly;
        await this.initQuotation(params.quotationId);
    }

    public async save(): Promise<void> {
        if (!this.quotation) { return; }

        await this.serviceCallQuotationService.saveQuotation(this.quotation, true, true).then(() => {
            routerHelper.navigateBack();
        });
    }

    private async initQuotation(quotationId: number): Promise<void> {
        this.quotation = await await this.serviceCallQuotationService.getQuotation(quotationId, true, true, true, true, false);
    }
}
