import { Router } from 'aurelia-router';
import { default as val } from "core/val";
import UserAccessService from "services/user-access-service";
import { CompanyService } from "services/company-service";
import { SettingRepository } from "repositories/setting-repository";

define([
    "knockout",
    "underscore",
    "core/resx",
    "helpers/queryStringHelper",
    "services/timesheetService",
    "helpers/labelHelper",
    "services/activityService",
    "services/bonusService",
    "services/defaultService",
    "services/commonService",
    "services/equipmentService",
    "services/timesheetEquipmentCounterService",
    "helpers/routerHelper",
    "helpers/notificationHelper",
    "helpers/enumHelper",
    "moment",
    "helpers/dateHelper",
    "services/tradesService",
    "repositories/settingRepository",
    "widgets/dateTimePicker",
    "widgets/timesheetHeader",
    "widgets/predefinedSentences",
    "widgets/multiSelectDays",
    "widgets/timesheetEmployeeHeader",
    "widgets/maSelect",
    "widgets/equipmentCounterEditor"
], function (ko, _, resx, queryStringHelper, timesheetService, labelHelper, activityService, bonusService, defaultValues, commonService, equipmentService,
    timesheetEquipmentCounterService, routerHelper, notificationHelper, enumHelper, moment, dateHelper, tradesService, settingRepository) {
        "use strict";
        var viewModel = (function () {
            var self = null;

            function ctor(router, userAccessService, companyService, settingRepository) {
                self = this;
                //#region properties
                self.labelHelper = labelHelper;
                self.resx = resx;
                self.router = router;
                self.userAccessService = userAccessService;
                self.companyService = companyService;
                self.companies = ko.observableArray([]);
                self.settingRepository = settingRepository;
                self.enumHelper = enumHelper;
                self.isAllowedToSeeEquipmentCounters = false;

                self.allowEmployeeSelection = false;
                self.isTeamLeader = false;
                self.isResponsibleForTimeEntry = false;
                self.isCreationMode = false;
                self.CompanyToSpecify = false;
                self.TimeEntryPrefix = null;
                self.readonly = false;

                self.isEquipmentTimeEntry = false;

                self.shiftList = [];
                self.subscriptions = [];

                self.wageTypes = ko.observableArray([]);
                self.timesheetId = ko.observable();
                self.timesheetEntryId = ko.observable();
                self.linkedTimesheetEntryId = ko.observable();
                self.showBonus = ko.observable(false);
                self.projectCode = ko.observable();
                self.targetPrefix = ko.observable();
                self.payPeriodStart = ko.observable();
                self.TimeEntryDate = ko.observable();
                self.bonusTemplateSelection = bonusTSelection;
                self.linkedEquipments = ko.observableArray([]);
                self.unit = ko.observable("");
                self.selectedHours = ko.observable(self.emptyDate());
                self.canEditEquipment = ko.observable(false);
                self.employee = ko.observable({});
                self.employees = ko.observableArray([]);
                self.employeesD = ko.observableArray([]);
                self.initialList = ko.observableArray([]);
                self.dayPrefix = ko.observable();
                self.selectedFilter = ko.observable();
                self.selectedResourceType = ko.observable(enumHelper.attendanceTypes().EMPLOYEE.id);
                self.dataDto = ko.observableArray([]);
                self.myActiveClass = ko.observable("");
                self.hoursEdition = ko.observable(false);
                self.showOnlyEquipment = ko.observable(false);
                self.employeeIdToEdit = ko.observable();
                self.counter1Value = ko.observable();
                self.counter2Value = ko.observable();

                //UNIFIED INTERFACE
                self.totalHours = 0;
                self.startTime = "";
                self.endTime = "00:00";
                self.regularTime = 0;
                self.halfTime = 0;
                self.doubleTime = 0;
                self.selectedStartTime = ko.observable(null);
                self.selectedEndTime = ko.observable(null);
                self.selectedRegularTime = ko.observable(self.emptyDate());
                self.selectedHalfTime = ko.observable(self.emptyDate());
                self.selectedDoubleTime = ko.observable(self.emptyDate());

                // Counters informations
                self.showEquipmentCounter = ko.observable(false);
                self.linkedEquipmentsCounters = ko.observableArray([]);
                self.counter1TypeDescription = ko.observable("");
                self.counter1PreviousValue = ko.observable("");
                self.counter1Type = ko.observable("");
                self.hasCounter1 = ko.observable(false);
                self.counter2TypeDescription = ko.observable("");
                self.counter2PreviousValue = ko.observable("");
                self.counter2Type = ko.observable("");
                self.hasCounter2 = ko.observable(false);

                self.commentMaxLength = val.get('timesheets.timeentry.comment', 'maxLength');

                self.validatedForm = ko.validatedObservable({
                    targetPrefix: ko.observable(),
                    project: ko.observable(),
                    hours_activity: ko.observable(),
                    group: ko.observable(),
                    equipment_activity: ko.observable(),
                    bonus_activity: ko.observable(),
                    equipment: ko.observable(),
                    employees: ko.observable(),
                    totalHours: ko.observable(0),
                    bonus: ko.observable(),
                    quantity: ko.observable(null),
                    shift: ko.observable(""),
                    wageType: ko.observable(),
                    comment: ko.observable(),
                    employeeSelectedDays: ko.observableArray([]),
                    bonusSelectedDays: ko.observableArray([]),
                    equipmentGroup: ko.observable(),
                    bonusGroup: ko.observable(),
                    trade: ko.observable(),

                    init: function () {
                        var valForm = this;

                        valForm.targetPrefix = valForm.targetPrefix.extend({
                            required: {
                                onlyIf: function () {
                                    this.message = resx.localize('err_CompaniesSelectionRequired');
                                    return self.CompanyToSpecify;
                                }
                            }
                        });
                        valForm.project = valForm.project.extend({
                            required: {
                                message: resx.localize("err_ProjectRequired")
                            },
                            validation: {
                                validator: function () {
                                    if (!self.validatedForm().hours_activity() &&
                                        !self.validatedForm().equipment_activity() &&
                                        !self.validatedForm().bonus_activity()) {
                                        this.message = resx.localize('err_ActivityRequired');
                                        return false;
                                    }
                                    return true;
                                }
                            }
                        });
                        valForm.hours_activity = valForm.hours_activity.extend({
                            validation: {
                                validator: function () {
                                    if (!self.validatedForm().hours_activity() &&
                                        !self.showBonus() &&
                                        !self.validatedForm().equipment_activity()) {
                                        this.message = resx.localize('err_HoursActivityRequired');
                                        return false;
                                    }
                                    return true;
                                }
                            }
                        });
                        valForm.totalHours = valForm.totalHours.extend({
                            validation: {
                                validator: function () {
                                    if ((self.validatedForm().totalHours() === 0) &&
                                        (self.validatedForm().hours_activity() ||
                                            self.validatedForm().equipment_activity()) &&
                                        !self.showBonus()) {
                                        this.message = resx.localize('err_TimeSpentOrEquipmentHoursRequired');
                                        return false;
                                    }
                                    return true;
                                }
                            }
                        });
                        valForm.employeeSelectedDays = valForm.employeeSelectedDays.extend({
                            validation: {
                                validator: function () {
                                    if (self.validatedForm().employeeSelectedDays().length === 0 &&
                                        self.isCreationMode &&
                                        !self.showBonus()) {
                                        this.message = resx.localize('err_YouMustSelectADay');
                                        return false;
                                    }
                                    return true;
                                }
                            }
                        });
                        valForm.bonusSelectedDays = valForm.bonusSelectedDays.extend({
                            validation: {
                                validator: function () {
                                    if (self.validatedForm().bonusSelectedDays().length === 0 &&
                                        self.isCreationMode &&
                                        self.showBonus()) {
                                        this.message = resx.localize('err_YouMustSelectADay');
                                        return false;
                                    }
                                    return true;
                                }
                            }
                        });
                        valForm.equipment = valForm.equipment.extend({
                            validation: {
                                validator: function () {
                                    if (!self.validatedForm().equipment() &&
                                        !self.showBonus() &&
                                        (self.validatedForm().equipment_activity())) {
                                        this.message = resx.localize('err_EquipmentRequired');
                                        return false;
                                    }
                                    return true;
                                }
                            }
                        });
                        valForm.equipment_activity = valForm.equipment_activity.extend({
                            validation: {
                                validator: function () {
                                    if (!self.validatedForm().equipment_activity() &&
                                        !self.showBonus() &&
                                        (self.validatedForm().equipment())) {
                                        this.message = resx.localize('err_EquipmentActivityRequired');
                                        return false;
                                    }
                                    return true;
                                }
                            }
                        });
                        valForm.bonus = valForm.bonus.extend({
                            validation: {
                                validator: function () {
                                    if (!self.validatedForm().bonus &&
                                        self.showBonus() &&
                                        (self.quantity() >= 0 || self.validatedForm().bonus_activity())) {
                                        this.message = resx.localize('err_BonusRequired');
                                        return false;
                                    }
                                    return true;
                                }
                            }
                        });
                        valForm.bonus_activity = valForm.bonus_activity.extend({
                            validation: {
                                validator: function () {
                                    if (!self.validatedForm().bonus_activity() &&
                                        self.showBonus() &&
                                        self.validatedForm().bonus()) {
                                        this.message = resx.localize('err_BonusActivityRequired');
                                        return false;
                                    }
                                    return true;
                                }
                            }
                        });
                        valForm.quantity = valForm.quantity.extend({
                            validation: {
                                validator: function () {
                                    if (!self.validatedForm().quantity() &&
                                        self.showBonus() &&
                                        (self.validatedForm().bonus() || self.validatedForm().bonus_activity())) {
                                        this.message = resx.localize("err_BonusQtyRequired");
                                        return false;
                                    }
                                    if (self.validatedForm().quantity() < 0 &&
                                        self.validatedForm().bonus() &&
                                        self.showBonus()) {
                                        this.message = resx.localize("err_MinQuantity");
                                        return false;
                                    }
                                    if (self.validatedForm().quantity() &&
                                        (!self.validatedForm().bonus() || !self.validatedForm().bonus_activity()) &&
                                        self.showBonus()) {
                                        this.message = resx.localize("err_BonusRequired");
                                        return false;
                                    }
                                    return true;
                                }
                            }
                        });
                    },

                    clear: function () {
                        var valForm = this;

                        valForm.targetPrefix(null);
                        valForm.project(null);
                        valForm.trade(null);
                        valForm.group(null);
                        valForm.hours_activity(null);
                        valForm.equipment_activity(null);
                        valForm.bonus_activity(null);
                        valForm.employees(null);
                        valForm.equipment(null);
                        valForm.totalHours(0);
                        valForm.bonus(null);
                        self.unit("");
                        valForm.quantity(null);
                        valForm.shift(0);
                        valForm.comment("");
                        valForm.wageType(enumHelper.wageType().SIMPLE);
                        self.employees([]);
                        self.employeeIdToEdit(null);
                        valForm.equipmentGroup(null);
                        valForm.bonusGroup(null);
                        valForm.employeeSelectedDays([]);
                        valForm.bonusSelectedDays([]);
                    }
                });
                //#endregion

                // On empêche les retours de ligne car ceux-ci ne sont pas affichables dans maestro*
                self.validatedForm().comment.subscribe(function () {
                    self.validatedForm().comment(self.validatedForm().comment().replace(/\n/g, ""));
                });

                //#region lookup
                self.lookupEmployee = {
                    transport: function (params, success, failure) {
                        timesheetService
                            .getEmployeeForTimesheet(self.payPeriodStart(), params.data.filter, params.data.page || 1)
                            .done(success)
                            .fail(failure);
                    },
                    mapResults: function (item) {
                        return {
                            id: item.Id,
                            text: item.Id + ' - ' + item.FirstName + ' ' + item.LastName,
                            data: {
                                LinkedEquipement: item.LinkedEquipment,
                                DefaultActivityId: item.DefaultActivityId
                            }
                        };

                    }
                };
                self.lookupProjects = {
                    transport: function (params, success, failure) {
                        let prefix = self.TimeEntryPrefix;
                        if (self.CompanyToSpecify && self.validatedForm().targetPrefix()) {
                            prefix = self.validatedForm().targetPrefix().id;
                        }

                        timesheetService.getProjectForTimesheet(prefix, params.data.filter, params.data.page || 1)
                            .done(success)
                            .fail(failure);
                    }
                };
                self.lookupTrades = {
                    transport: function (params, success, failure) {
                        tradesService.getTradesForEmployee(concernedEmployee(), params.data.filter, params.data.page || 1)
                            .done(function (data) {
                                return success(_.map(data,
                                    function (x) {
                                        x.Id = x.Code;
                                        return x;
                                    }));
                            })
                            .fail(failure);
                    }
                };
                self.lookupActivity = {
                    transport: function (params, success, failure) {
                        activityService.getActivitiesForProject(self.validatedForm().project().id,
                            params.data.filter,
                            params.data.page || 1)
                            .then(success)
                            .catch(failure);
                    }
                };
                self.lookupGroup = {
                    transport: function (params, success, failure) {
                        timesheetService.getGroupsforTimesheet(params.data.filter, params.data.page || 1)
                            .done(success)
                            .fail(failure);
                    },
                    mapResults: function (item) {
                        return {
                            id: item.Id,
                            text: item.Id + ' - ' + item.Description
                        };
                    }
                };
                self.lookupEquipment = {
                    transport: function (params, success, failure) {
                        timesheetService
                            .getEquipmentForTimesheet(getSingleEmployeeId(), params.data.filter, params.data.page || 1)
                            .done(success)
                            .fail(failure);
                    },
                    mapResults: function (item) {
                        return {
                            id: item.Code,
                            text: item.Code + ' - ' + item.Description
                        };
                    }
                };
                self.lookupBonus = {
                    transport: function (params, success, failure) {
                        timesheetService.getBonusForTimesheet(params.data.filter, params.data.page || 1)
                            .done(success)
                            .fail(failure);
                    }
                };
                self.lookupEquipmentGroup = {
                    transport: function (params, success, failure) {
                        timesheetService.getEquipmentGroupsforTimesheet(params.data.filter, params.data.page || 1)
                            .done(success)
                            .fail(failure);
                    },
                    mapResults: function (item) {
                        return {
                            id: item.Id,
                            text: item.Id + ' - ' + item.Description
                        };
                    }
                };
                self.lookupCompanies = {
                    transport: function (params, success, failure) {
                        self.companyService.getAll()
                            .done(success)
                            .fail(failure);
                    },
                    mapResults: function (item) {
                        return {
                            id: item.Prefix,
                            text: item.Name
                        };
                    }
                };
                //#endregion
            }

            ctor.inject = [Router, UserAccessService, CompanyService, SettingRepository];

            //#region private functions
            function getSingleEmployeeId() {
                if (self.employees().length === 0) {
                    // No employee selected. Use current user.
                    return self.employee().Id
                }

                if (self.employees().length === 1) {
                    // Only one employee selected. Return him
                    return _.first(self.employees()).id
                }

                // More than 1 selected. Return null
                return null;
            }

            function bindViewModel(timesheetId, timesheetEntryId, querystring) {
                self.timesheetId(timesheetId);
                self.timesheetEntryId(timesheetEntryId);

                //queryString Parsing
                const qs = routerHelper.getQuerystring(querystring);
                self.employee(JSON.parse(qs.Employee));
                self.linkedEquipments(JSON.parse(qs.LinkedEquipments));
                self.payPeriodStart(qs.PayPeriodStartDate);
                self.isTeamLeader = queryStringHelper.parseIsTeamLeaderTimesheet(querystring);
                self.isResponsibleForTimeEntry = queryStringHelper.IsResponsibleforTimeEntry(querystring);

                self.dayPrefix(getDayPrefix(self.payPeriodStart()));
                self.isCreationMode = !(timesheetId && timesheetEntryId);
                self.allowEmployeeSelection = self.isTeamLeader && self.isResponsibleForTimeEntry && self.isCreationMode;
                self.hoursEdition(false);
                self.showBonus(false);
                self.showOnlyEquipment(false);
                self.isAllowedToSeeEquipmentCounters = self.userAccessService.isOptionEnabledSync(self.enumHelper.userOptions.EQUIPMENTCOUNTERS);

                //empty initialised list
                self.initialList([]);
            }

            async function loadData() {
                loadWageTypes();

                await Promise.all([loadShifts(), loadEntry()]);

                await initCompanies();
            }

            async function loadDefaultTrade() {
                var employeeId = getSingleEmployeeId();

                if (!employeeId) {
                    self.validatedForm().trade(null);
                    return;
                }

                const defaultTrade = await tradesService.getEmployeeDefaultTrade(employeeId).promise();

                if (defaultTrade) {
                    self.validatedForm().trade({
                        id: defaultTrade.Code,
                        text: defaultTrade.Code + " - " + defaultTrade.Description
                    });
                }
            }

            async function getAllowedExpenseCompanySelection() {
                self.CompanyToSpecify = await timesheetService.getAllowedExpenseCompanySelection();
            }

            async function loadDefaultActivityForLabor() {
                self.validatedForm().hours_activity(null);

                const project = self.validatedForm().project();
                var defaultActivityIdList = _.chain(self.employees())
                    .map(emp => emp.data.DefaultActivityId)
                    .uniq()
                    .filter(defaultActivityId => defaultActivityId != "")
                    .value();

                if (defaultActivityIdList.length !== 1) {
                    if (self.employee().DefaultActivityId && defaultActivityIdList.length === 0) {
                        defaultActivityIdList[0] = self.employee().DefaultActivityId;
                    } else {
                        // If there is zero or multiple default activities let the user choose.
                        return;
                    }
                }

                var activityCode = _.first(defaultActivityIdList);

                if (!activityCode) {
                    return;
                }

                var activity;

                if (project) {
                    activity = await activityService.getActivityForProject(project.id, activityCode);
                } else {
                    activity = await activityService.getActivity(activityCode);
                }

                if (!activity) {
                    notificationHelper.showError(resx.localize('err_DefaultActivityNotExistForProject'));
                    return;
                }

                if (project && !activity.AvailableInMobileForLabour) {
                    notificationHelper.showError(resx.localize('err_DefaultActivityNotAvailableForMobile'));
                    return;
                }

                self.validatedForm().hours_activity({
                    id: activity.Id,
                    text: activity.Id + ' - ' + activity.Description
                });
            }

            async function loadDefaultActivityForEquipment() {
                self.validatedForm().equipment_activity(null);

                const project = self.validatedForm().project();
                const equipment = self.validatedForm().equipment();

                if (!project || !project.id || !equipment || !equipment.data.DefaultActivityId) {
                    // Cannot load activities without project.
                    return;
                }

                const activity = await activityService.getActivityForProject(project.id, equipment.data.DefaultActivityId);

                if (!activity) {
                    return;
                }

                self.validatedForm().equipment_activity({
                    id: activity.Id,
                    text: activity.Id + ' - ' + activity.Description
                });
            }

            async function loadDefaultActivityForBonus() {
                self.validatedForm().bonus_activity(null);
                let defaultActivityCode = null;

                const project = self.validatedForm().project();
                const bonus = self.validatedForm().bonus();

                if (!bonus) {
                    return;
                }

                if (bonus.data) {
                    defaultActivityCode = bonus.data.DefaultActivityCode
                } else {
                    defaultActivityCode = bonus.DefaultActivityCode
                }

                if (!defaultActivityCode) {
                    var defaultActivityIdList = _.chain(self.employees())
                        .map(emp => emp.data.DefaultActivityId)
                        .uniq()
                        .value();

                    if (defaultActivityIdList.length !== 1) {
                        if (self.employee().DefaultActivityId && defaultActivityIdList.length === 0) {
                            defaultActivityIdList[0] = self.employee().DefaultActivityId;
                        } else {
                            // If there is zero or multiple default activities let the user choose.
                            return;
                        }
                    }

                    defaultActivityCode = _.first(defaultActivityIdList);
                }

                if (!project || !project.id || !defaultActivityCode) {
                    // Cannot load activities without project.
                    return;
                }

                const activity = await activityService.getActivityForProject(project.id, defaultActivityCode);

                if (!activity) {
                    return;
                }

                if (project && !activity.AvailableInMobileForLabour) {
                    return;
                }

                self.validatedForm().bonus_activity({
                    id: activity.Id,
                    text: activity.Id + ' - ' + activity.Description
                });
            }

            async function loadEntry() {
                if (self.isCreationMode) {
                    self.validatedForm().init();
                    if (self.isResponsibleForTimeEntry && self.isTeamLeader && self.isCreationMode) {
                        loadStoredEmployeeList();
                    }

                    self.TimeEntryPrefix = self.settingRepository.getPrefix();

                    self.canEditEquipment(true);

                    loadLinkedEquipmentsCountersInfos();

                    const defaultActivityPromise = loadDefaultActivityForLabor();
                    const defaultTradePromise = loadDefaultTrade();
                    await Promise.all([defaultActivityPromise, defaultTradePromise]);
                    return;
                }
                const loadedData = await timesheetService.getTimesheetEntryToLoad(self.timesheetId(), self.timesheetEntryId()).promise();

                if (loadedData && loadedData.Prefix) {
                    self.TimeEntryPrefix = loadedData.Prefix;
                }

                if (loadedData.Bonus) {
                    loadBonus(loadedData);
                } else if (loadedData.EmployeeActivity) {
                    loadHours(loadedData);
                } else {
                    self.canEditEquipment(true);
                    self.isEquipmentTimeEntry = true;

                    loadEquipments(loadedData);
                }
            }

            async function initCompanies() {

                if (!self.CompanyToSpecify) {
                    self.validatedForm().targetPrefix(null);
                    return Promise.resolve();
                }

                return self.companyService.getAll().then(
                    data => {
                        let list = _.map(data, function (item) {
                            return { id: item.Prefix, tag: item.Tag, text: item.Name };
                        });
                        self.companies(list);
                        let selectedCompanyPrefix = self.settingRepository.getPrefix();
                        if (self.TimeEntryPrefix) {
                            selectedCompanyPrefix = self.TimeEntryPrefix;
                        }

                        if (selectedCompanyPrefix) {
                            self.validatedForm().targetPrefix(self.companies().find(company => company.id === selectedCompanyPrefix));
                        }
                        return Promise.resolve();
                    },
                    reason => {
                        self.companies([]);
                        return Promise.reject(new Error(this.i18n.tr("err_CompanyNotFound")));
                    }
                );
            }

            function loadStoredEmployeeList() {
                var savedEmployee = JSON.parse(settingRepository.getTimesheetDefaultValues());
                if (savedEmployee && savedEmployee.employees[0].text) {
                    if (currentPeriodEmployeeList(savedEmployee.currentPeriod)) {
                        self.employees(savedEmployee.employees);
                        //map to add selected()
                        _.map(self.employees(), function (entry) {
                            return _.extend(entry, {
                                selected: ko.observable(true)
                            });
                        });
                        ko.utils.arrayPushAll(self.initialList, self.employees());
                    } else {
                        initializeInitialList(constructData());
                    }
                } else {
                    initializeInitialList(constructData());
                }
            }

            function currentPeriodEmployeeList(period) {
                return self.payPeriodStart() === period;
            }

            function initializeInitialList(entries) {
                self.initialList()[0] = entries;
            }

            function constructData() {
                return {
                    id: self.employee().Id,
                    text: self.employee().Id + " - " + self.employee().FirstName + " " + self.employee().LastName,
                    type: 1,
                    selected: ko.observable(true),
                    data: {
                        Id: self.employee().Id,
                        LastName: self.employee().FirstName,
                        FirstName: self.employee().LastName,
                        LinkedEquipment: self.linkedEquipments(),
                        DefaultActivityId: self.employee().DefaultActivityId
                    }
                };
            }

            async function loadShifts() {
                const data = await commonService.getShifts().promise();

                var shifList = _.map(data, function (shift) {
                    return {
                        id: shift.Id,
                        text: shift.Id + " - " + shift.Description
                    };
                });

                self.shiftList = shifList;
                if (self.shiftList.length > 0) {
                    self.validatedForm().shift(self.shiftList[0].id);
                }

            }

            function getDayPrefix(startDate) {
                var date = dateHelper.dateFromUTC(startDate);
                return date.getDay();
            }

            function bonusTSelection(item) {
                if (item.data) {
                    self.validatedForm().bonus({
                        id: item.data.BonusCode,
                        text: item.data.BonusCode + " - " + item.data.BonusDescription
                    });
                    self.validatedForm().quantity(1);
                    self.unit(item.data.Unit);
                }
            }

            function initDropDownLists() {
                jQuery("#ddlShift").select2({
                    minimumResultsForSearch: defaultValues.getMinimumResultsForSearch(),
                    allowClear: false,
                    placeholder: resx.localize("SelectOoo"),
                    width: "100%",
                    language: labelHelper.getDefaultSelect2Labels(),
                    data: self.shiftList
                }).on("change", function (e) {
                    self.validatedForm().shift(e.target.value);
                }).val(self.validatedForm().shift()).trigger("change");
            }

            function loadHours(loadedData) {
                self.hoursEdition(true);
                var date = dateHelper.getDate();
                var workedHours = date.setMinutes(loadedData.Hours * 60, 0, 0);
                self.validatedForm().project({
                    id: loadedData.ProjectId,
                    text: loadedData.ProjectId + " - " + loadedData.ProjectDescription
                });
                self.validatedForm().trade({
                    id: loadedData.TradeId,
                    text: loadedData.TradeId + " - " + loadedData.TradeDescription
                });
                self.validatedForm().hours_activity({
                    id: loadedData.EmployeeActivity,
                    text: loadedData.EmployeeActivity + " - " + loadedData.EmployeeActivityDescription
                });
                self.validatedForm().wageType(parseInt(loadedData.RateType));
                self.validatedForm().shift(loadedData.Shift);

                self.selectedHours(workedHours);
                self.validatedForm().totalHours(loadedData.Hours);

                self.canEditEquipment(loadedData.CanEditEquipment);

                self.validatedForm().comment(loadedData.Comment);
                self.TimeEntryDate(loadedData.TimeEntryDate);
                self.employeeIdToEdit(loadedData.EmployeeId);

                self.linkedTimesheetEntryId(loadedData.LinkedTimesheetEntryId);
                fillLinkedEquipmentsCounter(loadedData.LinkedEquipmentsCounters);

                self.selectedStartTime(loadedData.StartTime);
                self.selectedEndTime(loadedData.EndTime);
            }

            function loadEquipments(loadedData) {
                var date = dateHelper.getDate();
                var workedHours = date.setMinutes(loadedData.Hours * 60, 0, 0);
                self.showOnlyEquipment(true);
                self.validatedForm().project({
                    id: loadedData.ProjectId,
                    text: loadedData.ProjectId + " - " + loadedData.ProjectDescription
                });
                self.validatedForm().equipment_activity({
                    id: loadedData.EquipmentActivity,
                    text: loadedData.EquipmentActivity + " - " + loadedData.EquipmentActivityDescription
                });
                self.validatedForm().equipment({
                    id: loadedData.EquipmentId,
                    text: loadedData.EquipmentId + " - " + loadedData.EquipmentDescription
                });
                self.validatedForm().wageType(parseInt(loadedData.RateType));
                self.validatedForm().shift(loadedData.Shift);

                self.selectedHours(workedHours);
                self.validatedForm().totalHours(loadedData.Hours);

                self.validatedForm().comment(loadedData.Comment);
                self.TimeEntryDate(loadedData.TimeEntryDate);
                self.employeeIdToEdit(loadedData.EmployeeId);

                // Equipment counter
                self.counter1Value(loadedData.Counter1Value);
                self.counter2Value(loadedData.Counter2Value);

                self.linkedTimesheetEntryId(loadedData.LinkedTimesheetEntryId);
                loadEquipmentCountersInfos(loadedData.EquipmentId);

                self.selectedStartTime(loadedData.StartTime);
                self.selectedEndTime(loadedData.EndTime);
            }

            function loadEquipmentCountersInfos(equipmentId) {
                equipmentService.getEquipmentWithCounters(equipmentId)
                    .done(function (result) {
                        if (result.EquipmentId != null) {
                            self.counter1TypeDescription(result.Counter1TypeDescription);
                            self.counter1PreviousValue(result.Counter1PreviousValue);
                            self.counter1Type(result.Counter1Type);
                            self.hasCounter1(result.HasCounter1);

                            self.counter2TypeDescription(result.Counter2TypeDescription);
                            self.counter2PreviousValue(result.Counter2PreviousValue);
                            self.counter2Type(result.Counter2Type);
                            self.hasCounter2(result.HasCounter2);

                            self.showEquipmentCounter(true);
                        } else {
                            self.counter1Value(0);
                            self.counter2Value(0);

                            self.showEquipmentCounter(false);
                        }
                    });
            }

            function loadLinkedEquipmentsCountersInfos() {
                if(self.employees().length > 1){
                    self.linkedEquipmentsCounters(null);
                    return;
                }

                // In allowSelectionEmployeeMode, we use the employees list
                var employeeId = self.employees().length === 1 ? self.employees()[0].id : null;

                timesheetEquipmentCounterService
                    .getTimesheetEmployeeLinkedEquipmentsWithCounters(employeeId || self.employeeIdToEdit() || self.employee().Id)
                    .done(function (results) {
                        fillLinkedEquipmentsCounter(results);
                    });
            }

            function fillLinkedEquipmentsCounter(linkedEquipmentsCountersResults) {
                if (!linkedEquipmentsCountersResults || linkedEquipmentsCountersResults.length === 0) {
                    self.linkedEquipmentsCounters(null);
                    return;
                }

                var arrayCounter = [];

                _.each(linkedEquipmentsCountersResults,
                    function (result, i) {
                        arrayCounter.push({
                            EquipmentId: ko.observable(result.EquipmentId),
                            EquipmentDescription: ko.observable(result.EquipmentDescription),
                            Counter1Value: ko.observable(result.Counter1Value),
                            Counter1TypeDescription: ko.observable(result.Counter1TypeDescription),
                            Counter1PreviousValue: ko.observable(result.Counter1PreviousValue),
                            Counter1Type: ko.observable(result.Counter1Type),
                            HasCounter1: ko.observable(result.HasCounter1),
                            Counter2Value: ko.observable(result.Counter2Value),
                            Counter2TypeDescription: ko.observable(result.Counter2TypeDescription),
                            Counter2PreviousValue: ko.observable(result.Counter2PreviousValue),
                            Counter2Type: ko.observable(result.Counter2Type),
                            HasCounter2: ko.observable(result.HasCounter2)
                        });
                    });

                self.linkedEquipmentsCounters(arrayCounter);
            }

            function loadBonus(data) {
                self.showBonus(true);
                self.validatedForm().project({
                    id: data.ProjectId,
                    text: data.ProjectId + " - " + data.ProjectDescription
                });
                self.validatedForm().bonus_activity({
                    id: data.BonusActivity,
                    text: data.BonusActivity + " - " + data.BonusActivityDescription
                });
                self.validatedForm().bonus({
                    id: data.Bonus,
                    text: data.Bonus + " - " + data.BonusDescription,
                    DefaultActivityCode: data.BonusActivity
                });
                self.validatedForm().quantity(data.BonusQuantity);
                self.unit(data.BonusUnit);
                self.validatedForm().comment(data.Comment);
                self.TimeEntryDate(data.TimeEntryDate);
                self.employeeIdToEdit(data.EmployeeId);
            }

            function loadWageTypes() {
                var Wage = function (id) {
                    var wSelf = this;
                    wSelf.id = id;
                    wSelf.cssClass = ko.computed(function () {
                        return getWageTypeCssClass(id);
                    }, wSelf);

                    wSelf.click = function () {
                        self.wageTypeClicked(id);
                    };
                };

                self.wageTypes(_.map(_.range(3),
                    function (i) {
                        return new Wage(i);
                    }));
            }

            function getWageTypeCssClass(id) {
                var cls = self.validatedForm().wageType() === id ? 'fill' : '';
                cls += self.readonly ? " disabled" : "";
                return cls;
            }

            function gethoursbyEmployee(workTime, day, wageType, startTime, endTime) {
                return _.map(self.employees(),
                    function (employee) {
                        var calculatedEntryDate = dateHelper.addDay(self.payPeriodStart(), day);
                        var data = {};
                        data.TimesheetId = self.timesheetId();
                        data.TimesheetEntryId = self.timesheetEntryId();
                        data.LinkedTimesheetEntryId = self.linkedTimesheetEntryId();
                        data.EmployeeId = employee.id;
                        data.RateType = wageType ? wageType : self.validatedForm().wageType();
                        data.TargetPrefix = self.CompanyToSpecify ? self.validatedForm().targetPrefix().id : self.TimeEntryPrefix;
                        data.ProjectId = self.validatedForm().project().id;
                        data.EmployeeActivity = self.validatedForm().hours_activity().id;
                        data.Shift = self.validatedForm().shift();
                        data.TradeId = self.validatedForm().trade() && (self.employees().length < 2) ? self.validatedForm().trade().id : "";
                        data.TimeEntryDate = self.isCreationMode ? dateHelper.formatDateToSend(calculatedEntryDate) : self.TimeEntryDate();
                        data.Hours = workTime;
                        data.Comment = self.validatedForm().comment;
                        data.LinkedEquipmentsCounters = getLinkedEquipments();
                        data.StartTime = startTime;
                        data.EndTime = endTime;
                        return data;
                    });
            }

            function getHours(workTime) {
                return _.map(self.validatedForm().employeeSelectedDays(),
                    function (day) {
                        self.dataDto.push.apply(self.dataDto, gethoursbyEmployee(workTime, getDayToAdd(day), self.validatedForm().wageType(), self.startTime, self.endTime));
                    });
            }

            function getHoursUnified() {
                var usedWages =
                    _.filter([
                        { wage: enumHelper.wageType().SIMPLE, workedHours: self.regularTime, startTime: self.startTime, endTime: self.endTime },
                        { wage: enumHelper.wageType().OVER, workedHours: self.halfTime, startTime: self.startTime, endTime: self.endTime },
                        { wage: enumHelper.wageType().DOUBLE, workedHours: self.doubleTime, startTime: self.startTime, endTime: self.endTime }],
                        (wage) => { return wage.workedHours && wage.workedHours > 0 });

                let startTime = self.startTime;
                let endTime = self.endTime;

                return _.map(usedWages,
                    function (workedWage) {
                        var setStartEndTime = true;
                        return _.map(self.validatedForm().employeeSelectedDays(),
                            function (day) {
                                if (self.startTime !== "" && setStartEndTime) {
                                    if (workedWage.wage !== enumHelper.wageType().SIMPLE) {
                                        startTime = endTime;
                                    }
                                    endTime = getEndTime(startTime, workedWage.workedHours);
                                    setStartEndTime = false
                                }

                                self.dataDto.push.apply(self.dataDto, gethoursbyEmployee(workedWage.workedHours, getDayToAdd(day), workedWage.wage, startTime, endTime));
                            });
                    });
            }

            function getEquipment(workTime) {
                return _.map(self.validatedForm().employeeSelectedDays(), function (day) {
                    var calculatedEntryDate = dateHelper.addDay(self.payPeriodStart(), getDayToAdd(day));
                    var data = {};
                    data.TimesheetId = self.timesheetId();
                    data.TimesheetEntryId = self.isEquipmentTimeEntry ? self.timesheetEntryId() : 0;
                    data.LinkedTimesheetEntryId = self.linkedTimesheetEntryId();
                    data.EmployeeId = self.employees()[0].id;
                    data.EquipmentId = self.validatedForm().equipment().id;
                    data.TargetPrefix = self.CompanyToSpecify ? self.validatedForm().targetPrefix().id : self.TimeEntryPrefix;
                    data.ProjectId = self.validatedForm().project().id;
                    data.EquipmentActivity = self.validatedForm().equipment_activity().id;
                    data.TimeEntryDate = self.isCreationMode ? dateHelper.formatDateToSend(calculatedEntryDate) : self.TimeEntryDate();
                    data.Hours = workTime;
                    data.Comment = self.validatedForm().comment;
                    data.Counter1Value = self.counter1Value();
                    data.Counter2Value = self.counter2Value();
                    data.RateType = enumHelper.wageType().EQUIPMENT;
                    data.StartTime = self.startTime;
                    data.EndTime = self.endTime;
                    return data;
                });
            }

            function getBonusByEmployee(workTime, day) {
                return _.map(self.employees(),
                    function (employee) {
                        var calculatedEntryDate = dateHelper.addDay(self.payPeriodStart(), day);
                        var data = {};
                        data.TimesheetId = self.timesheetId();
                        data.TimesheetEntryId = self.timesheetEntryId();
                        data.TimesheetEntryId = self.timesheetEntryId();
                        data.EmployeeId = employee.id;
                        data.TargetPrefix = self.CompanyToSpecify ? self.validatedForm().targetPrefix().id : self.TimeEntryPrefix;
                        data.ProjectId = self.validatedForm().project().id;
                        data.Bonus = self.validatedForm().bonus().id;
                        data.BonusQuantity = self.validatedForm().quantity();
                        data.BonusActivity = self.validatedForm().bonus_activity().id;
                        data.TimeEntryDate = self.isCreationMode ? dateHelper.formatDateToSend(calculatedEntryDate) : self.TimeEntryDate();
                        data.Comment = self.validatedForm().comment;
                        data.RateType = enumHelper.wageType().BONUS;
                        data.StartTime = self.startTime;
                        data.EndTime = self.endTime;
                        return data;
                    });
            }

            function getBonus(workTime) {
                return _.map(self.validatedForm().bonusSelectedDays(),
                    function (day) {
                        self.dataDto.push.apply(self.dataDto, getBonusByEmployee(workTime, getDayToAdd(day)));
                    });
            }

            function getDayToAdd(widgetChoice) {
                var dayToAdd = widgetChoice >= self.dayPrefix() ? (widgetChoice - self.dayPrefix()) % 7 : ((7 + widgetChoice - self.dayPrefix()) % 7);
                return dayToAdd;
            }

            function getWorkTime(time) {
                return moment(time).diff(moment(self.emptyDate()), "minutes") / 60;
            }

            function getLinkedEquipments() {
                return _.map(self.linkedEquipmentsCounters(),
                    function (linkedEquipment) {
                        var data = {};
                        data.TimesheetId = self.timesheetId();
                        data.EquipmentId = linkedEquipment.EquipmentId();
                        data.Counter1Value = linkedEquipment.Counter1Value();
                        data.Counter2Value = linkedEquipment.Counter2Value();
                        return data;
                    });
            }

            function dataToSend() {
                //if no employee has been selected, make the changes on the current employee
                self.dataDto([]);
                if (self.employees().length === 0) {
                    self.employees().push({
                        id: self.isCreationMode ? self.employee().Id : self.employeeIdToEdit()
                    });
                }

                //initialize incremental days in edition Mode
                if (!self.isCreationMode) {
                    self.validatedForm().bonusSelectedDays([]);
                    self.validatedForm().employeeSelectedDays([]);
                    self.validatedForm().bonusSelectedDays().push(0);
                    self.validatedForm().employeeSelectedDays().push(0);
                }

                var workTime = self.validatedForm().totalHours();

                if (self.showBonus()) {
                    getBonus();
                } else {
                    if (self.validatedForm().hours_activity()) {
                        if (!self.isCreationMode) {
                            getHours(workTime);
                        } else {
                            getHoursUnified();
                        }
                    }
                    if (self.validatedForm().equipment()) {
                        self.dataDto.push.apply(self.dataDto, getEquipment(workTime));
                    }
                }
                return self.dataDto();
            }

            //this help to check trade of the concerned employee
            function concernedEmployee() {
                if (self.isCreationMode) {
                    if (self.employees().length === 0) {
                        return self.employee().Id;
                    } else if (self.employees().length === 1) {
                        return self.employees()[0].id;
                    }
                }

                return self.employeeIdToEdit();
            }

            function checkDirty() {
                var x = self.validatedForm();
                if (x.hours_activity() ||
                    x.equipment_activity() ||
                    x.bonus_activity() ||
                    x.equipment() ||
                    x.totalHours() !== 0 ||
                    x.bonus() ||
                    x.quantity() ||
                    (x.employeeSelectedDays().length !== 0) ||
                    (x.bonusSelectedDays().length !== 0)) {
                    return true;
                } else {
                    return false;
                }
            }

            function clearData() {
                var x = self.validatedForm();

                x.trade(null);
                x.group(null);
                x.hours_activity(null);
                x.equipment_activity(null);
                x.bonus_activity(null);
                x.equipment(null);
                x.totalHours(0);
                x.bonus(null);
                x.quantity(0);
                x.shift(0);
                x.comment("");
                x.wageType(enumHelper.wageType().SIMPLE);
                x.equipmentGroup(null);
                x.bonusGroup(null);
                x.employeeSelectedDays([]);
                x.bonusSelectedDays([]);

                self.selectedHours(self.emptyDate());
                self.selectedRegularTime(self.emptyDate());
                self.selectedHalfTime(self.emptyDate());
                self.selectedDoubleTime(self.emptyDate());

            }

            function updateTotalHours() {
                if (self.isCreationMode) {
                    self.totalHours = self.regularTime + self.halfTime + self.doubleTime;
                } else {
                    self.totalHours = getWorkTime(self.selectedHours());
                }

                self.validatedForm().totalHours(self.totalHours);
                updateEndTime(self);
            }

            function updateEndTime(self) {
                if (self.startTime !== "") {
                    self.endTime = getEndTime(self.startTime, self.totalHours);
                    self.selectedEndTime(getDateTime(self.endTime));
                }
            }

            function getEndTime(strStartTime, strDurationInHours) {
                return dateHelper.getTime(dateHelper.addHour(getDateTime(strStartTime), strDurationInHours));
            }

            function getDateTime(time) {
                let dateTime = new Date();
                dateTime.setHours(time.split(":")[0]);
                dateTime.setMinutes(time.split(":")[1]);
                return dateTime;
            }

            function initializeSubscription() {
                self.subscriptions.push(
                    // On empêche les retours de ligne car ceux-ci ne sont pas affichables dans maestro*
                    self.validatedForm().comment.subscribe(function () {
                        self.validatedForm().comment(self.validatedForm().comment().replace(/\n/g, ""));
                    })
                );

                // Puisqu'on est pas capable de relier un événement sur le dateTimePicker(onSelect),
                // on subscribe mannuellement sur 3 types de temps de travail.
                // Mode unifié en création et mode standard en modification

                self.subscriptions.push(
                    self.selectedStartTime.subscribe(function (newValue) {
                        if (newValue && newValue !== " ") {
                            self.startTime = dateHelper.formatDate("HH:mm", newValue);
                            updateTotalHours();
                        }
                    }));

                if (self.isCreationMode) {
                    self.subscriptions.push(
                        self.selectedRegularTime.subscribe(function (newValue) {
                            if (newValue) {
                                self.regularTime = getWorkTime(newValue);
                                updateTotalHours();
                            }
                        }));

                    self.subscriptions.push(
                        self.selectedHalfTime.subscribe(function (newValue) {
                            if (newValue) {
                                self.halfTime = getWorkTime(newValue);
                                updateTotalHours();
                            }
                        }));

                    self.subscriptions.push(
                        self.selectedDoubleTime.subscribe(function (newValue) {
                            if (newValue) {
                                self.doubleTime = getWorkTime(newValue);
                                updateTotalHours();
                            }
                        }));
                } else {
                    self.subscriptions.push(
                        self.selectedHours.subscribe(function (newValue) {
                            if (newValue) {
                                self.validatedForm().totalHours(getWorkTime(newValue));
                                self.totalHours = self.validatedForm().totalHours();
                                self.validatedForm().totalHours(self.totalHours);
                                if (self.startTime !== "") {
                                    self.endTime = getEndTime(self.startTime, self.totalHours);
                                }
                            }
                        }));
                }
            }

            function clearSubscription() {
                _.each(self.subscriptions,
                    function (s) {
                        s.dispose();
                    });
            }

            //#endregion
            //#region public functions
            ctor.prototype.activate = async function (routeParameters, route, navInstruction) {
                bindViewModel(routeParameters.timesheetId, routeParameters.timesheetEntryId, routeParameters.q);
                await getAllowedExpenseCompanySelection();
                self.validatedForm().clear();
                return loadData();
            };

            ctor.prototype.deactivate = function () {
                clearSubscription();
            };

            ctor.prototype.attached = function () {
                initializeSubscription();
            };

            ctor.prototype.compositionCompleteCallback = function () {
                initDropDownLists();
                window.scrollTo(0, 1);
            };

            ctor.prototype.emptyDate = function () {
                return dateHelper.getDate().toISOString();
            };

            ctor.prototype.wageTypeClicked = function (id) {
                self.validatedForm().wageType(id);
            };

            ctor.prototype.onSelectBonus = async function (selectedItem) {
                await loadDefaultActivityForBonus();
                self.unit(selectedItem.data.Unit);
            };

            ctor.prototype.onUnSelectBonus = function () {
                self.unit("");
            };

            ctor.prototype.show = async function (section) {
                if (checkDirty()) {
                    const success = await notificationHelper.showDialogYesNo("", resx.localize("DoYouWantToSave"), false, {
                        thirdButton: true,
                        otherLabel: resx.localize("Cancel")
                    });

                    if (success === true) {
                        self.save(false);
                    } else if (success === false) {
                        clearData();
                        self.showBonus(section === "bonus");
                    }

                } else {
                    self.showBonus(section === "bonus");
                }
            };

            ctor.prototype.removeEmployee = async function (item) {
                const success = await notificationHelper.showConfirmation(resx.localize('msg_DeleteEmployeeConfirmation'));

                if (success) {
                    self.employees.remove(item);

                    if (item.data.Id !== self.employee().Id) {
                        self.initialList.remove(item);
                    }

                    loadLinkedEquipmentsCountersInfos();

                    const defaultActivityPromise = loadDefaultActivityForLabor();
                    const defaultTradePromise = loadDefaultTrade();
                    await Promise.all([defaultActivityPromise, defaultTradePromise]);
                }
            };

            ctor.prototype.existInSelect = function (id) {
                return _.contains(self.selectedDays(), id);
            };

            ctor.prototype.addEmployees = async function () {
                var tempList = [];
                tempList.push(...self.employeesD());

                var selectionWithoutSelf = _.reject(tempList, (item) => { return item.data.Id === self.employee().Id });
                self.initialList(_.union(self.initialList(), selectionWithoutSelf));
                self.employees(tempList);

                loadLinkedEquipmentsCountersInfos();

                const defaultActivityPromise = loadDefaultActivityForLabor();
                const defaultTradePromise = loadDefaultTrade();
                await Promise.all([defaultActivityPromise, defaultTradePromise]);
            };

            ctor.prototype.onEquipmentSelected = async function () {
                await loadDefaultActivityForEquipment();
                loadEquipmentCountersInfos(self.validatedForm().equipment().id);
            };

            ctor.prototype.onProjectSelected = async function () {
                const defaultActivityForEmployeePromise = loadDefaultActivityForLabor();
                const defaultActivityForEquipmentPromise = loadDefaultActivityForEquipment();
                const defaultActivityForBonusPromise = loadDefaultActivityForBonus();

                await Promise.all([defaultActivityForEmployeePromise, defaultActivityForEquipmentPromise, defaultActivityForBonusPromise]);
            }

            ctor.prototype.onCompanySelected = async function () {
                self.validatedForm().project(null);
            }

            ctor.prototype.save = function (navigateBack) {
                if ((self.employees().length === 0) && self.isResponsibleForTimeEntry && self.isTeamLeader && self.isCreationMode) {
                    notificationHelper.showWarning(self.resx.localize("YouNeedToSelectAnEmployee!"), resx.localize("ValidationError"));
                } else {
                    if (!self.validatedForm.isValid()) {
                        notificationHelper.showValidationError(self.validatedForm.errors);
                    } else {
                        routerHelper.showLoading();

                        timesheetService.setEntriesForTimesheet(dataToSend())
                            .done(function () {
                                settingRepository.setTimesheetDefaultValues(JSON.stringify({
                                    "employees": self.employees(),
                                    "currentPeriod": self.payPeriodStart()
                                }));
                                //empty initialised list
                                self.initialList([]);
                                clearData();
                                if (navigateBack) {
                                    self.router.navigateBack();
                                } else {
                                    self.show(self.showBonus() ? 'time' : 'bonus');
                                }
                            })
                            .always(function () {
                                routerHelper.hideLoading();
                            });
                    }
                }
            };
            //#endregion
            return ctor;
        })();

        return viewModel;
    });