define([
    "knockout",
    "helpers/queryStringHelper",
    "services/contactService",
     "widgets/pages/contact"
], function (ko, queryStringHelper, contactService) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        function ctor() {
            self = this;
            //#region Properties
            self.actions = {};
            self.contacts = ko.observableArray();
            self.dispatchProjectCode = ko.observable();
            self.isProjectResponsible = false;
            //#endregion
        }

        //#region Private Functions
        function bindViewModel(dispatchProjectCode, queryString) {
            self.isProjectResponsible = queryStringHelper.parseIsProjectResponsible(queryString);

            self.dispatchProjectCode(dispatchProjectCode);
        }

        function bindWidget() {
            self.actions = {
                getContacts: contactService.getLookupByDispatchProjectCode.bind(null, self.dispatchProjectCode())
            };
        }
        //#endregion

        //#region Public Functions
        ctor.prototype.activate = function (params) {
            bindViewModel(params.dispatchProjectCode, params.q);
            bindWidget();
        };
        //#endregion


        return ctor;
    })();

    return viewModel;
});
