export class MaCardOptionsModel {
    public id?: any;
    public model?: any;

    public leftSectionClass?: string;
    public leftSectionIcon?: string;
    public leftSectionText?: string[];
    public leftSectionAction?: () => any;

    public url?: string;
    public class?: string;
    public cardBodyClass?: string;
    public action?: () => any;

    public displayLines!: MaCardLineModel[];
    public actionItems?: MaCardActionModel[] = [];
    public rightInfos?: MaCardRightInfoModel[] = [];

    public isSelected?: boolean = false;
    public isSelectable?: boolean = false;
    public selectAction?: () => any;

    constructor(maCardOptionsModel?: MaCardOptionsModel) {
        Object.assign(this, maCardOptionsModel);
    }
}

export class MaCardLineModel {
    public id?: string;
    public display?: string;
    public icon?: string;
    public class?: string;
    public isCustomTemplate?: boolean;
    public splitDisplays?: MaCardSplitDisplayModel[];

    public textAction?: () => any;

    constructor(maCardLinesModel?: MaCardLineModel) {
        this.isCustomTemplate = false;

        Object.assign(this, maCardLinesModel);
    }
}

export class MaCardActionModel {
    public id?: any;
    public icon?: string;
    public action?: () => any;

    constructor(maCardActionsModel?: MaCardActionModel) {
        Object.assign(this, maCardActionsModel);
    }
}

export class MaCardRightInfoModel {
    public display?: string;
    public class?: string;

    constructor(maCardRightInfoModel?: MaCardRightInfoModel) {
        Object.assign(this, maCardRightInfoModel);
    }
}

export class MaCardSplitDisplayModel {
    public display?: string;
    public icon?: string;
    public class?: string;

    constructor(maCardSplitDisplayModel?: MaCardSplitDisplayModel) {
        Object.assign(this, maCardSplitDisplayModel);
    }
}
