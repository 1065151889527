define([
    "knockout",
    "core/resx",
    "jquery",
    "underscore",
    "helpers/dateHelper",
    "helpers/labelHelper",
    "helpers/notificationHelper",
    "helpers/queryStringHelper",
    "helpers/routerHelper",
    "moment",
    "services/bonusService",
    "services/dailyEntryService",
    "services/dispatchService",
    "services/dispatchTimesheetService",
    "services/tradesService",
    "services/templateService"
], function (ko, resx, jQuery, _, dateHelper, labelHelper, notificationHelper, queryStringHelper, routerHelper, moment, bonusService, dailyEntryService, dispatchService, dispatchTimesheetService, tradesService, templateService) {
    "use strict";

    var PAUSE_ACTIVITY_CODE = "PAUSE";

    var viewModel = (function () {
        var self = null;

        function ctor() {
            self = this;

            //#region Properties
            self.resx = resx;

            self.bonuses = ko.observableArray();
            self.dispatchProjectCode = ko.observable();
            self.dispatchDate = ko.observable();
            self.dispatchId = ko.observable();
            self.dispatch = ko.observable({});
            self.empFullName = ko.observable();
            self.empId = ko.observable();
            self.equipmentCode = ko.observable();
            self.equipmentDescription = ko.observable();
            self.hasLeft = ko.observable();
            self.hasLeftCssClass = ko.observable();
            self.inactive = ko.observable();
            self.inactiveCssClass = ko.observable();
            self.isProjectResponsible = false;
            self.linkedEquipmentCode = ko.observable();
            self.linkedEquipmentDescription = ko.observable();
            self.linkedEquipments = ko.observable();
            self.PAUSE_ACTIVITY_CODE = PAUSE_ACTIVITY_CODE;
            self.readonly = true;
            self.requiredTomorrow = ko.observable();
            self.requiredTomorrowCssClass = ko.observable();
            self.timeEntries = ko.observableArray();
            self.trade = ko.observable();
            self.tradeCode = ko.observable();
            self.tradeDescription = ko.observable();
            self.dispatchTemplateID = "";
            self.defaultEquipmentActivityCode = "";
            self.defaultLaborActivityCode = "";
            self.isEmployee = ko.pureComputed(function() {
                return self.empId() !== 0;
            });

            self.hasEquipment = ko.pureComputed(function() {
                return !!self.linkedEquipmentCode();
            });

            self.tradeText = ko.pureComputed(function() {
                return self.tradeCode()
                    ? self.tradeCode() + ' - ' + self.tradeDescription()
                    : resx.localize("SelectATrade");
            });
            //#endregion
        }

        //#region Private Functions
        function bindViewModel(dispatchProjectCode, dispatchDate, dispatchId, queryString) {
            self.isProjectResponsible = queryStringHelper.parseIsProjectResponsible(queryString);

            self.dispatchTemplateID = routerHelper.getQuerystring(queryString).DispatchTemplateID;
            self.dispatchProjectCode(dispatchProjectCode);
            self.dispatchDate(dispatchDate);
            self.dispatchId(dispatchId);
        }

        function loadData() {
            var timeSheets = dispatchTimesheetService.getByDispach(self.dispatchId());
            self.dispatch(dailyEntryService.get(self.dispatchProjectCode(), self.dispatchDate(), getTimeEntryMode(), getInChargeRequireIndicateWhenEmployeeLeftWorksite(), getAllowEquipmentTimeEntryForSimpleUser()));

            return jQuery.when(timeSheets, self.dispatch()).done(initialize);
        }

        function getCssClassForCheck(checked, context) {
            var val = checked ? 'checked ' : '';

            if (self.readonly === true) {
                val += 'disabled ';
                return val;
            }

            if (context === 'hasLeft') {
                var atLeastOneActivity = self.timeEntries().length > 0 || self.bonuses().length>0,
                    hasEndDate = !_.some(self.timeEntries(), function (x) {
                        return !x.EndTime;
                    }),
                    enableHasLeft = atLeastOneActivity && hasEndDate && !self.inactive();

                val += enableHasLeft ? '' : 'disabled ';
            }

            return val;
        }

        function getDefaultQueryStringParameters() {
            var params = {};

            params.isProjectResponsible = self.isProjectResponsible;
            params.readonly = self.readonly;

            return params;
        }

        function initialize(data, dispatch) {
            self.readonly = dispatch.IsReadOnly;

            self.empId(data.EmployeeNumber);
            self.empFullName(data.EmployeeFirstName + " " + data.EmployeeLastName);
            self.equipmentCode(data.EquipmentCode);
            self.equipmentDescription(data.EquipmentDescription);

            self.tradeCode(data.TradeCode);
            self.tradeDescription(data.TradeDescription);

            self.linkedEquipmentCode(data.LinkedEquipmentCode);
            self.linkedEquipmentDescription(data.LinkedEquipmentDescription);
            self.linkedEquipments(data.LinkedEquipments);

            loadTimeEntries(data.TimeEntries);

            self.bonuses(data.Bonuses);

            self.hasLeft(data.HasLeft);
            self.inactive(data.IsInactive);
            self.requiredTomorrow(data.RequiredTomorrow);

            self.hasLeftCssClass(getCssClassForCheck(self.hasLeft(), 'hasLeft'));
            self.requiredTomorrowCssClass(getCssClassForCheck(self.requiredTomorrow(), 'requiredTomorrow'));
            self.inactiveCssClass(getCssClassForCheck(self.inactive(), 'inactive'));
            self.defaultEquipmentActivityCode = data.DefaultEquipmentActivityCode;
            self.defaultLaborActivityCode = data.DefaultLaborActivityCode;
        }

        function loadTimeEntries(timeEntries) {
            self.timeEntries(_.map(timeEntries,
                   function (timeEntry) {
                       timeEntry.wageText = labelHelper.getWageTypeLabel(timeEntry.WageType);

                       if (timeEntry.EndTime) {
                           timeEntry.formattedTime = dateHelper.getTime(timeEntry.StartDateTime) + " - " + dateHelper.getTime(timeEntry.EndDateTime);
                           timeEntry.hoursWorked = dateHelper.getTimeSpan(timeEntry.StartDateTime, timeEntry.EndDateTime);
                       } else {
                           timeEntry.formattedTime = dateHelper.getTime(timeEntry.StartDateTime) + " - ...";

                           var diff = moment(dateHelper.getNow()).diff(moment(timeEntry.StartDateTime));
                           timeEntry.hoursWorked = diff > 0 ? dateHelper.getTimeSpan(timeEntry.StartDateTime, dateHelper.getNow()) : "0:00";
                       }
                       return timeEntry;
                   }));
        }

        function setInactive() {
            routerHelper.showLoading();
            self.inactiveCssClass(getCssClassForCheck(self.inactive(), 'inactive'));

            dispatchService.setInactive(self.dispatchId(), !self.inactive())
                .done(function (data) {
                    // TODO: KG - self.dispatch() retourne un deferred, le self.readonly sera undefined
                    initialize(data, self.dispatch());
                })
                .always(function () {
                    self.inactiveCssClass(getCssClassForCheck(self.inactive(), 'inactive'));
                    routerHelper.hideLoading();
                });
        }

        function getTimeEntryMode() {
            return templateService.getCurrentTemplateConfigs().ProjectTimeEntryMode;
        }

        function getInChargeRequireIndicateWhenEmployeeLeftWorksite() {
            return templateService.getCurrentTemplateConfigs().InChargeRequireIndicateWhenEmployeeLeftWorksite;
        }

        function getAllowEquipmentTimeEntryForSimpleUser() {
            return templateService.getCurrentTemplateConfigs().AllowEquipmentTimeEntry;
        }
        //#endregion

        //#region Public Functions
        ctor.prototype.activate = function (params) {
            bindViewModel(params.dispatchProjectCode, params.dailyEntryDate, params.dispatchId, params.q);
            return loadData();
        };

        ctor.prototype.deleteBonus = function (bonusId) {
            notificationHelper.showDeleteConfirmation()
                .done(function (success) {
                    if (success) {
                        routerHelper.showLoading();
                        bonusService.deleteBonus(bonusId)
                            .done(function () {
                                loadData(self);
                            })
                            .always(function () {
                                routerHelper.hideLoading();
                            });
                    }
                });
        };

        ctor.prototype.addActivity = function (timeEntryId) {
            routerHelper.navigate(self.getAddActivityUrl(timeEntryId));
        };

        ctor.prototype.deleteTimeEntry = function (timeEntryId) {
            notificationHelper.showDeleteConfirmation()
                .done(function (success) {
                    if (success) {
                        routerHelper.showLoading();
                        dispatchTimesheetService.deleteTimeEntry(timeEntryId)
                            .done(function () {
                                loadData();
                            })
                            .always(function () {
                                routerHelper.hideLoading();
                            });
                    }
                });
        };

        ctor.prototype.getAddActivityUrl = function (id) {
            var params = getDefaultQueryStringParameters();
            params.dispatchIds = JSON.stringify([{ "id": self.dispatchId(), "isEmployee": self.isEmployee(), "DispatchTemplateID": self.dispatchTemplateID, "DefaultEquipmentActivityCode": self.defaultEquipmentActivityCode, "DefaultLaborActivityCode": self.defaultLaborActivityCode }]);

            var queryString = routerHelper.addQuerystring(params);
            var url = id
                ? routerHelper.navigateTo("Project_Detail_Daily_Entry_TimeEntry_Edit", self.dispatchProjectCode(), self.dispatchDate(), id)
                : routerHelper.navigateTo("Project_Detail_Daily_Entry_TimeEntry_Edit", self.dispatchProjectCode(), self.dispatchDate());
            return url + queryString;
        };

        ctor.prototype.getAddBonusUrl = function (id) {
            var params = getDefaultQueryStringParameters();
            params.dispatchIds = [self.dispatchId()].join();

            var queryString = routerHelper.addQuerystring(params);
            var url = id
                ? routerHelper.navigateTo("Project_Detail_Daily_Entry_TimeEntry_Bonus", self.dispatchProjectCode(), self.dispatchDate(), id)
                : routerHelper.navigateTo("Project_Detail_Daily_Entry_TimeEntry_Bonus", self.dispatchProjectCode(), self.dispatchDate());

            return url + queryString;
        };

        ctor.prototype.getDeleteVisible = function (index) {
            if (self.readonly) {
                return false;
            }

            //JL 13/06/2016: Fix un bug ou la supression d'un pause au milieu d'une journée ne fait rien.
            // On empêche donc la supression d'une pause si elle est entre deux activités qui ne sont pas des pauses.

            var curIsBreak = self.timeEntries()[index].ActivityCode === PAUSE_ACTIVITY_CODE;

            if (curIsBreak) {
                var hasPrevActivity = index > 0 && self.timeEntries()[index - 1].ActivityCode !== PAUSE_ACTIVITY_CODE,
                    hasNextActivity = index + 1 < self.timeEntries().length && self.timeEntries()[index + 1].ActivityCode !== PAUSE_ACTIVITY_CODE;

                return !(hasPrevActivity && hasNextActivity);
            }

            return true;
        };

        ctor.prototype.getTrades = {
            transport: function (params, success, failure) {
                tradesService.getTradesForEmployee(self.empId(), params.data.filter, params.data.page || 1)
                    .done(function (data) {
                        return success(_.map(data, function (x) {
                            x.Id = x.Code;
                            return x;
                        }));
                    })
                    .fail(failure);
            }
        };

        ctor.prototype.onTradeSelect = function () {
            routerHelper.showLoading();

            tradesService.setTradeForDispatch(self.dispatchId(), self.trade() ? self.trade().id : 0)
               .done(function (data) {
                   self.tradeCode(data.Code);
                   self.tradeDescription(data.Description);
               })
               .always(function () {
                   routerHelper.hideLoading();
               });
        };

        ctor.prototype.returnPresent = function () {
            self.hasLeftCssClass(getCssClassForCheck(self.hasLeft(), 'hasLeft'));

            dispatchService.setHasLeft(self.dispatchId(), !self.hasLeft())
                .done(function (data) {
                    self.hasLeft(!self.hasLeft());
                })
                .always(function () {
                    self.hasLeftCssClass(getCssClassForCheck(self.hasLeft(), 'hasLeft'));
                });
        };

        ctor.prototype.inactiveClicked = function () {
            if (self.timeEntries().length > 0) {
                notificationHelper.showConfirmation(resx.localize('msg_AbsentConfirmationEquipment'))
                    .done(function (success) {
                        if (success) {
                            setInactive();
                        }
                    });
            } else {
                setInactive();
            }
        };

        ctor.prototype.navigateToEmployee = function () {
            var url = routerHelper.navigateTo("Project_Detail_Daily_Entry_Employee", self.dispatchProjectCode(), self.dispatchDate(), self.empId(), self.dispatchId());
            url += routerHelper.addQuerystring(getDefaultQueryStringParameters());

            routerHelper.navigate(url);
        };

        ctor.prototype.requiredTomorowClicked = function () {
            routerHelper.showLoading();
            self.requiredTomorrowCssClass(getCssClassForCheck(self.requiredTomorrow(), 'requiredTomorrow'));

            dispatchService.setRequiredTomorrow(self.dispatchId(), !self.requiredTomorrow())
                .done(function (data) {
                    self.requiredTomorrow(!self.requiredTomorrow());
                })
                .always(function () {
                    self.requiredTomorrowCssClass(getCssClassForCheck(self.requiredTomorrow(), 'requiredTomorrow'));
                    routerHelper.hideLoading();
                });
        };
        //#endregion

        return ctor;
    })();

    return viewModel;
});
