define([
    "jquery",
    "knockout",
    "core/resx",
    "helpers/dateHelper",
    "helpers/labelHelper",
    "helpers/notificationHelper",
    "helpers/routerHelper",
    "helpers/stringHelper",
    "moment",
    "services/materialRequisitionService",
    "widgets/pageBottom",
    "select2"
], function (jQuery, ko, resx, dateHelper, labelHelper, notificationHelper, routerHelper, stringHelper, moment, materialRequisitionService) {
    "use strict";

    var widget = (function () {
        var self = null;

        function ctor() {
            self = this;

            //#region Properties
            self.resx = resx;
            self.dateHelper = dateHelper;
            self.routerHelper = routerHelper;

            self.actions = {
                getMaterialRequisition: function () { },
                saveMaterialRequisitionService: function (requisition) { },
                setSpecificFields: function (item) { return item; }
            };
            self.dateEntered = ko.observable();
            self.dateRequired = ko.observable();
            self.isNew = ko.observable();
            self.readonly = ko.observable();
            self.requisition = ko.observable({});
            self.requisitionId = ko.observable();
            self.status = ko.observable();

            // Computed
            self.addUrl = ko.computed(function () {
                const date = dateHelper.dateToNavigationParameter(self.dateRequired()); //self.dateHelper.getVeryShortDateLocal
                let temp = self.routerHelper.getRelativeUrl('');

                if (stringHelper.endsWith(temp, "edit")) {
                    temp += "/"
                    temp += self.requisitionId();
                    temp += "/item/";
                    temp += date;

                    return temp;
                }
                return self.routerHelper.getRelativeUrl('item', date);
            });
            self.addProjectResourceUrl = ko.computed(function(){
                const date = dateHelper.dateToNavigationParameter(self.dateRequired());
                let temp = self.routerHelper.getRelativeUrl('');

                if (stringHelper.endsWith(temp, "edit")) {
                    temp += "/"
                    temp += self.requisitionId();
                    temp += "/projectResource/";
                    temp += date;

                    return temp;
                }
                return self.routerHelper.getRelativeUrl('projectResource', date);
            })

            self.btnText = ko.computed(function () {
                return self.isNew() ? resx.localize("Add") : resx.localize("Save");
            });
            self.requisitionIdDisplay = ko.computed(function () {
                return self.isNew() ? resx.localize('New_F') : self.requisitionId();
            });
            self.reqItemsVisible = ko.computed(function () {
                return !self.isNew() && self.requisition().Items && self.requisition().Items.length > 0;
            });
            self.txtNoReqItem = self.isNew() ? resx.localize('lstNoData_RequisitionItemUnsaved') : resx.localize('lstNoData_RequisitionItem');

            self.isAddItemDisabled = ko.computed(function () {
                return self.readonly() || self.isNew();
            });
            self.isAddResourceDisabled = ko.computed(function () {
                return self.isAddItemDisabled() || !self.requisition().HasResource;
            });
            //#endregion
        }

        //#region Private Methods
        function bindViewModel(widgetSettings) {
            self.actions = widgetSettings.actions;
            self.requisitionId(widgetSettings.requisitionId === null ? "-1" : widgetSettings.requisitionId);
            self.widgetSettingsReadonly = widgetSettings.readonly;

            self.isFromProjectModule = !!widgetSettings.dispatchProjectCode;
        }

        function loadData() {
            return self.actions.getMaterialRequisition().done(function (data) {
                self.requisition(data);

                //Init values for new requisition
                if (self.requisition().Id === null) {
                    initValuesForNewRequisition(self);
                } else {
                    self.isNew(false);
                }

                updateself(self);
                initDropdown(self);
            });
        }

        function initDropdown() {
            jQuery("#ddlStatus").select2({
                minimumResultsForSearch: 999,
                width: "100%",
                language: labelHelper.getDefaultSelect2Labels(),
                data: [
                    { text: resx.localize('Ongoing'), id: 0 },
                    { text: resx.localize('Completed_F'), id: 1 }
                ]
            })
                .on("change", function (e) {
                    self.status(e.target.value);
                })
                .val(self.status()).trigger("change");
        }

        function initValuesForNewRequisition() {
            self.isNew(true);

            self.requisition().MobileStatus = "0";
            self.requisition().DateEntered = dateHelper.getVeryShortDateLocal(moment());
            self.requisition().RequiredDate = dateHelper.getVeryShortDateLocal(moment());
            self.requisition().Prefix = '';
            self.requisition().UserNo = -1;
            self.requisition().Items = [];
        }

        function updateself() {
            self.status(self.requisition().MobileStatus);
            self.dateEntered(self.requisition().DateEntered);
            self.dateRequired(self.requisition().RequiredDate);

            self.readonly((self.requisition().MobileStatus === "1") || self.widgetSettingsReadonly);
        }
        //#endregion

        //#region Public Methods
        ctor.prototype.activate = function (widgetSettings) {
            bindViewModel(widgetSettings);
            return loadData();
        };

        ctor.prototype.compositionCompleteCallback = function () {
            initDropdown(self);
        };

        ctor.prototype.deleteItem = function (item) {
            notificationHelper.showDeleteConfirmation().done(function (success) {
                if (success) {
                    routerHelper.showLoading();
                    materialRequisitionService.delMaterialRequisitionItem(item.Id, item.Line)
                        .done(function () {
                            loadData(self);
                        }).always(function () {
                            routerHelper.hideLoading();
                        });
                }
            });
        };

        ctor.prototype.genUrl = function (line) {
            return routerHelper.getRelativeUrl('item', dateHelper.dateToNavigationParameter(self.dateRequired()), line) + routerHelper.addQuerystring({ readonly: self.readonly() });
        };

        ctor.prototype.saveRequisition = function () {
            self.requisition().DateEntered = self.dateEntered();
            self.requisition().RequiredDate = self.dateRequired();
            self.requisition().MobileStatus = self.status();
            self.requisition().Id = self.requisitionId() === "-1" ? null : self.requisitionId();

            self.requisition(self.actions.setSpecificFields(self.requisition()));

            routerHelper.showLoading();

            self.actions.saveMaterialRequisitionService(self.requisition()).done(function (data) {
                self.requisitionId(data.Id);

                if (self.isNew()) {
                    self.isNew(false);

                    //The navigation doesn't work (doesn't trigger activate or other events), but it at least add the parameter in the URL
                    routerHelper.navigate(routerHelper.getRelativeUrl(encodeURIComponent(data.Id)), { replace: true, trigger: true });
                } else {
                    routerHelper.navigateBack();
                }
            }).always(function () {
                routerHelper.hideLoading();
            });
        };
        //#endregion

        return ctor;
    })();

    return widget;
});
