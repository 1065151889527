import { default as recommendationService } from "services/recommendationService";
import { default as routerHelper } from "helpers/routerHelper";
import { default as queryStringHelper } from "helpers/queryStringHelper";
import "widgets/autoShowAddButton";

export class Recommendation {
    workOrderId;
    fetchRecommendations;
    readonly = false;

    constructor() {
    }

    activate(params) {
        this.bindViewModel(params.workOrderId, params.q);
    }

    bindViewModel(workOrderId, querystring) {
        const params = routerHelper.getQuerystring(querystring);
        if (params.readonly) {
            this.readonly = queryStringHelper.parseReadonly(querystring);
        }
        this.workOrderId = workOrderId;
        this.fetchRecommendations = async (status, page) => await recommendationService.getRecommendationsForWorkOrder(workOrderId, status, page).promise();
    }
}
