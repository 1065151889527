import { PLATFORM } from "aurelia-framework";

define([
    "knockout",
    "jquery",
    "underscore",
    "helpers/dateHelper",
    "helpers/labelHelper",
    "helpers/routerHelper",
    "helpers/notificationHelper",
    "core/resx"
], function (ko, jQuery, _, dateHelper, labelHelper, routerHelper, notificationHelper, resx) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        PLATFORM.moduleName("pages/templates/hoursSummary_SingleProject.html");
        PLATFORM.moduleName("pages/templates/hoursSummary_MultipleProject.html");
        PLATFORM.moduleName("pages/templates/hoursSummary_ProjectSection.html");

        function ctor() {
            self = this;

            //#region Properties
            self.dateHelper = dateHelper;
            self.labelHelper = labelHelper;
            self.resx = resx;
            self.selectedDate = "";
            self.list = ko.observableArray([]);

            self.listCompleted = ko.computed(function() {
                return _.filter(self.list(),
                    function(entry) {
                        return entry.IsQuit;
                    });
            });

            self.listUnCompleted = ko.computed(function() {
                return _.filter(self.list(),
                    function(entry) {
                        return !entry.IsQuit && entry.IsInProgress;
                    });
            });
            self.isSingle = ko.observable(false);
            //#endregion
        }
        //#region Private Functions

        function bindViewModel(widgetOptions) {
            self.root = widgetOptions.root;
            self.isSingle(widgetOptions.single || false);
            self.list(widgetOptions.list);

            self.selectedDate = widgetOptions.selectedDate;
            if (_.isFunction(self.selectedDate)) {
                self.selectedDate = self.selectedDate();
            }
        }
        //#endregion

        //#region Public Functions
        ctor.prototype.activate = function (widgetOptions) {
            bindViewModel(widgetOptions);
        };

        ctor.prototype.genEmployeeDispatchUrl = function (dispatchProjectCode, dispatchId, dispatchDate, readonly) {
            return routerHelper.navigateTo("Project_Detail_Daily_Entry_TimeEntryEmployee", dispatchProjectCode, dateHelper.formatDate("YYYY-MM-DD", dispatchDate, true), dispatchId) + routerHelper.addQuerystring({ isProjectResponsible: true, readonly: readonly });
        };
        //#endregion

        return ctor;
    })();

    return viewModel;
});
