import { transient } from "aurelia-framework";

import { default as resx } from "core/resx";
import { default as routerHelper } from "helpers/routerHelper";

@transient()
export class ConsultHoursWorkedButton {
    constructor() {
        this.resx = resx;
        this.url = "";
    }

    activate(widgetOptions) {
        this.url = routerHelper.navigateTo("HoursWorked");
    }
}
