define([
    "knockout",
    "core/resx",
    "helpers/routerHelper"
], function (ko, resx, routerHelper) {
    "use strict";

    var widget = (function () {
        var self = null;

        function ctor() {
            self = this;

            //#region Properties
            self.resx = resx;
            self.routerHelper = routerHelper;

            self.actions = {
                getContacts: function() {}
            };
            self.contacts = ko.observableArray();
            self.dispatchId = ko.observable();
            self.serviceType = '';
            self.querystringToAdd = '';
            self.readonly = false;
            //#endregion
        }

        //#region Private Methods
        function bindViewModel(widgetSettings) {
            self.actions = widgetSettings.actions;
            self.readonly = widgetSettings.readonly;
            self.querystringToAdd = routerHelper.addQuerystring({readonly: widgetSettings.readonly});
        }

        function loadData() {
            return self.actions.getContacts().done(function(data) {
                self.contacts(data);
            });
        }
        //#endregion

        //#region Public Methods
        ctor.prototype.activate = function (widgetSettings) {
            bindViewModel(widgetSettings);
            return loadData();
        };
        //#endregion

        return ctor;
    })();

    return widget;
});
