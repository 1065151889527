define([
    "knockout",
    "helpers/queryStringHelper",
     "services/materialRequisitionService"
], function (ko, queryStringHelper, materialRequisitionService) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        function ctor() {
            self = this;
            //#region Properties
            self.actions = {};
            self.dispatchProjectCode = ko.observable();
            self.requisitionId = ko.observable();
            self.isProjectResponsible = false;
            //#endregion
        }

        //#region Private Function
        function bindViewModel(dispatchProjectCode, requisitionId, queryString) {
            self.isProjectResponsible = queryStringHelper.parseIsProjectResponsible(queryString);

            self.dispatchProjectCode(dispatchProjectCode);
            self.requisitionId(requisitionId);
        }

        function bindWidget() {
            self.actions = {
                getMaterialRequisition: materialRequisitionService.getMaterialRequisition.bind(self, self.requisitionId()),
                saveMaterialRequisitionService: materialRequisitionService.setMaterialRequisitionHeaderForProject.bind(self, self.dispatchProjectCode()),
                setSpecificFields: setSpecificFields
            };
        }

        function setSpecificFields(item) {
            item.dispatchProjectCode = self.dispatchProjectCode();
            return item;
        }
        //#endregion


        //#region Public Functions
        ctor.prototype.activate = function (params) {
            bindViewModel(params.dispatchProjectCode, params.requisitionId, params.q);
            bindWidget();
        };
        //#endregion

        return ctor;
    })();

    return viewModel;
});
