import { transient } from "aurelia-framework";

import { default as ko } from "knockout";
import { default as _ } from "underscore";
import { default as resx } from "core/resx";

@transient()
export class StartEndTimeTemplate {
    constructor() {
        //#region Properties
        this.resx = resx;
        this.startHour = ko.observable();
        this.endHour = ko.observable();
        this.readonly = false;
        //#endregion
    }

    //#region Public Functions
    activate(settings) {
        this.startHour = settings.templateContext.startHour;
        this.endHour = settings.templateContext.endHour;
        this.readonly = settings.readonly || false;

    }
}