define([
    "knockout",
    "core/resx",
    "helpers/routerHelper",
    "services/dispatchService",
    "helpers/enumHelper",
    "widgets/projectHeader",
    "widgets/predefinedSentences"
], function (ko, resx, routerHelper, dispatchService, enumHelper) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        function ctor() {
            self = this;
            //#region Properties
            self.resx = resx;

            self.dispatchDate = ko.observable('');
            self.dispatchProjectCode = ko.observable('');
            self.dispatchId = ko.observable('');
            self.reason = ko.observable('');
            //#endregion
        }

        //#region Private Functions
        function clear() {
            self.reason('');
        }
        
        function loadData(self) {
            self.temp = ko.observable();
        }

        function bindViewModel({ dispatchProjectCode, dailyEntryDate, dispatchId }) {
            self.dispatchDate(dailyEntryDate);
            self.dispatchProjectCode(dispatchProjectCode);
            self.dispatchId(dispatchId);
        }
        //#endregion

        //#region Public Functions
        ctor.prototype.activate = function (activationParameters) {
            clear();
            bindViewModel(activationParameters);

            return loadData(self);
        };

        ctor.prototype.setReason = function() {
            dispatchService.setAttendanceStatus(self.dispatchId(), { Presence: enumHelper.attendance().ABSENT, AbsenceReason: self.reason() })
                .done(routerHelper.navigateBack);
        };
        //#endregion
        return ctor;
    })();

    return viewModel;
});
