import { LocationModel } from "api/models/company/location-model";
import { PagingInfo } from "api/paging-info";
import { LocationProxy } from "api/proxies/location-proxy";
import { autoinject } from "aurelia-framework";
import { IRequestConfig } from "models/request-config";
import { LocationTypeModel } from "api/models/company/location-type-model";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class LocationService {
    constructor(private readonly locationProxy: LocationProxy) { }

    public async getLocations(type: string, id: string | null, id2: string | null, filter: string | null, pagingInfo?: PagingInfo, requestConfig?: IRequestConfig): Promise<LocationModel[]> {
        return await this.locationProxy.GetLocations(type, id ? id : undefined, id2 ? id2 : undefined, filter ? filter : undefined, pagingInfo, requestConfig) || [];
    }

    public async getLocationTypes(filter?: string, pagingInfo?: PagingInfo, requestConfig?: IRequestConfig): Promise<LocationTypeModel[] | null> {
        return await this.locationProxy.GetLocationTypes(filter ? filter : undefined, pagingInfo, requestConfig) || [];
    }

    public async AddAlternativeLocation(location: LocationModel | null, requestConfig?: HttpClientRequestConfig): Promise<LocationModel | null> {
        return await this.locationProxy.AddAlternativeLocation(location, requestConfig);
    }
}
