import EmailHelper from "helpers/emailHelper";
define([
    "knockout",
    "jquery",
    "core/resx",
    "core/val",
    "services/dispatchService",
    "services/serviceService",
    "services/contactService",
    "services/templateService",
    "helpers/viewbag",
    "helpers/routerHelper",
    "helpers/dateHelper",
    "helpers/labelHelper",
    "helpers/notificationHelper",
    "underscore"
], function (ko, jQuery, resx, val, dispatchService, service, contact, templateService, viewbag, routerHelper, hlpDate, labelHelper, notifier, _) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        function ctor(emailHelper) {
            this.emailHelper = emailHelper;
            self = this;

            //#region Properties

            self.resx = resx;
            self.val = val;

            self.hlpDate = hlpDate;
            self.labelHelper = labelHelper;
            self.routerHelper = routerHelper;

            self.client = ko.observable({});
            self.contactNameAvailable = ko.observable(true);
            self.contacts = ko.observableArray();
            self.dispatch = ko.observable({});
            self.id = "";
            self.assignedDate = "";
            self.fromHistory = ko.observable(false);
            self.equipmentCode = "";
            self.dispatchId = ko.observable();
            self.emailAvailable = ko.observable(false);
            self.equipment = ko.observableArray();
            self.equipmentDetail = ko.observableArray();
            self.infos = ko.observable({});
            self.inputBag = ko.observable({});
            self.labor = ko.observable({});
            self.lblCompletedStatus = '';
            self.lblFollowUpStatus = '';
            self.material = ko.observableArray();
            self.manageTravelTime = false;
            self.notes = ko.observableArray();
            self.serviceType = "";
            self.signatureAvailable = ko.observable(false);
            self.status = ko.observable({});
            self.viewbagValid = ko.observable(false);
            self.oneDayWorkDuration = false;
            self.noCallFound = ko.observable(false);

            self.loadContacts = {
                transport: function (params, success, failure) {
                    contact.getByCustomerCode(self.client().Id, params.data.filter, params.data.page || 1)
                        .done(success)
                        .fail(failure);
                },
                mapResults: function (item) {
                    return { id: item.No, text: item.Name + ', ' + item.FirstName, data: item.Email };
                }
            };

            self.validateInput = function () {
                if (viewbag() &&
                    viewbag() !== undefined &&
                    viewbag().DispatchId !== undefined &&
                    viewbag().ServiceType !== undefined &&
                    viewbag().Date !== undefined &&
                    viewbag().Comment !== undefined &&
                    viewbag().CustomStatus !== undefined &&
                    viewbag().Hour !== undefined &&
                    viewbag().IsComplete !== undefined &&
                    viewbag().IsTechWillReturn !== undefined &&
                    viewbag().Length !== undefined &&
                    viewbag().Workorder !== undefined &&
                    viewbag().EndDate !== undefined &&
                    viewbag().IsClosed !== undefined &&
                    viewbag().DispatchId === self.dispatchId() &&
                    viewbag().ServiceType === self.serviceType) {
                    //valid
                    self.inputBag(viewbag());
                    self.viewbagValid(true);
                } else {
                    //invalid
                    self.inputBag({});
                    self.viewbagValid(false);
                }

                //clear viewbag to fix back issue where the user could come back without respecting the flow
                viewbag(undefined);
            };

            self.validatedForm = ko.validatedObservable({
                contact: ko.observable(null),
                email: ko.observable('')
                    .extend({
                        validation: {
                            validator: function (email) {
                                this.message = resx.localize("err_InvalidEmail");
                                var invalidEmails = "";
                                if (email.length > 0) {
                                    invalidEmails = self.emailHelper.findInvalidEmailsInString(email);
                                    if (invalidEmails.length >= 1) {
                                        this.message += " " + invalidEmails.join(", ");
                                        return false;
                                    }
                                }
                                return true;
                            }
                        }
                    }),
                txtSignature: ko.observable('')
                    .extend({
                        required: {
                            params: true,
                            message: resx.localize("err_SignatureRequired")
                        }
                    }),
                clear: function () {
                    var valForm = this;
                    valForm.txtSignature('');
                    valForm.contact(null);
                    valForm.email('');
                }
            });

            self.validatedForm().contact.subscribe(function (newValue) {
                if (newValue && newValue.id !== undefined) {
                    self.validatedForm().email(newValue.data.Email);
                    self.validatedForm().txtSignature(newValue.text);
                    self.contactNameAvailable(false);
                } else {
                    self.validatedForm().email('');
                    self.validatedForm().txtSignature('');
                    self.contactNameAvailable(true);
                }
            });
            //#endregion
        }

        ctor.inject = [EmailHelper];

        //#region Private Functions
        function getQuerystringData(querystring) {
            var json = routerHelper.getQuerystring(querystring);

            if (!json) return;

            if (json.id) {
                self.id = decodeURIComponent(json.id);
            }
            if (json.assignedDate && json.assignedDate !== "null" && json.assignedDate !== "undefined") {
                self.assignedDate = decodeURIComponent(json.assignedDate);
            } else {
                self.assignedDate = null;
            }

            if (json.fromHistory) {
                self.fromHistory(decodeURIComponent(json.fromHistory));
            }

            if (json.equipmentCode) {
                self.equipmentCode = decodeURIComponent(json.equipmentCode);
            }

            if (json.isClosed) {
                self.isClosed = !(json.isClosed === "false") || false;
            } else {
                self.isClosed = false;
            }

           
        }

        function bindViewModel(serviceType, dispatchId, id, querystring) {
            self.validatedForm().clear();

            self.serviceType = serviceType;
            self.dispatchId(dispatchId);
            self.id = "";
            self.assignedDate = "";
            self.equipmentCode = "";

            getQuerystringData(querystring);

            self.validateInput();
        }

        async function loadData() {
            initClient(await dispatchService.getClient(self.dispatchId()).promise());

            let summaryPromise;
            if (self.id && self.fromHistory()) {
                summaryPromise = service.getSummaryFromId(self.serviceType, self.id, self.assignedDate, self.specifiedLanguage).promise();
            }
            else {
                summaryPromise = service.getSummaryFromDispatchId(self.serviceType, self.dispatchId(), self.specifiedLanguage).promise();
            }

            let equipmentDetailPromise = null;
            if (self.equipmentCode) {
                equipmentDetailPromise = service.getEquipmentDetail(self.serviceType, self.id, self.equipmentCode).promise();
            }

            const dispatchTemplatePromise = templateService.getCurrentDispatchTemplateConfigs().promise();

            initialise(await summaryPromise, await equipmentDetailPromise, await dispatchTemplatePromise);
        }

        function initialise(summary, equipmentDetail, dispatchTemplate) {
            var equipment = _.filter(summary.Equipments, function (equ) { return equ.IsUsed; });

            self.noCallFound(summary.NoVisitFound);

            self.notes(summary.Notes);
            self.labor(summary.Labors == null ? [] : summary.Labors);
            self.equipment(equipment);
            self.client(summary.Client);
            self.equipmentDetail(equipmentDetail);

            self.manageTravelTime = dispatchTemplate.ManageTravelTime;

            if (summary.Status) {
                self.specifiedLanguage = !summary.Status.IsClosed ? self.clientLanguage : null;
            } else {
                self.specifiedLanguage =  self.clientLanguage;
            }
           

            initStatus(summary.Status);

            if (self.viewbagValid() && self.inputBag().OrderNumber) {
                summary.Detail.OrderNumber = self.inputBag().OrderNumber;
            }

            initDispatch(summary.Detail);

            initSignature();

            initMaterials(summary.Materials);
        }

        function initClient(client) {
            self.clientLanguage = (client && client.Language) ? labelHelper.getLanguageAcronym(client.Language) : null;
            self.specifiedLanguage = !self.isClosed ? self.clientLanguage : null;
        }

        function initDispatch(dispatch) {
            self.dispatch(dispatch);

            if (dispatch.AssignedEndDate) {
                self.oneDayWorkDuration = !hlpDate.isAfter(dispatch.AssignedEndDate, dispatch.AssignedDate);
            } else {
                self.oneDayWorkDuration = true;
            }
            if (self.status()) {
                if (self.dispatch().ServiceType === 'S') {
                    self.lblCompletedStatus = self.status().IsComplete || (self.viewbagValid() && self.inputBag().IsComplete)
                        ? resx.localize('ServiceCallCompleted', self.specifiedLanguage)
                        : resx.localize('ServiceNotCompleted', self.specifiedLanguage);
                } else {
                    self.lblCompletedStatus = self.status().IsComplete || (self.viewbagValid() && self.inputBag().IsComplete)
                        ? resx.localize('WorkOrderCompleted', self.specifiedLanguage)
                        : resx.localize('WorkOrderNotCompleted', self.specifiedLanguage);
                }
            }

            if (self.dispatch().ContactDetails) {
                self.validatedForm().email(self.dispatch().ContactDetails.Email);
                self.validatedForm()
                    .txtSignature(self.dispatch().ContactDetails.FirstName + ' ' + self.dispatch().ContactDetails.Name);
            }
        }

        function initMaterials(material) {
            self.material([
                { title: resx.localize('Material', self.specifiedLanguage), items:  _.where(material, { IsBillingItem: false }) },
                { title: resx.localize('BillingItem', self.specifiedLanguage), items: _.where(material, { IsBillingItem: true }) }
            ]);

            self.material(_.filter(self.material(), function (val) { return val.items.length > 0; }));
        }

        function initStatus(status) {
            self.status(status);
            if (status) {
                if (self.status().IsTechWillReturn) {
                    self.lblFollowUpStatus = `${resx.localize('TechnicianWillBeBackOn', self.specifiedLanguage)} ${hlpDate.getFullTextDate(self.status().Date, self.specifiedLanguage)} ${resx.localize('for', self.specifiedLanguage)} ${self.status().Length} h.`;
                } else {
                    self.lblFollowUpStatus = resx.localize('TechnicianFollupNoScheduled', self.specifiedLanguage);
                }
            }
        }

        function initSignature() {
            if (self.viewbagValid()) {
                self.signatureAvailable(!self
                    .status()
                    .IsClosed &&
                    (!self.client() || self.client().MobilitySignatureRequiredness !== 2));

                if (self.signatureAvailable() && (self.client() && self.client().MobilityEmailAvailability !== undefined)) {
                    if (self.client().MobilityEmailAvailability === 0) {
                        self.emailAvailable(self.serviceType === 'W' ? self.client().GeneralConfigSaveFormWorkorder : self.client().GeneralConfigSaveFormServiceCall);
                    } else {
                        self.emailAvailable(self.client().MobilityEmailAvailability === 1 ? true : false);
                    }
                } else if (self.dispatch().Contract.IsMiscCustomer) {
                    self.emailAvailable(true);
                } else {
                    self.emailAvailable(false);
                }

                initStatus(self.inputBag());

            } else {
                self.signatureAvailable(false);
            }
        }
        //#endregion

        //#region Public Functions
        ctor.prototype.activate = function (params) {
            if (params.id && !params.q) {
                // TODO: Pourquoi on ferait ça ???
                params.q = params.id;
                params.id = null;
            }

            bindViewModel(params.serviceType, params.dispatchId, params.id, params.q);
            return loadData();
        };

        ctor.prototype.sign = function () {
            if (self.validatedForm.isValid()) {
                self.inputBag().txtSignature = self.validatedForm().txtSignature();
                self.inputBag().email = self.validatedForm().email();
                viewbag(self.inputBag());
                routerHelper.navigate(routerHelper.getRelativeUrl('signature') + routerHelper.addQuerystring({ readonly: false }));
            } else {
                notifier.showValidationError(self.validatedForm.errors);
            }
        };

        ctor.prototype.attached = function () {
            routerHelper.hideLoading();
        };

        ctor.prototype.showEquipmentDetail = function () {
            return self.equipmentDetail().Extension ||
                self.equipmentDetail().PONumber ||
                self.equipmentDetail().ServedSector ||
                self.equipmentDetail().UnitType ||
                self.equipmentDetail().WarrantyEquipmentEndDate ||
                self.equipmentDetail().WarrantyLaborEndDate ||
                self.equipmentDetail().WarrantyStartDate;

        };
        //#endregion

        return ctor;
    })();

    return viewModel;
});