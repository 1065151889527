import { ProcoreEntityToSynchronize } from "api/enums/procore-entity-to-synchronize";
import { ProcoreEntityToSynchronizeModel } from "api/models/company/procore/procore-entity-to-synchronize-model";
import { autoinject } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { ProcoreConfigurationService } from "services/procore/procore-configuration-service";
import { ProcoreMenuItemId } from "../enums/menu-item-id";
import { MenuItem } from "../models/menu-item";

@autoinject
export class MenuItemsGenerator {
  constructor(private i18n: I18N, private configurationService: ProcoreConfigurationService) { }

  public async generate(isTools: boolean): Promise<MenuItem[]> {
    const menuItems = await this.getEnabledMenuItems(isTools);

    return menuItems.filter((menuItem: MenuItem | null) => menuItem != null)
      .sort((a: MenuItem, b: MenuItem) => a.id > b.id ? 1 : -1) as MenuItem[];
  }

  private async getEnabledMenuItems(isTools: boolean): Promise<Array<MenuItem | null>> {
    const configurations = await this.configurationService.GetProcoreConfiguration();

    if (!configurations || !configurations.EntitiesToSynchronize) {
      return [];
    }

    let menuItems = configurations.EntitiesToSynchronize.filter((entity: ProcoreEntityToSynchronizeModel) => entity.Enabled)
      .map((entity: ProcoreEntityToSynchronizeModel) => this.convertConfigMenuItem(entity, isTools));
    menuItems = this.removeDuplicates(menuItems);
    if (menuItems && menuItems.length > 0) {
        const firstItem = menuItems[0];
        if (firstItem) {
            firstItem.selected = true;
        }
    }
    return menuItems;
  }

  private convertConfigMenuItem(entity: ProcoreEntityToSynchronizeModel, isTools: boolean): MenuItem | null {
    if (isTools) {
        if (entity.EntityType === ProcoreEntityToSynchronize.DirectCostItems) {
        return this.generateMenuItem(ProcoreMenuItemId.DeleteDirectCosts);
        }
        return null;
    }
    switch (entity.EntityType) {
      case ProcoreEntityToSynchronize.Projects:
        return this.generateMenuItem(ProcoreMenuItemId.Projects);

      case ProcoreEntityToSynchronize.ProjectBudgets:
        return this.generateMenuItem(ProcoreMenuItemId.Budget);

      case ProcoreEntityToSynchronize.BudgetModifications:
        return this.generateMenuItem(ProcoreMenuItemId.BudgetModifications);

      case ProcoreEntityToSynchronize.Suppliers:
        return this.generateMenuItem(ProcoreMenuItemId.Suppliers);

      case ProcoreEntityToSynchronize.PccoClients:
          return this.generateMenuItem(ProcoreMenuItemId.PccoClients);

      // SH : À Mesure qu'on supporte de nouvelles transactions, les ajouter ici
      case ProcoreEntityToSynchronize.PurchaseOrders:
      case ProcoreEntityToSynchronize.Contracts:
      case ProcoreEntityToSynchronize.DirectCostItems:
      case ProcoreEntityToSynchronize.ChangeOrders:
        return this.generateMenuItem(ProcoreMenuItemId.Transactions);

      case ProcoreEntityToSynchronize.PrimeContracts:
          return this.generateMenuItem(ProcoreMenuItemId.PrimeContracts);

      case ProcoreEntityToSynchronize.Employees:
        return this.generateMenuItem(ProcoreMenuItemId.Employees);

      case ProcoreEntityToSynchronize.PrimeContracts:
        return this.generateMenuItem(ProcoreMenuItemId.PrimeContracts);

        // Generate Here
    }

    return null;
  }

  private generateMenuItem(menuItemId: ProcoreMenuItemId): MenuItem | null {
    const menuItem = ProcoreMenuItemId[menuItemId];
    const text = this.i18n.tr(`Procore.Procore_Entity_${ProcoreMenuItemId[menuItemId]}`);

    const rootMenuItems = [ProcoreMenuItemId.Projects, ProcoreMenuItemId.Suppliers, ProcoreMenuItemId.Employees, ProcoreMenuItemId.PccoClients];
    const isChild = rootMenuItems.find((c: ProcoreMenuItemId) => menuItem === ProcoreMenuItemId[c]) == null;

    return {
      id: menuItemId,
      projectChild: isChild,
      selected: false,
      text: text
    };
  }

  private removeDuplicates(menuItems: Array<MenuItem | null>): Array<MenuItem | null> {
    const newList: MenuItem[] = [];

    menuItems.forEach((menuItem: MenuItem | null) => {
      if (menuItem != null) {
        const alredyInNewList = newList.some((newListMenuItem: MenuItem) => newListMenuItem.id === menuItem.id);

        if (!alredyInNewList) {
          newList.push(menuItem);
        }
      }
    });

    return newList;
  }
}
